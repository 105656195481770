import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Devices from '@mui/icons-material/Devices';
import Error from '@mui/icons-material/Error';
import LockPerson from '@mui/icons-material/LockPerson';
import Security from '@mui/icons-material/Security';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { EmptyState, InfoListItem } from '@brightlayer-ui/react-components';

import { NUMBER_REGEX } from '@fiji/common/src/constants';
import { useGetAreaCodesQuery, useGetCountriesQuery } from '@fiji/common/src/features/profile/profileApi';
import { CustomTransComponent } from './CustomTransComponent';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles(() => ({
    muiDialogpaper: {
        '& .MuiPaper-root': {
            maxWidth: '450px',
        },
    },
    divider: {
        width: 'calc(100% + 48px)',
        marginTop: '48px',
        marginLeft: '-24px',
        marginRight: '-24px',
    },
    topDivider: {
        marginTop: '48px',
    },
    formOverflow: {
        display: 'flex',
        flex: '1',
        overflow: 'auto',
        width: '100%',
    },
    infoListItem: {
        background: '#F7F8F8',
        paddingTop: '4px',
        paddingBottom: '4px',
        marginTop: '4px',
        marginBottom: '4px',
        borderRadius: '4px',
        height: 'auto',
        '& .MuiListItemText-root': {
            '& .MuiTypography-subtitle2 ': {
                whiteSpace: 'pre-wrap',
            },
        },
    },
    emptyState: {
        padding: '0 16px',
        '& .MuiTypography-h6 ': {
            padding: '0px 40px',
        },
    },
}));

type ValidInputType = string | number | boolean;

/* The above code is a TypeScript React component called `CustomDetailsScreen`. It is a form component
that allows users to enter and update their account details, such as phone number, country, area
code, and two-factor authentication (MFA) settings. */
export const CustomDetailsScreen: React.FC<React.PropsWithChildren<any>> = (props) => {
    const theme: any = useTheme();
    const { t } = useTranslation();
    const { onDetailsChanged, initialDetails } = props;
    const [phoneNumber, setPhoneNumber] = useState<ValidInputType>(initialDetails.phoneNumber ?? '');
    const { data: countries } = useGetCountriesQuery<{ data: any }>();
    const { data: areaCodes } = useGetAreaCodesQuery<{ data: any }>();

    const [country, setCountry] = useState(initialDetails.country ?? '');
    const [areaCode, setAreaCode] = useState(initialDetails.areaCode ?? '');
    const [mfa, setMfa] = useState<ValidInputType>(initialDetails.mfa ?? true);
    const [secureYourAccountModal, setSecureYourAccountModal] = useState(false);
    const classes = useStyles();

    /* The above code is using the useEffect hook in a React component. It is performing validation
    checks on the values of the variables `country`, `phoneNumber`, `areaCode`, and `mfa`. */
    useEffect(() => {
        // validation checks
        let valid = country !== '';
        if (phoneNumber !== '' && typeof phoneNumber === 'string' && phoneNumber?.length !== 10) {
            valid = false;
        }
        onDetailsChanged({ country, phoneNumber, areaCode, mfa }, valid);
    }, [country, phoneNumber, areaCode, mfa]); // Do NOT include onDetailsChanged in the dependencies array here or you will run into an infinite loop of updates

    useEffect(() => {
        if (countries) setCountry(countries?.data?.[0]?.name);
    }, [countries]);

    useEffect(() => {
        if (areaCodes) setAreaCode(areaCodes?.data?.[0]);
    }, [areaCodes]);
    /**
     * The function handleChangePhoneNumber is used to handle changes in a phone number input field in a
     * React component, validating the input against a regular expression and updating the state with the
     * new value if it meets the criteria.
     * @param e - React.ChangeEvent<HTMLInputElement>
     * @returns `void`, which means it does not return any value.
     */
    const handleChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.value.length && (e.target.value.length > 10 || !NUMBER_REGEX.test(e.target.value))) return;
        setPhoneNumber(e.target.value);
    };

    /**
     * The handleChangeCountry function is a TypeScript function in a React component that updates the
     * country state based on the value of an input element.
     * @param evt - The parameter `evt` is of type `SelectChangeEvent<string | number | boolean>`. This means it
     * is an event object that is triggered when the value of an input element of type
     * `HTMLInputElement` changes.
     */
    const handleChangeCountry = (evt: SelectChangeEvent<string | number | boolean>): void => {
        setCountry(evt.target.value);
    };

    /**
     * The handleChangeAreaCode function updates the area code state based on the value of an input
     * field.
     * @param evt - The parameter `evt` is of type `SelectChangeEvent<string | number | boolean>`. This means it
     * is an event object that is triggered when the value of an input element of type
     * `HTMLInputElement` changes.
     */
    const handleChangeAreaCode = (evt: SelectChangeEvent<string | number | boolean>): void => {
        setAreaCode(evt.target.value);
    };

    /**
     * The handleChangeMFA function updates the value of the MFA state based on the checked status of
     * an input element.
     * @param evt - The `evt` parameter is of type `React.ChangeEvent<HTMLInputElement>`. This means it
     * represents an event that is triggered when the value of an input element of type `checkbox` is
     * changed.
     */
    const handleChangeMFA = (evt: React.ChangeEvent<HTMLInputElement>): void => setMfa(evt.target.checked);

    return (
        <>
            <Box mt={4}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl variant={'filled'} fullWidth>
                            <InputLabel htmlFor={'Country Code'}>
                                <CustomTransComponent translationKey={'COMMON:COUNTRY_CODE'} />
                            </InputLabel>
                            <Select
                                data-cy={'country-selector'}
                                fullWidth
                                labelId={'Country Code'}
                                inputProps={{
                                    name: 'Country Code',
                                }}
                                value={areaCode}
                                onChange={handleChangeAreaCode}
                            >
                                {areaCodes?.data?.map((data: any) => (
                                    <MenuItem key={data} value={data}>
                                        {data}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id="phone-number"
                            fullWidth
                            type="text"
                            variant={'filled'}
                            label={<CustomTransComponent translationKey={'REGISTRATION:PHONE_NUMBER'} />}
                            placeholder={''}
                            value={phoneNumber}
                            onChange={handleChangePhoneNumber}
                        />
                    </Grid>
                </Grid>
                <FormControl variant="filled" fullWidth className="margin-top-32">
                    <InputLabel htmlFor={'country'}>
                        <CustomTransComponent translationKey={'COMMON:COUNTRY'} />
                    </InputLabel>
                    <Select id="country" fullWidth labelId={'country'} value={country} onChange={handleChangeCountry}>
                        {countries?.data?.map((item: any) => (
                            <MenuItem key={item?.name} value={item?.name}>
                                {item?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box className="justify-content-between margin-top-32 margin-x-0">
                    <Grid item xs={10} className="align-items-center margin-x-0">
                        <Typography variant="body1" color="primary" fontWeight={'600'} mr={1}>
                            {t('bluiRegistration:REGISTRATION.MULTIFACTOR_AUTH_TEXT')}
                        </Typography>
                        <Error
                            id="secure-modal"
                            color="disabled"
                            className="cursor-pointer"
                            onClick={(e): void => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSecureYourAccountModal(true);
                            }}
                        />
                    </Grid>
                    <Switch
                        checked={Boolean(mfa)}
                        onChange={handleChangeMFA}
                        sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                                color: theme?.palette?.primary?.main,
                                '& + .MuiSwitch-track': {
                                    backgroundColor: theme?.palette?.primary?.main,
                                },
                            },
                        }}
                    />
                </Box>
            </Box>
            <Dialog
                open={secureYourAccountModal}
                onClose={(): void => setSecureYourAccountModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.muiDialogpaper}
            >
                <DialogTitle id="alert-dialog-title" pt={4}>
                    <Typography variant={'h6'} mb={2} width={'100%'}>
                        <CustomTransComponent translationKey={'blui:STEPS.MULTIFACTOR_TITLE'} />
                    </Typography>
                    <Divider className={clsx(classes.divider, classes.topDivider)} sx={{ mt: '40px !important' }} />
                </DialogTitle>
                <DialogContent className="min-height-334">
                    <Box className={classes.formOverflow}>
                        <EmptyState
                            mt={1}
                            className="empty-state"
                            icon={<Security fontSize={'inherit'} sx={{ color: theme?.palette?.primary?.main }} />}
                            title={t('REGISTRATION:MULTIFACTOR_TITLE')}
                            description={
                                <>
                                    <Typography variant={'body1'} mb={1} textAlign={'justify'} px={3} mt={1}>
                                        <CustomTransComponent
                                            translationKey={'COMMON:PREVENT_UNAUTHORIZED_ACCESS_FOR_YOUR_ACCOUNT'}
                                        />
                                    </Typography>
                                    <InfoListItem
                                        className={classes.infoListItem}
                                        data-testid="infoListItem"
                                        data-cy={'list-content'}
                                        icon={
                                            <LockPerson
                                                sx={{ color: theme?.palette?.primary?.main }}
                                                fontSize={'large'}
                                            />
                                        }
                                        subtitle={t('blui:MULTIFACTOR_EXTRA_PROTECTION_TEXT')}
                                        title={'Extra Protection'}
                                        avatar={false}
                                        iconAlign="center"
                                        hidePadding
                                    />
                                    <InfoListItem
                                        className={classes.infoListItem}
                                        data-testid="infoListItem"
                                        data-cy={'list-content'}
                                        icon={
                                            <Devices sx={{ color: theme?.palette?.primary?.main }} fontSize={'large'} />
                                        }
                                        subtitle={t('REGISTRATION:MULTIFACTOR_SMS_TEXT')}
                                        title={t('blui:MULTIFACTOR_SMS_TTITLE')}
                                        avatar={false}
                                        iconAlign="center"
                                        hidePadding
                                    />
                                </>
                            }
                        />
                    </Box>
                </DialogContent>
                <DialogActions className="padding-24 border-top-1">
                    <Stack direction="row" justifyContent="end" className="w-100">
                        <Button
                            sx={{
                                border: `1px solid ${theme?.palette?.primary?.main}`,
                                '&:hover': {
                                    backgroundColor: theme?.palette?.primary?.[50],
                                    color: theme?.palette?.primary?.main,
                                },
                            }}
                            variant={'outlined'}
                            onClick={(): void => setSecureYourAccountModal(false)}
                        >
                            {t('bluiRegisteration:ACTIONS.CLOSE')}
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};
