import { isNullOrUndefined } from '@fiji/common/src/utils/helpers';

const reducer = (state: any, action: any): any => {
    const stateClone = JSON.parse(JSON.stringify(state ?? {}));
    let selectedChannelIndex: any;
    if (action.payload?.category) {
        selectedChannelIndex = stateClone?.data?.[action?.payload?.category]?.findIndex(
            (item: any) => item?.channelId === action.payload?.key
        );
    }
    switch (action.type) {
        case 'SET_DATA':
            return {
                ...action.payload,
            };
        case 'UPDATE_DATA':
            if (selectedChannelIndex !== -1 && !isNullOrUndefined(selectedChannelIndex)) {
                stateClone.data[action?.payload?.category][selectedChannelIndex]['value'] = action.payload.value;
            }
            return stateClone;
        default:
            return state;
    }
};

export { reducer };
