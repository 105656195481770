import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { useGetTagsQuery } from '@fiji/common/src/features/TagManagement/tagManagementApi';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { useUpdateDeviceMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { resetDeviceProperties } from '@fiji/common/src/features/deviceManagement/deviceSlice';
import { setRefetchGroupHierarchy } from '@fiji/common/src/features/group/groupSlice';
import { usePayloadActions } from '@fiji/common/src/hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

type ReturnProps = {
    handleModalHeader: (args0: string) => string;
    handleDisable: (args0: any) => boolean;
    handleModalType: (args0: string) => string;
    handleSubmit: any;
    isLoading: boolean;
};

export const useHandleUpdate = (): ReturnProps => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { data: deviceTagsList } = useGetTagsQuery<{
        data: any;
    }>({
        page: 0,
        size: 100,
    });

    const { deviceId } = useParams();

    const { propertiesPayload } = useTypedSelector((state) => state.device);

    const [updateDeviceProperties, { isLoading }] = useUpdateDeviceMutation();

    const [cleanPayload] = usePayloadActions();

    const handleDisable = (data: any): boolean => {
        if (
            (data?.value &&
                Array.isArray(data?.value) &&
                data?.value?.map((item: any) => item.id)?.toString() === propertiesPayload?.[data?.key]?.toString()) ||
            ((data?.value === propertiesPayload?.value || (!propertiesPayload?.[data?.key]?.length && !data?.value)) &&
                data?.key === 'description') ||
            (data?.value &&
                (data?.value?.groupId ?? data?.value) ===
                    (propertiesPayload?.[data?.key]?.id ?? propertiesPayload[data?.key]))
        ) {
            return true;
        }
        if (
            data?.key === 'tags' &&
            propertiesPayload?.[data?.key]?.length === data?.tags?.length &&
            propertiesPayload?.[data?.key]?.every(
                (payloadTag: string) => data?.tags?.findIndex((tag: any) => tag?.name === payloadTag) !== -1
            )
        ) {
            return true;
        }
        if (
            data?.key === 'location' &&
            data?.value &&
            (data?.value?.lat === propertiesPayload?.[data?.key]?.lat || !propertiesPayload?.[data?.key]?.lat) &&
            (data?.value?.lng === propertiesPayload?.[data?.key]?.lng || !propertiesPayload?.[data?.key]?.lat)
        ) {
            return true;
        }
        return false;
    };

    const handleModalHeader = (modalKey: string): string => {
        const modalHeader: any = {
            name: t('DEVICE_PROPERTIES:EDIT_NAME'),
            tags: t('DEVICE_PROPERTIES:EDIT_TAGS'),
            groupId: t('COMMON:EDIT_GROUP'),
            description: t('DEVICE_PROPERTIES:EDIT_DESCRIPTION'),
            location: t('DEVICE_PROPERTIES:EDIT_LOCATION'),
            batteryInstallDate: t('DEVICE_PROPERTIES:BATTERY_INSTALL_DATE'),
            deviceInstallDate: t('DEVICE_PROPERTIES:DEVICE_INSTALL_DATE'),
        };

        return modalHeader[modalKey] ?? '';
    };

    const handleModalType = (key: string): string => {
        if (key !== 'batteryInstallDate' && key !== 'deviceInstallDate') {
            return 'secondary';
        }
        return 'primary';
    };

    const handleSubmit = async (key: string, callback: any, id?: string): Promise<void> => {
        const payload: any = {
            [key]: propertiesPayload[key]?.id ?? propertiesPayload[key],
        };
        if (key === 'batteryInstallDate' || key === 'deviceInstallDate') {
            payload[key] = new Date(propertiesPayload[key])?.getTime();
        }
        if (key === 'tags') {
            payload[key] = deviceTagsList?.data?.records
                ?.filter((item: any) => payload[key].includes(item.name))
                ?.map((tag: any) => tag?.id);
        }
        const cleanedPayload = cleanPayload({ payload, ...(payload?.location && { coordinates: payload?.location }) });

        const { error }: any = await updateDeviceProperties({
            deviceId: deviceId ?? id,
            body: cleanedPayload,
        });

        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: deviceId ? t('COMMON:CHANGES_SAVED_SUCCESS') : t('COMMON:TAGS_UPDATED_SUCCESSFULLY'),
                })
            );
            dispatch(resetDeviceProperties());
            if (key === 'groupId' || key === 'name') {
                dispatch(setRefetchGroupHierarchy({ refetch: true }));
            }
            callback(true);
        }
    };

    return {
        handleModalHeader,
        handleDisable,
        handleModalType,
        handleSubmit,
        isLoading,
    };
};
