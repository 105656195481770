import React, { useState } from 'react';
import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from '@react-google-maps/api';
import { Box, Card, CardContent, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Fullscreen from '@mui/icons-material/Fullscreen';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import Layers from '@mui/icons-material/Layers';
import MyLocation from '@mui/icons-material/MyLocation';
import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';
import { WidgetFooter, WidgetHeader } from 'pages/WidgetManagement/common';
import ReactDOMServer from 'react-dom/server';
import { useTypedSelector } from '@fiji/common/src/app/store';
import CustomIcon from 'components/CustomIcon';
import { mapStyles } from '@fiji/common';
import { useNavigate } from 'react-router-dom';
import { useIsMount, useNavigationBack } from 'hooks';
import { Loader } from 'components';

export type LocationType = { lat: number | null; lng: number | null };

export function getIconPath(icon: any): string {
    const iconString = ReactDOMServer.renderToString(icon);
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(iconString, 'image/svg+xml');
    const iconPath = svgDoc.querySelector('path')?.getAttribute('d') as string;
    return iconPath;
}

export const fetchLocation = (): Promise<{ lat: number; lng: number }> =>
    new Promise((resolve, reject) => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position: any) => {
                    const lat = parseFloat(position.coords.latitude);
                    const lng = parseFloat(position.coords.longitude);
                    resolve({ lat, lng });
                },
                (error) => {
                    reject(new Error(error.message));
                }
            );
        } else {
            reject(new Error('Geolocation is not supported by this browser.'));
        }
    });

export const getMarkerUrl = (icon: any, themeColor: any): string =>
    `data:image/svg+xml,${encodeURIComponent(
        `<svg xmlns="http://www.w3.org/2000/svg" width="45" height="66" viewBox="0 0 45 66" fill="none"><path d="M45 22.4C45 9.92 35.0357 0 22.5 0C9.96428 0 0 9.92 0 22.4C0 39.04 22.5 64 22.5 64C22.5 64 45 39.04 45 22.4Z" fill="${themeColor}"/>${
            icon ?? ''
        }<g style="transform: translate(5px, 59px);"><ellipse cx="16.5" cy="4" rx="16.5" ry="4" fill="url(#paint0_radial_852_3674)"/><defs><radialGradient id="paint0_radial_852_3674" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.5 4) rotate(90) scale(4 16.5)"><stop stop-color="#424E54" stop-opacity="0.5"/><stop offset="1" stop-color="#424E54" stop-opacity="0"/></radialGradient></defs></g></svg>`
    )}`;

const getMarkerIcon = (themeColor: string, markerSelector: boolean, countKey?: string, obj?: any): string => {
    switch (true) {
        case markerSelector: {
            return getMarkerUrl('<circle r="9" cx="23" cy="24" fill="#fff"/>', themeColor);
        }
        case obj?.[countKey ?? 'count'] === 1: {
            return getMarkerUrl(
                `<g style="transform: translate(12px, 12px);"><path d="${getIconPath(
                    <CustomIcon family="material-ui" iconName="CorporateFare" />
                )}" fill="#fff" /></g>`,
                themeColor
            );
        }
        default: {
            return getMarkerUrl(undefined, themeColor);
        }
    }
};

type MapProps = {
    widgetData: any;
    clustering: boolean;
    minHeight?: string;
    mode?: string;
    onIdle?: any;
    countKey?: string;
    widgetId?: string;
    showInfoWindow: boolean;
    markerSelector?: boolean;
    isFullscreen?: boolean;
    customFullView?: boolean;
    center?: any;
    disabled?: boolean;
};

const MapComponent = ({
    widgetData,
    clustering,
    minHeight,
    onIdle,
    countKey = 'count',
    widgetId,
    showInfoWindow = true,
    markerSelector = false,
    isFullscreen,
    customFullView,
    center: centerData,
    mode,
    disabled,
}: MapProps): JSX.Element => {
    const isMount = useIsMount();
    const navigate = useNavigate();
    const navigationBack = useNavigationBack();
    const handleNavigateBack = (): void => {
        navigationBack();
    };

    const theme: any = useTheme();
    const containerStyle = {
        ...(minHeight && { minHeight: minHeight }),
        width: '100%',
        height: mode?.includes('pinned') ? '425px' : '100%',
    };

    const [zoom, setZoom] = React.useState(1);
    const [isMapUnDirty, setIsMapUnDirty] = React.useState(false);

    const [center, setCenter] = useState<any>({ lat: 24.879, lng: -6.834 });
    const [isFullScreen, setIsFullScreen] = React.useState(Boolean(isFullscreen));
    const [map, setMap] = React.useState<any>(null);
    const [selectedMarker, setSelectedMarker] = React.useState<any>();

    const googleMapLoaded = useTypedSelector((state) => state.common.googleMapLoaded);
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);

    React?.useEffect(() => {
        if (centerData?.lat && centerData?.lng) {
            setCenter(centerData);
            setZoom(16);
        }
    }, [centerData]);

    React.useEffect(() => {
        const handleEsc = (): void => {
            if (!document.fullscreenElement) {
                setIsFullScreen(false);
            }
        };
        document.body.addEventListener('fullscreenchange', handleEsc);

        return () => {
            document.body.removeEventListener('fullscreenchange', handleEsc);
        };
    }, []);

    const toggleFullScreen = async (): Promise<void> => {
        if (customFullView) {
            if (!isFullScreen) {
                navigate(`/${currentRealmName}/map`, {
                    state: {
                        widgetName: widgetData?.widgetName,
                    },
                });
            } else {
                handleNavigateBack();
            }
        } else if (!isFullScreen) {
            await map?.getDiv().requestFullscreen();
            setIsFullScreen(true);
        } else if (document.fullscreenElement) {
            await document.exitFullscreen();
            setIsFullScreen(false);
        }
    };
    const onLoad = React.useCallback((property: any) => {
        setMap(property);
    }, []);

    const onUnmount = React.useCallback(() => {}, []);
    const bounds = new window.google.maps.LatLngBounds();

    React.useEffect(() => {
        if (!isMapUnDirty && widgetData?.secondary && widgetData?.secondary?.length === 0) {
            setZoom(3);
        }
    }, [widgetData]);

    return googleMapLoaded && center?.lat ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                disableDefaultUI: true,
                styles: mapStyles,
            }}
            onBoundsChanged={(): void => setSelectedMarker(undefined)}
            onIdle={(): void => {
                if (!isMount) {
                    onIdle?.({
                        widgetId: widgetId,
                        bounds: {
                            topLeft: {
                                lat: map?.getBounds()?.getNorthEast()?.lat(),
                                lng: map?.getBounds()?.getSouthWest()?.lng(),
                            },
                            bottomRight: {
                                lat: map?.getBounds()?.getSouthWest()?.lat(),
                                lng: map?.getBounds()?.getNorthEast()?.lng(),
                            },
                            precision: map?.getZoom(),
                        },
                    });
                }
            }}
        >
            <MarkerClusterer
                averageCenter
                gridSize={100}
                styles={[
                    {
                        url: getMarkerIcon(theme?.palette?.primary?.main, markerSelector, countKey),
                        className: 'custom-cluser-marker-icon',
                        height: 48,
                        width: 48,
                        textColor: '#fff',
                        textSize: 20,
                    },
                ]}
            >
                {(clusterer): JSX.Element => (
                    <div>
                        {widgetData?.secondary?.map((obj: any, index: number): JSX.Element => {
                            if (!isMapUnDirty && obj.deviceCount === 1 && widgetData?.secondary?.length === 1) {
                                setIsMapUnDirty(true);
                                setCenter({ lat: obj.coordinates?.lat, lng: obj.coordinates?.lng });
                                setZoom(17);
                            } else {
                                bounds.extend({
                                    lat: parseFloat(obj.deviceList?.[0]?.location?.lat),
                                    lng: parseFloat(obj.deviceList?.[0]?.location?.lon),
                                });
                                bounds.extend({
                                    lat:
                                        parseFloat(obj.coordinates?.lat) +
                                        (parseFloat(obj.coordinates?.lat) -
                                            parseFloat(obj.deviceList?.[0]?.location?.lat)),
                                    lng:
                                        parseFloat(obj.coordinates?.lng) +
                                        (parseFloat(obj.coordinates?.lng) -
                                            parseFloat(obj.deviceList?.[0]?.location?.lon)),
                                });
                                if (widgetData?.secondary?.length - 1 === index && !isMapUnDirty) {
                                    setIsMapUnDirty(true);
                                    if (
                                        Object.values(JSON.parse(JSON.stringify(bounds)))?.every(
                                            (item: any) => item !== null
                                        )
                                    ) {
                                        map.fitBounds(bounds);
                                    }

                                    setZoom(map?.getZoom());
                                }
                            }
                            const renderMarkerLabel = (): any => ({
                                text: obj?.[countKey] > 1 ? obj?.[countKey].toString() : obj.name,
                                className: obj?.[countKey] > 1 ? '' : 'marker-label-with-avatar',
                                color: obj?.[countKey] > 1 ? '#fff' : theme?.palette?.primary?.main,
                                ...(obj?.[countKey] === 1 && {
                                    fontSize: '20px',
                                    fontWeight: '600',
                                }),
                            });
                            return (
                                obj.coordinates?.lat && (
                                    <Marker
                                        key={`lat-${obj.coordinates?.lat}-lng-${obj.coordinates?.lng}`}
                                        position={obj.coordinates}
                                        icon={{
                                            scaledSize: new google.maps.Size(
                                                obj?.[countKey] ? 48 : 32,
                                                obj?.[countKey] ? 48 : 32
                                            ),
                                            url: getMarkerIcon(
                                                theme?.palette?.primary?.main,
                                                markerSelector,
                                                countKey,
                                                obj
                                            ),
                                            origin: new google.maps.Point(0, 0),
                                            anchor: new google.maps.Point(25, 50),
                                        }}
                                        animation={null}
                                        {...(clustering && { clusterer })}
                                        {...((obj?.[countKey] || obj.name) && {
                                            label: renderMarkerLabel(),
                                        })}
                                        clickable={!disabled}
                                        onClick={() => {
                                            if (obj?.[countKey] === 1) {
                                                navigate(`/${currentRealmName}/device/${obj['id']}`);
                                            } else {
                                                navigate(`/${currentRealmName}/multiDevices`, {
                                                    state: {
                                                        deviceIds: obj?.deviceList?.map((device: any) => device?.id),
                                                    },
                                                });
                                            }
                                            if (selectedMarker && showInfoWindow) {
                                                setSelectedMarker(undefined);
                                            } else if (showInfoWindow) {
                                                setSelectedMarker(obj);
                                            }
                                        }}
                                    ></Marker>
                                )
                            );
                        })}
                    </div>
                )}
            </MarkerClusterer>

            <Stack direction={'row'} spacing={1} sx={{ position: 'absolute', right: '20px', bottom: '20px' }}>
                <Box className="border-radius-4 bg-white box-shadow padding-6">
                    <IconButton
                        aria-label="delete"
                        disabled={disabled}
                        onClick={(): void => {
                            void fetchLocation().then(({ lat, lng }: any) => {
                                setCenter({ lat: parseFloat(lat) ?? 0, lng: parseFloat(lng) ?? 0 });
                                setZoom(16);
                            });
                        }}
                    >
                        <MyLocation className="black-500" />
                    </IconButton>
                </Box>
                <Box className={`border-radius-4 bg-white box-shadow padding-6 ${disabled ? '' : 'layers'}`}>
                    <Stack
                        direction={'row'}
                        sx={{ position: 'absolute', right: '132px', bottom: '46px' }}
                        className="default-satellite padding-bottom-10 bg-transparent"
                    >
                        <Stack direction={'row'} className="bg-transparent">
                            <IconButton onClick={(): void => map?.setMapTypeId('roadmap')}>
                                <Stack className="default" />
                            </IconButton>
                            <IconButton onClick={(): void => map?.setMapTypeId('hybrid')}>
                                <Stack className="satellite" />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <IconButton aria-label="delete" disabled={disabled}>
                        <Layers className="black-500" />
                    </IconButton>
                </Box>
                <Box className="border-radius-4 bg-white box-shadow padding-6">
                    <IconButton aria-label="delete" onClick={toggleFullScreen} disabled={disabled}>
                        {isFullScreen ? <FullscreenExit className="black-500" /> : <Fullscreen className="black-500" />}
                    </IconButton>
                </Box>
                <Stack direction={'row'} className="border-radius-4 bg-white box-shadow">
                    <Box className="padding-6">
                        <IconButton
                            aria-label="delete"
                            onClick={(): void => setZoom((prev: number) => (prev > 1 ? prev - 1 : 1))}
                            disabled={zoom === 3}
                        >
                            <ZoomOut className="black-500" />
                        </IconButton>
                    </Box>
                    <Box className="border-left-1 padding-6">
                        <IconButton
                            aria-label="delete"
                            onClick={(): void => setZoom((prev: number) => (prev < 20 ? prev + 1 : 20))}
                            disabled={zoom === 20}
                        >
                            <ZoomIn className="black-500" />
                        </IconButton>
                    </Box>
                </Stack>
            </Stack>
            {selectedMarker && showInfoWindow && (
                <InfoWindow
                    position={selectedMarker.coordinates}
                    options={{ pixelOffset: { height: -50, width: 0, equals: () => true }, minWidth: 200 }}
                    onCloseClick={(): void => setSelectedMarker(undefined)}
                >
                    <Stack sx={{ paddingTop: '12px', paddingLeft: '8px' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                            {selectedMarker[countKey] > 1 ? `${selectedMarker[countKey]} Devices` : selectedMarker.name}
                        </Typography>
                        <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>{selectedMarker.name}</Typography>
                    </Stack>
                </InfoWindow>
            )}
        </GoogleMap>
    ) : (
        <Loader />
    );
};

const Component = (props: MapProps): JSX.Element => (
    <Card sx={{ height: '100%', ...(props?.mode?.includes('pinned') && { boxShadow: 'none' }) }}>
        {!props?.mode?.includes('pinned') && <WidgetHeader mode={props?.mode} widgetData={props?.widgetData} />}
        {props?.mode !== 'edit' && <Divider />}
        <CardContent
            className="padding-0"
            sx={{
                height: `calc(100% - ${props?.mode === 'edit' || props?.widgetData?.viewAllButton ? '97px' : '49px'})`,
                position: 'relative',
            }}
        >
            <MapComponent {...props} customFullView disabled={!props?.mode?.includes?.('view')} />
        </CardContent>
        {!props?.mode?.includes('pinned') && (
            <WidgetFooter
                mode={props?.mode}
                widgetData={props?.widgetData}
                ids={[]}
                disabled={false}
                actionBtnName="View Full Map"
            />
        )}
    </Card>
);
export default React.memo(Component);
export { MapComponent };
