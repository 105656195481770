import React, { memo } from 'react';
import {
    MenuItem,
    IconButton,
    Typography,
    Paper,
    TableContainer,
    TableHead,
    Table,
    TableRow,
    TableCell,
    Checkbox,
    InputAdornment,
    FilledInput,
    FormControl,
    TableSortLabel,
    Stack,
    TableBody,
    Button,
    Box,
    CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Header } from '@fiji/common/src/types';
import { TableSkeleton } from './TableSkeleton';
import { EmptyState } from '@brightlayer-ui/react-components';
import Add from '@mui/icons-material/Add';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { useIsMount, usePagination, useSelectedIds } from '../hooks';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { CustomTableLoadMoreProps } from '@fiji/common/src/features/common/commonTypes';
import { CustomTransComponent } from 'components';

/**
 * The above type defines the props for a custom table component in TypeScript React, including options
 * for success/error states, pagination, loading state, data and headers, and various customization
 * options.
 * @property {string} wrapperClass - The `wrapperClass` property is a string that represents the CSS
 * class name(s) to be applied to the wrapper element of the custom table component.
 * @property {string} containerClass - A string representing the CSS class name for the container
 * element of the table.
 * @property {boolean} isPagination - A boolean value indicating whether pagination should be enabled
 * for the table.
 * @property {any[]} data - An array of data that will be displayed in the table.
 * @property {any[]} headers - An array of objects representing the headers of the table. Each object
 * should have properties like "label" (string) for the header label and "key" (string) for the
 * corresponding key in the data array.
 * @property {string} keyToTraverse - The `keyToTraverse` property is a string that represents the key
 * in the `data` array that should be used to render the table rows. This key is used to access the
 * corresponding value in each object within the `data` array.
 * @property {boolean} isLoading - A boolean value indicating whether the data is currently being
 * loaded or not.
 * @property noDataFoundIcon - The `noDataFoundIcon` property is an optional JSX element that
 * represents the icon to be displayed when there is no data found in the table.
 * @property {string} noDataFoundTitle - The `noDataFoundTitle` property is a string that represents
 * the title or heading to be displayed when there is no data available for the table.
 * @property {string} noDataFoundButtonText - The `noDataFoundButtonText` property is an optional
 * string that represents the text to be displayed on the button when no data is found in the table.
 * @property noDataFoundButtonAction - The `noDataFoundButtonAction` property is a function that will
 * be called when the button in the "no data found" section is clicked. It is an optional property and
 * can be used to define a custom action to be performed when the button is clicked.
 * @property handleCheckboxSelect - A function that is called when checkboxes in the table are
 * selected. It takes an array of strings as an argument, which represents the selected checkboxes.
 * @property handleFilterChange - A function that is called when a filter is changed in the table. It
 * takes four arguments: arg0 (the filter value), arg1 (the filter type), arg3 (the filter column), and
 * arg4 (the filter operator).
 * @property {number} total - The `total` property is a number that represents the total number of
 * items in the data set.
 * @property {string | JSX.Element} noDataFoundDescription - The `noDataFoundDescription` property is a
 * string or JSX element that represents the description or additional information to be displayed when
 * there is no data found in the table. It can be used to provide context or instructions to the user
 * when there are no records available to display.
 */

export const CustomTableLoadMore = memo(
    React.forwardRef((props: CustomTableLoadMoreProps, ref) => {
        const [filteredValues, setFilteredValues] = React.useState<any>({});
        const [sortedData, setSortedData] = React.useState<any>({});
        const [openFilterDropDown, setOpenFilterDropDown] = React.useState<any>({});
        const [pageSize, setPageSize] = React.useState<number>(props.pageSize ?? 10);
        const theme: any = useTheme();
        /* The below code is using the `usePagination` hook to manage pagination functionality in a
    TypeScript React component. */
        const { currentPage, setCurrentPage } = usePagination({
            pageSize: pageSize,
            totalItems: props?.total,
        });

        const [selectedIds, setSelectedIds, selectionHandler] = useSelectedIds();

        const isMount = useIsMount();

        /* The below code is using the `useImperativeHandle` hook from React to expose certain functions to
    the parent component through a ref. */
        React.useImperativeHandle(ref, () => ({
            resetCheckboxes: (): void => setSelectedIds([]),
            resetFilters: (key: string, type?: any): void => resetFilters(key, type),
            dropDownHandler: (key: string, type?: any): void => dropDownHandler(key, type),
        }));

        /* The below code is a React useEffect hook that is triggered when the component mounts. It checks
    if the `props.headers` array exists and has a length greater than 0. If it does, it iterates
    over each `header` object in the array. If the `header` object has a property `isFilterable`
    that is truthy, it sets the state of `openFilterDropDown` using the `setOpenFilterDropDown`
    function. The state is updated by spreading the previous state (`prev`) and setting the value of
    the `header.accessor` property to `false`. */
        React.useEffect(() => {
            if (props?.headers?.length) {
                props.headers.forEach((header: Header) => {
                    if (header?.isFilterable) {
                        setOpenFilterDropDown((prev: any) =>
                            header.accessor ? { ...prev, [header?.accessor]: false } : prev
                        );
                    }
                });
            }
        }, []);

        /* The below code is using the `useEffect` hook from React to execute a function whenever the
    `selectedIds` variable changes. The function being executed is
    `props.handleCheckboxSelect(selectedIds)`, which is passed as a prop to the component. This code
    is likely used to handle the selection of checkboxes and update the selectedIds state in the
    parent component. */
        React.useEffect(() => {
            if (props.handleCheckboxSelect) props.handleCheckboxSelect(selectedIds);
        }, [selectedIds]);

        /* The below code is using the `useEffect` hook from React to execute a function whenever the
    dependencies (`filteredValues`, `currentPage`, `pageSize`, `sortedData`) change. */
        React.useEffect(() => {
            if ((filteredValues || sortedData) && props.handleFilterChange) {
                props.handleFilterChange(filteredValues, sortedData);
            }
        }, [filteredValues, sortedData]);

        React.useEffect(() => {
            if ((currentPage || pageSize) && props.handlePageChange) {
                props.handlePageChange(currentPage, pageSize);
            }
        }, [currentPage, pageSize]);

        React.useEffect(() => {
            if (!isMount && props.controlledPageSize !== currentPage) {
                setCurrentPage(props.controlledPageSize);
            }
        }, [props.controlledPageSize]);

        React.useEffect(() => {
            if (props.pageSize) {
                setPageSize(props.pageSize);
            }
        }, [props.pageSize]);

        /**
         * The function `filterPayloadHandler` handles filtering of values based on an ID and value, and
         * updates the filtered values accordingly.
         * @param {any} id - The `id` parameter is used to identify the specific filter that is being
         * applied. It could be a unique identifier or a key that represents a specific filter option.
         * @param {any} value - The `value` parameter is the value that is being filtered. It is used to
         * determine whether to reset the filters or set the filtered values.
         */
        const filterPayloadHandler = (id: any, value: any): void => {
            if (id !== '') {
                if (value === '') {
                    resetFilters(id);
                } else {
                    setFilteredValues((prev: any) => ({ ...prev, [id]: value }));
                }
            }
        };

        /**
         * The function `dropDownHandler` toggles the state of a dropdown menu based on a given key and
         * type.
         * @param {string | undefined} key - The `key` parameter is a string or undefined. It is used to
         * identify the specific dropdown that needs to be toggled or opened.
         * @param {string} [type] - The `type` parameter is an optional string that specifies the action to
         * be performed on the dropdown. If the `type` is set to `'remove'`, it will call the
         * `resetFilters` function with the `key` as an argument.
         */
        const dropDownHandler = (key: string | undefined, type?: string): void => {
            if (key) {
                if (Object.keys(openFilterDropDown).includes(key)) {
                    if (type && type === 'remove') {
                        resetFilters(key);
                    }
                    if (type === 'close') {
                        setOpenFilterDropDown((prev: any) => ({ ...prev, [key]: false }));
                        return;
                    }
                    setOpenFilterDropDown((prev: any) => ({ ...prev, [key]: !prev[key] }));
                } else setOpenFilterDropDown((prev: any) => ({ ...prev, [key]: true }));
            }
        };

        /**
         * The function `handleSelectAll` is used to handle the selection of all items in a list.
         * @param {any} e - The parameter `e` is an event object that is passed to the `handleSelectAll`
         * function. It is typically an event object that is triggered when a checkbox is checked or
         * unchecked.
         */
        const handleSelectAll = (e: any): void => {
            let IdsArr: string[] = [];
            if (e.target.checked) {
                props?.data?.forEach((subData: any) => {
                    IdsArr.push(subData?.[props?.keyToTraverse]);
                });
            } else {
                IdsArr = [];
            }
            setSelectedIds(IdsArr);
        };

        /**
         * The function `handleSorting` is used to toggle between ascending and descending sorting based on
         * a given key.
         * @param {any} key - The `key` parameter is used to specify the property or key by which the data
         * should be sorted.
         */
        const handleSorting = (key: any): void => {
            let str = 'ASC';
            if (key === sortedData?.key) {
                str = 'DESC';
                if (sortedData?.sortType === 'DESC') {
                    str = 'ASC';
                }
            }

            setSortedData((prev: any) => ({ ...prev, key: key, sortType: str }));
        };

        /**
         * The function `handleSetFilteredValues` updates the `filteredValues` state by removing a specific
         * value from a specific filter, and also removes the filter if it becomes empty or if the value is
         * 'all'.
         * @param {string} id - The `id` parameter is a string that represents the identifier of a filter.
         * @param {any} value - The `value` parameter is the value that needs to be removed from the
         * `filteredValues` object.
         */
        const handleSetFilteredValues = (id: string, value: any): void => {
            const filtersClone = JSON.parse(JSON.stringify(filteredValues));
            if (filtersClone?.[id]) {
                const removableIndex = filtersClone[id]?.indexOf(value);
                filtersClone[id].splice(removableIndex, 1);
            }
            if ((filtersClone && !filtersClone[id].length) || value === 'all') {
                delete filtersClone[id];
            }
            setFilteredValues(filtersClone);
        };

        /**
         * The function handles the change event of a checkbox and updates the filtered values based on the
         * checkbox's state.
         * @param e - The `e` parameter is of type `React.ChangeEvent<HTMLInputElement>`, which represents
         * the event object generated when the checkbox is changed. It contains information about the
         * checkbox element and its new state.
         * @param {any} id - The `id` parameter is used to identify the checkbox element. It is typically a
         * unique identifier or key associated with the checkbox.
         * @param {any} value - The `value` parameter represents the value of the checkbox that was
         * clicked.
         * @param {any} [allValues] - The `allValues` parameter is an optional parameter that represents an
         * array of all possible values for the checkbox group.
         */
        const checkboxPayloadHandler = (
            e: React.ChangeEvent<HTMLInputElement>,
            id: any,
            value: any,
            allValues?: any
        ): void => {
            if (!e.target.checked) {
                handleSetFilteredValues(id, value);
            } else if (value === 'all') {
                setFilteredValues((prev: any) => ({ ...prev, [id]: allValues?.filter((val: any) => val !== 'all') }));
            } else {
                setFilteredValues((prev: any) => ({
                    ...prev,
                    [id]: prev[id] ? prev[id]?.concat([value]) : [value],
                }));
            }
        };

        /**
         * The function checks if a specific value is already checked in a filtered list.
         * @param {string} id - The `id` parameter is a string that represents the identifier of an item.
         * @param {any} accessor - The `accessor` parameter is a variable that represents the property or
         * key of an object. It is used to access a specific value within the object.
         * @param {any} value - The `value` parameter is the total number of items that should be checked.
         * @returns a boolean value. It returns `true` if the conditions inside the if statements are met,
         * and `false` otherwise.
         */
        const alreadyCheckedHandler = (id: string, accessor: any, value: any): any => {
            if (filteredValues && accessor) {
                if (id === 'all') {
                    if (filteredValues[accessor]?.length === value - 1) return true;
                }
                if (filteredValues?.[accessor]?.includes(id)) return true;
                return false;
            }
        };

        /**
         * The function `resetFilters` is used to remove filters from a set of filtered values in a React
         * application.
         * @param {string} key - The key parameter is a string that represents the key of the filter to be
         * reset.
         * @param {any} [type] - The `type` parameter is an optional parameter of type `any`.
         */
        const resetFilters = (key: string, type?: any): void => {
            const filtersClone = JSON.parse(JSON.stringify(filteredValues));
            if (type) {
                setOpenFilterDropDown((prev: any) => ({ ...prev, [key]: false }));
            }
            if (key === 'globalSearch') {
                type.forEach((filter: any) => {
                    delete filtersClone[filter];
                    setOpenFilterDropDown((prev: any) => ({ ...prev, [filter]: false }));
                });
            } else {
                delete filtersClone[key];
            }
            setFilteredValues(filtersClone);
        };

        /* The below code is defining a function called `getTableBodyCellContent` that takes two
    parameters: `header` and `dataObj`. */
        const getTableBodyCellContent = (header: any, dataObj: any): JSX.Element => {
            if (header?.isSelectable) {
                return (
                    <Checkbox
                        color="primary"
                        checked={selectedIds.includes(dataObj?.[props?.keyToTraverse])}
                        onChange={selectionHandler(dataObj?.[props?.keyToTraverse])}
                    />
                );
            } else if (header?.cell) {
                return header?.cell(dataObj);
            }
            return (
                <Typography variant={header?.typeVariant ?? 'body1'}>
                    {dataObj?.[header?.accessor || ''] || 'N/A'}
                </Typography>
            );
        };

        /* The below code is a TypeScript React function called `getHeaderFilterBody`. It takes a `header`
    parameter of type `any` and returns a JSX element. */
        const getHeaderFilterBody = (header: any): JSX.Element => {
            if (header?.filterOptions?.length) {
                const handleChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>): void =>
                    checkboxPayloadHandler(
                        e,
                        header?.accessor,
                        e?.target?.id,
                        header?.filterOptions?.map((opt: any) => opt?.id)
                    );
                return header?.filterOptions?.map((option: any) => (
                    <Box key={option?.id}>
                        <Box
                            key={option?.id}
                            className={`padding-y-8 width-200 ${option?.id === 'all' ? 'border-bottom-1' : ''}`}
                        >
                            <MenuItem value={option?.id}>
                                <Checkbox
                                    className="margin-right-16"
                                    id={option?.id}
                                    checked={alreadyCheckedHandler(
                                        option?.id,
                                        header?.accessor,
                                        header?.filterOptions?.length
                                    )}
                                    onChange={handleChangeCheckbox}
                                />
                                {option?.label}
                            </MenuItem>
                        </Box>
                    </Box>
                ));
            } else if (header?.headerOptions) {
                return header?.headerOptions();
            }
            return (
                <Paper className="padding-16">
                    {' '}
                    <FilledInput
                        size="small"
                        placeholder="Search"
                        className="bg-transparent border-1 border-bottom-none width-200"
                        id={header?.accessor}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                            filterPayloadHandler(e.target.id, e.target.value)
                        }
                        value={filteredValues[header?.accessor] ?? ''}
                        hiddenLabel={true}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={(): void => dropDownHandler(header?.accessor, 'remove')}
                                >
                                    {filteredValues[header?.accessor] ? <HighlightOffIcon /> : <SearchIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Paper>
            );
        };
        return (
            <>
                <TableContainer className={props.wrapperClass ?? ''}>
                    <Table stickyHeader aria-label="sticky table" className={props.containerClass ?? ''}>
                        <TableHead>
                            <TableRow>
                                {props.headers?.map((header: Header) => (
                                    <TableCell key={header?.header} className="tableHeadCell" width={header?.width}>
                                        {header?.isSelectable ? (
                                            <Checkbox
                                                color="primary"
                                                inputProps={{
                                                    'aria-label': 'select all desserts',
                                                }}
                                                checked={
                                                    Boolean(selectedIds.length) &&
                                                    selectedIds.length === props?.data?.length
                                                }
                                                indeterminate={
                                                    Boolean(selectedIds.length) &&
                                                    selectedIds.length < props?.data?.length
                                                }
                                                onChange={handleSelectAll}
                                            />
                                        ) : (
                                            <Stack direction="row">
                                                {header?.header}
                                                {header?.isSortable && (
                                                    <TableSortLabel
                                                        direction={
                                                            sortedData?.key === header?.accessor ||
                                                            sortedData?.key === header?.sortKey
                                                                ? sortedData?.sortType?.toLowerCase()
                                                                : 'asc'
                                                        }
                                                        active={
                                                            sortedData?.key === header?.accessor ||
                                                            Boolean(
                                                                header?.sortKey && sortedData?.key === header?.sortKey
                                                            )
                                                        }
                                                        onClick={(): void =>
                                                            handleSorting(header?.sortKey ?? header?.accessor)
                                                        }
                                                    >
                                                        <Box component="span"></Box>
                                                    </TableSortLabel>
                                                )}

                                                {header?.isFilterable && (
                                                    <>
                                                        <ArrowDropDown
                                                            onClick={(): void => dropDownHandler(header?.accessor)}
                                                        />
                                                        {header?.accessor && openFilterDropDown?.[header?.accessor] && (
                                                            <Paper className="table-search-bar">
                                                                <FormControl
                                                                    variant="filled"
                                                                    className={header?.wrapperClass}
                                                                    size="small"
                                                                    defaultValue="Small"
                                                                    {...((header?.filterOptions ||
                                                                        header?.headerOptions) && {
                                                                        sx: {
                                                                            ...header?.headerSx?.(),
                                                                            padding: '0 !important',
                                                                        },
                                                                    })}
                                                                >
                                                                    {getHeaderFilterBody(header)}
                                                                </FormControl>
                                                            </Paper>
                                                        )}
                                                    </>
                                                )}
                                                {header?.extraOptions?.()}
                                            </Stack>
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading && <TableSkeleton headers={props.headers} />}

                        {!props?.isLoading && props?.data && Boolean(props?.data?.length) && (
                            <TableBody>
                                {props?.data?.map((dataObj: any) => (
                                    <TableRow className="position-relative" key={dataObj?.[props?.keyToTraverse]}>
                                        {props.headers?.map(
                                            (header: Header): JSX.Element => (
                                                <TableCell
                                                    {...(header?.sx && {
                                                        sx: { ':before': header?.sx(dataObj) },
                                                    })}
                                                    key={`unique${dataObj?.[props?.keyToTraverse]}`}
                                                    className="tableHeadCell"
                                                >
                                                    {getTableBodyCellContent(header, dataObj)}
                                                </TableCell>
                                            )
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                        {props?.data && !props?.data?.length && !props.isLoading && (
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={7}>
                                        <EmptyState
                                            className="emptyStateWrapper"
                                            icon={props?.noDataFoundIcon}
                                            title={props?.noDataFoundTitle}
                                            {...(props?.noDataFoundDescription && {
                                                description: props.noDataFoundDescription,
                                            })}
                                            actions={
                                                props?.noDataFoundButtonAction && (
                                                    <Button
                                                        sx={{
                                                            backgroundColor: theme?.palette?.primary?.main,
                                                            '&:hover': {
                                                                backgroundColor: theme?.palette?.primary?.main,
                                                            },
                                                        }}
                                                        variant={'contained'}
                                                        color={'primary'}
                                                        startIcon={<Add />}
                                                        onClick={props?.noDataFoundButtonAction}
                                                    >
                                                        {props?.noDataFoundButtonText ?? ''}
                                                    </Button>
                                                )
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                {props?.isPagination && (
                    <Box alignItems="center" justifyContent="flex-end" flexDirection="row" display="flex" margin={2}>
                        <Typography variant="body1" color="black" fontWeight="400">
                            <CustomTransComponent translationKey={'ALARM_TABLE:LODE_LABEL'} />
                        </Typography>
                        <div style={{ paddingRight: 5 }} />
                        <Typography variant="body1" color="black" fontWeight="700">
                            {props?.data?.length}
                        </Typography>
                        <div style={{ paddingRight: 5 }} />
                        <Typography variant="body1" color="black" fontWeight="400">
                            <CustomTransComponent translationKey={'ALARM_TABLE:OF_LABEL'} />
                        </Typography>
                        <div style={{ paddingRight: 5 }} />
                        <Typography variant="body1" color="black" fontWeight="600">
                            {props.total}
                        </Typography>
                        <div style={{ paddingRight: 40 }} />

                        <Button
                            variant="outlined"
                            style={{ width: 130 }}
                            disabled={Math.floor(props.total / pageSize) <= currentPage}
                            onClick={(): void => setCurrentPage(currentPage + 1)}
                        >
                            {props.isFetching && <CircularProgress size={14} sx={{ marginRight: 1 }} />}
                            <CustomTransComponent translationKey={'ALARM_TABLE:LODE_MORE_LABEL'} />
                        </Button>
                    </Box>
                )}
            </>
        );
    })
);
