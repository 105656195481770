import { api } from '../../app/api/baseApi';
import { PAGINATION_PAYLOAD } from '../../constants/paginationQuery';

export const orgApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getOrg: builder.query({
            query: (body?: any) => ({
                url: `/iam/v1/users/me/orgs`,
                method: 'POST',
                headers: body?.headers && { ...body?.headers },
                body: body?.body ?? {
                    page: 0,
                    size: 200,
                },
            }),
            transformResponse: (response: any) => ({ data: response?.data?.records }),
            providesTags: ['Orgs'],
        }),

        createOrg: builder.mutation({
            query: ({ body, realmPrefix }) => ({
                url: `/iam/v1/orgs`,
                method: 'POST',
                headers: realmPrefix && { 'x-realm': realmPrefix },
                body: body,
            }),
            invalidatesTags: (result, error): any =>
                !error ? ['Profile', 'GroupsHierarchy', 'orgSubscription', 'Notifications'] : [],
            extraOptions: 'no-toast',
        }),
        joinOrg: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users/me/request/initiate`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs', 'Profile'] : []),
        }),

        withdrawOrg: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users/me/request/withdraw`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs'] : []),
        }),

        updateOrg: builder.mutation({
            query: ({ orgId, body, realmPrefix }) => ({
                url: `/iam/v1/orgs/${orgId}`,
                method: 'PATCH',
                headers: realmPrefix ? { 'x-realm': realmPrefix } : {}, // Add realm prefix if it exists
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs', 'orgDetails'] : []),
        }),

        uploadOrganizationLogo: builder.mutation<object, object>({
            query: (payload: { orgId: string; logo: FormData }) => ({
                url: `/iam/v1/orgs/${payload.orgId}/logo`,
                method: 'PATCH',
                body: payload.logo,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs'] : []),
            extraOptions: 'no-toast',
        }),

        switchOrg: builder.mutation({
            query: ({ body, headersData }: any) => ({
                url: `/iam/v1/users/me/switch/orgs`,
                method: 'POST',
                body: body,
                headers: { ...headersData },
            }),
            invalidatesTags: (result, error): any =>
                !error ? ['Orgs', 'Notifications', 'GroupsHierarchy', 'DeviceList', 'Timeline', 'DeviceTags'] : [],
        }),

        deleteOrg: builder.mutation({
            query: ({ body: { orgId }, headersData }) => ({
                url: `/iam/v1/orgs/${orgId}`,
                method: 'DELETE',
                headers: { ...headersData },
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs'] : []),
        }),

        getUsersForRole: builder.mutation({
            query: (body) => ({
                url: `/iam/v1/users/filter`,
                method: 'POST',
                body,
            }),
        }),

        assignRole: builder.mutation({
            query: ({ userId, body }) => {
                return {
                    url: `/iam/v1/users/${userId}/assign/role`,
                    method: 'PATCH',
                    body,
                };
            },
            invalidatesTags: (result, error): any => (!error ? ['Orgs'] : []),
        }),

        leaveOrg: builder.mutation({
            query: ({ orgId }) => ({
                url: `/iam/v1/orgs/${orgId}/leave`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs'] : []),
        }),
        removeOrgLogo: builder.mutation({
            query: ({ orgId }) => ({
                url: `/iam/v1/orgs/${orgId}/logo`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => (!error ? ['Orgs'] : []),
        }),
        getOrgDetails: builder.query({
            query: ({ orgId, currentRealmName, headers }: any) => ({
                url: `/iam/v1/orgs/${orgId}`,
                method: 'GET',
                headers: {
                    ...headers,
                    ...(currentRealmName && { 'x-realm': currentRealmName }),
                },
            }),
            providesTags: ['orgDetails'],
        }),
        getPaginatedOrg: builder.query<any, any>({
            query: (body) => {
                const payloadClone = JSON.parse(JSON.stringify(body));

                delete payloadClone?.pullRefresh;
                delete payloadClone?.realmPrefix;

                return {
                    url: '/iam/v1/users/me/orgs',
                    method: 'POST',
                    body: payloadClone,
                    ...(body?.realmPrefix && {
                        headers: { 'x-realm': body?.realmPrefix },
                    }),
                };
            },
            ...PAGINATION_PAYLOAD,
        }),
    }),
});

export const {
    useGetOrgDetailsQuery,
    useGetOrgQuery,
    useCreateOrgMutation,
    useJoinOrgMutation,
    useWithdrawOrgMutation,
    useUpdateOrgMutation,
    useSwitchOrgMutation,
    useDeleteOrgMutation,
    useGetUsersForRoleMutation,
    useAssignRoleMutation,
    useLeaveOrgMutation,
    useUploadOrganizationLogoMutation,
    useRemoveOrgLogoMutation,
    useGetPaginatedOrgQuery,
} = orgApi;
