function handlePrimaryHeading(widgetType: any, t: any): string {
    switch (widgetType) {
        case 'device_list':
        case 'schedule':
            return t('COMMON:SELECTED_DEVICES');
        case 'group_list':
            return t('COMMON:SELECTED_GROUPS');
        case 'loads':
            return t('WIDGET_MANAGEMENT:SELECT_LOADS');
        default:
            return t('COMMON:SELECTED_GROUPS_AND_DEVICES');
    }
}

function handleSubHeading(widgetType: any, payload: any, t: any): string {
    switch (widgetType) {
        case 'schedule':
            return t('HELPERS_MSGS:SCHEDULE_MSG');
        case 'device_list':
            return t('HELPERS_MSGS:DEVICE_LIST_MSG', {
                replace: { deviceLength: payload?.selectedNodes?.length ?? 0 },
            });
        case 'group_list':
            return t('HELPERS_MSGS:GROUP_LIST_MSG', {
                replace: { groupLength: payload?.selectedNodes?.length ?? 0 },
            });
        case 'loads':
            return t('HELPERS_MSGS:LOADS_MSG', {
                replace: { loadsLength: payload?.selectedNodes?.length ?? 0 },
            });
        case 'timeline':
        default:
            return t('HELPERS_MSGS:TIMELINE_MSG', {
                replace: { loadsLength: payload?.selectedNodes?.length ?? 0 },
            });
    }
}

const getStringGroupPath = (pathName: any[], deviceName: any): string => {
    let path = '';
    if (Array.isArray(pathName)) {
        if (deviceName) path = `${path}${deviceName}`;
        if (pathName?.length > 0 && deviceName) path = `${path} <`;
        const groupPath: any = [...pathName];
        groupPath.forEach((groupName: string, index: number): void => {
            path = `${path} ${groupName}`;
            if (index !== groupPath?.length - 1) path = `${path} <`;
        });
    }
    return path;
};

const getChartMaxValue = (newData: any): any => {
    const sortedData = (a: any, b: any): any => {
        const aVal = a?.value ? +a.value : 0;
        const bVal = b?.value ? +b.value : 0;
        if (aVal > bVal) return -1;
        if (aVal < bVal) return 1;
        return 0;
    };
    return newData?.sort?.(sortedData)[0];
};

const getIntervalValue = (dataValue: any): any => {
    const value: number = getChartMaxValue(dataValue)?.value;
    if (value > 10) return Math.ceil(value / 10);
    return value ? (value / 12)?.toFixed?.(2) : undefined;
};

const isHighAndLow = (threshold: any): any => {
    const high = ['highCritical', 'highCritical'];
    const low = ['lowCritical', 'lowCritical'];
    const thresholdKeys = Object.keys(threshold ?? {});
    return high.find((key) => thresholdKeys?.includes(key)) && low.find((key) => thresholdKeys?.includes(key));
};

const findColor = (threshold: any, value: number): any => {
    const arrThreshold = Object.keys(threshold ?? {});
    const allMaxValue = arrThreshold
        .map((item: string) => ({
            name: item,
            value: threshold[item],
        }))
        .filter((item) => item?.value >= value);
    if (allMaxValue?.length < 1) {
        return '#55BF3B';
    }
    const highestValName = allMaxValue?.sort((a, b) => b?.value - a?.value)?.[0]?.name;
    if (highestValName === 'lowWarning' || highestValName === 'highWarning') {
        return '#DDDF0D';
    } else if (highestValName === 'lowCritical' || highestValName === 'highCritical') {
        return '#DF5353';
    }
    return '#55BF3B';
};

const generatePlotBands = (
    widgetData: any,
    isThreshold: boolean,
    isLowAndHigh?: boolean,
    isHorizontal?: boolean
): any => {
    const criticalColor = widgetData?.viewThreshold && isThreshold ? '#DF5353' : '#55BF3B';
    const warningColor = widgetData?.viewThreshold && isThreshold ? '#DDDF0D' : '#55BF3B';
    const highWarning = widgetData?.threshold?.highWarning ?? 0;
    const highCritical = widgetData?.threshold?.highCritical ?? 0;
    const lowWarning = widgetData?.threshold?.lowWarning ?? 0;
    const lowCritical = widgetData?.threshold?.lowCritical ?? 0;
    if (isHorizontal && !isLowAndHigh) {
        return [
            {
                from: 0,
                to: widgetData?.data?.value ?? 0,
                color: isThreshold ? findColor(widgetData?.threshold, widgetData?.data?.value) : '#55BF3B',
                thickness: 70,
            },
            {
                from: widgetData?.data?.value ?? 0,
                to: 9e9,
                color: '#eeeeee',
                thickness: 70,
            },
        ];
    }
    if (widgetData?.viewThreshold && isThreshold)
        return [
            {
                from: 0,
                to: lowCritical,
                color: criticalColor,
                thickness: 70,
            },
            {
                from: lowCritical,
                to: lowWarning,
                color: warningColor,
                thickness: 70,
            },
            {
                from: lowWarning,
                to: highWarning,
                color: '#55BF3B',
                thickness: 70,
            },
            {
                from: highWarning,
                to: highCritical,
                color: warningColor,
                thickness: 70,
            },
            {
                from: highCritical,
                to: 9e9,
                color: criticalColor,
                thickness: 70,
            },
        ];
    return [
        {
            from: 0,
            to: 9e9,
            color: '#55BF3B',
            thickness: 70,
        },
    ];
};

export {
    handlePrimaryHeading,
    handleSubHeading,
    getStringGroupPath,
    generatePlotBands,
    getChartMaxValue,
    getIntervalValue,
    isHighAndLow,
};
