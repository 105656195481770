import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as Colors from '@brightlayer-ui/colors';
import { Theme, useTheme } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { DateRange } from '@mui/x-date-pickers-pro';
import { DateField } from '@mui/x-date-pickers/DateField';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import Stack from '@mui/material/Stack';
import { CustomTransComponent } from './CustomTransComponent';
type ExportAlaramProps = {
    handleExportSubmit?: (arg0: any, arg1: any) => void | undefined;
};

export const ExportAlarmRecords = React.forwardRef((props: ExportAlaramProps, ref): JSX.Element => {
    React.useImperativeHandle(ref, () => ({
        modalHandler: (action: boolean): void => modalHandler(action),
    }));

    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const todayDate = dayjs().format('YYYY-MM-DD');
    const defaultFromValue = dayjs().subtract(1, 'month');
    const defaultToValue = dayjs();
    const [fromValue, setFromValue] = React.useState<Dayjs | null>(defaultFromValue);
    const [toValue, setToValue] = React.useState<Dayjs | null>(defaultToValue);
    const handleDateRangeCalendarChange = (newValue: DateRange<Dayjs>): void => {
        setFromValue(newValue[0]);
        setToValue(newValue[1]);
    };

    const modalHandler = (action: boolean): void => {
        setIsOpen(action);
        if (!action) {
            setFromValue(defaultFromValue);
            setToValue(defaultToValue);
        }
    };

    const useStyles = makeStyles((theme: Theme) => ({
        muiDialogpaper: {
            padding: '24px 16px',
            [theme.breakpoints.down('sm')]: {
                minHeight: 'calc(100vh - 56px)',
            },
            '& .MuiPaper-root': {
                maxWidth: '80%',
                width: 700,
                height: 700,
                maxHeight: '90%',
                margin: 0,
                justifyContent: 'center',
            },
        },
        typographyTitle: {
            marginBottom: theme.spacing(2),
            fontSize: '20px',
            color: Colors.black[800],
        },
        typographyHeader: {
            fontSize: '28px',
            fontWeight: '600',
            color: '#2B353A',
        },
        typograpghySubHeader: {
            textTransform: 'capitalize',
            fontSize: '16px',
            fontWeight: '700',
            color: '#424E54',
            paddingBottom: '24px',
        },
        typographyPara: {
            fontSize: '16px',
            fontWeight: '400',
            color: '#424E54',
            paddingBottom: '16px',
        },
        typographyParaHeader: {
            fontSize: '16px',
            fontWeight: '600',
            color: '#424E54',
            paddingBottom: '16px',
            textDecoration: 'underline',
        },
        dialogueContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    }));

    const theme: any = useTheme();
    const classes = useStyles(theme);
    const isExportButtonDisabled = (): boolean => !fromValue || !toValue || dayjs(fromValue).isAfter(dayjs(toValue));
    const handleExportClick = (): void => {
        setIsOpen(false);
        if (props.handleExportSubmit) {
            const fromTimestamp = dayjs(fromValue).startOf('day').valueOf();
            let toTimestamp = dayjs(toValue).endOf('day').valueOf();

            if (dayjs(fromValue).isSame(dayjs(toValue), 'day')) {
                toTimestamp = dayjs(fromValue).endOf('day').valueOf();
            }

            props.handleExportSubmit(fromTimestamp, toTimestamp);
        }
    };

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.muiDialogpaper}
        >
            <DialogTitle id="alert-dialog-title" style={{ padding: 32 }} className="border-bottom-1">
                <Typography variant="body1" fontWeight={600} fontSize={20}>
                    <CustomTransComponent translationKey={'EXPORT_ALARM_RECORDS:Export Alarm Records'} />
                </Typography>
            </DialogTitle>
            <DialogContent style={{ padding: 32 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={2}
                        style={{ marginBottom: 20 }}
                    >
                        <DateField
                            value={fromValue}
                            onChange={(newValue): void => setFromValue(newValue)}
                            format="LL"
                            maxDate={dayjs(todayDate)}
                        />
                        <Typography variant="body1" fontWeight={600} fontSize={16}>
                            to
                        </Typography>
                        <DateField
                            value={toValue}
                            onChange={(newValue): void => setToValue(newValue)}
                            format="LL"
                            maxDate={dayjs(todayDate)}
                        />
                    </Stack>
                    <DateRangeCalendar
                        value={[fromValue, toValue]}
                        onChange={handleDateRangeCalendarChange}
                        className="justify-content-center"
                        maxDate={dayjs(todayDate)}
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions className="adding-top-24" style={{ padding: 24 }}>
                <Button
                    sx={{
                        border: `1px solid ${theme?.palette?.primary?.main}`,
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.[50],
                        },
                    }}
                    variant="outlined"
                    onClick={(): void => modalHandler(false)}
                    style={{ marginRight: 24 }}
                >
                    <CustomTransComponent translationKey={'ACCEPT_EULA:CANCEL_BTN'} />
                </Button>
                <Button
                    sx={{
                        backgroundColor: theme?.palette?.primary?.main,
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.main,
                        },
                    }}
                    variant="contained"
                    startIcon={<GetAppRoundedIcon />}
                    onClick={handleExportClick}
                    disabled={isExportButtonDisabled()}
                >
                    <CustomTransComponent translationKey={'COMMON:EXPORT'} />
                </Button>
            </DialogActions>
        </Dialog>
    );
});
