import React, { ReactNode } from 'react';

import {
    toggleScheduleViewMode,
    toggleScheduleGrouping,
    setHoveredScheduleFilterDate,
    setSelectedScheduleFilterDate,
    setSchedulesForComponent,
} from '@fiji/common/src/features/component/schedule/scheduleSlice';

import { CustomDayPicker } from '../WeekView';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { Stack, Switch, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { CustomTransComponent } from 'components/CustomTransComponent';
import moment from 'moment';

type ComponentProps = {
    groupingEnabled?: boolean;
    rightComponents?: Array<{ [key: string]: unknown }>;
} & (ComponentPropsBase | GroupingProps);

type ComponentPropsBase = {
    groupingEnabled?: false;
};

type GroupingProps = {
    groupingEnabled: true;
    groupingLabel: ReactNode;
};

export const ScheduleHeader = (props: ComponentProps): JSX.Element => {
    const { groupingEnabled, rightComponents } = props;

    const dispatch = useAppDispatch();

    const mode = useTypedSelector((state: any) => state['schedule']['mode']);
    const selectedDate = useTypedSelector((state: any) => state['schedule']['selectedDate']);
    const isGroupingEnabled = useTypedSelector((state: any) => state['schedule']['isGroupingEnabled']);

    const getDaySlot = (dayProps: any): JSX.Element => <CustomDayPicker {...dayProps} />;

    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={3} alignItems={'center'}>
                <ToggleButtonGroup
                    value={mode}
                    exclusive
                    onChange={(e: React.ChangeEvent<any>) => {
                        dispatch(toggleScheduleViewMode(e.target.value));
                        dispatch(setSchedulesForComponent([]));
                    }}
                    aria-label="text alignment"
                >
                    <ToggleButton sx={{ textTransform: 'none' }} value="day" aria-label="centered">
                        <CustomTransComponent translationKey={'COMMON:DAY'} />
                    </ToggleButton>
                    <ToggleButton sx={{ textTransform: 'none' }} value="week" aria-label="centered">
                        <CustomTransComponent translationKey={'COMMON:WEEK'} />
                    </ToggleButton>
                </ToggleButtonGroup>
                <DatePicker
                    value={moment(selectedDate)}
                    format="LL"
                    showDaysOutsideCurrentMonth
                    onChange={(selectedDatefilter: any) => {
                        if (mode === 'day') {
                            dispatch(setSelectedScheduleFilterDate(moment(selectedDatefilter).toISOString()));
                        } else {
                            dispatch(
                                setSelectedScheduleFilterDate(moment(selectedDatefilter).startOf('week').toISOString())
                            );
                        }
                    }}
                    {...(mode === 'week' && {
                        slots: { day: getDaySlot },
                    })}
                    {...(mode === 'week' && {
                        slotProps: {
                            day: (ownerState: any) =>
                                ({
                                    selectedDay: moment(selectedDate),
                                    onPointerEnter: () =>
                                        dispatch(setHoveredScheduleFilterDate(moment(ownerState?.day).toISOString())),
                                    onPointerLeave: () => dispatch(setHoveredScheduleFilterDate(null)),
                                } as any),
                        },
                    })}
                ></DatePicker>
            </Stack>
            {groupingEnabled && (
                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                    <Typography variant="subtitle1" fontSize={'16px'} fontWeight={'600'}>
                        {props.groupingLabel}
                    </Typography>
                    <Switch checked={isGroupingEnabled} onChange={() => dispatch(toggleScheduleGrouping())} />
                </Stack>
            )}
            {rightComponents?.length && (
                <Stack direction={'row'} spacing={2}>
                    {rightComponents?.map((componentDetails: any) => {
                        const RightComponent = componentDetails.component;
                        return <RightComponent key={componentDetails.id} {...componentDetails} />;
                    })}
                </Stack>
            )}
        </Stack>
    );
};
