import React from 'react';

import { useConfirm } from '@fiji/common/src/hooks';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useEditScheduleStatusMutation } from '@fiji/common/src/features/schedule/scheduleApi';
import { useAppDispatch } from '@fiji/common';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { t } from 'i18next';
import { CustomTransComponent } from 'components';

export const DisableScheduleModal = React.forwardRef((_, ref): JSX.Element => {
    const dispatch = useAppDispatch();
    const [editSchedule, { isLoading }] = useEditScheduleStatusMutation();
    const handleNextSchedule = async (): Promise<void> => {
        const { error }: any = await editSchedule({
            scheduleId: data?.id,
            params: {
                status: 'SKIPNEXTTRIGGER',
            },
        });
        if (!error) {
            dispatch(setMessageContent({ isOpen: true, message: t('SCHEDULING:SKIPPED_NEXT_SCHEDULE') }));
            onCancel(true);
        }
    };

    const handleSubmit = async (): Promise<void> => {
        const { error }: any = await editSchedule({
            scheduleId: data?.id,
            params: {
                status: data?.enabled ? t('SCHEDULING:DISABLE') : t('SCHEDULING:ENABLE'),
            },
        });
        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: `${data?.name} ${data?.enabled ? t('COMMON:DISABLED') : t('COMMON:ENABLED')} ${t(
                        'SCHEDULING:SUCCESSFULLY'
                    )}`,
                })
            );
            onCancel(true);
        }
    };
    const { data, isVisible, onCancel, onClick, onConfirm } = useConfirm(handleSubmit);

    React.useImperativeHandle(ref, () => ({
        handleDisableModal: onClick,
    }));
    return (
        <Dialog onClose={(): void => onCancel(true)} open={isVisible}>
            <DialogTitle>
                <CustomTransComponent translationKey={'SCHEDULING:DISABLE_SCHEDULE'} />
            </DialogTitle>
            <DialogContent sx={{ width: '444px' }}>
                <Typography variant="body1">
                    <CustomTransComponent translationKey={'SCHEDULING:CHOOSE_ALL_EVENTS'} />
                </Typography>

                <Stack mt={4}>
                    <Typography variant="subtitle2" fontWeight={'600'}>
                        {data?.name ?? '-'}
                    </Typography>
                    <Stack spacing={1} mt={1}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant="body2">
                                <CustomTransComponent translationKey={'COMMON:DEVICES'} />
                            </Typography>
                            <Typography variant="subtitle2" fontWeight={'600'} fontSize={'14px'}>
                                {data?.deviceCount ?? '-'}
                            </Typography>
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant="body2">
                                <CustomTransComponent translationKey={'COMMON:ACTION'} />
                            </Typography>
                            {data?.actions?.length > 2 ? (
                                <Typography variant="subtitle2" fontWeight={'600'} fontSize={'14px'}>
                                    {`${data?.actions[0]?.name}, ${data?.actions[1]?.name} and ${
                                        data?.actions?.length - 2
                                    } others`}
                                </Typography>
                            ) : (
                                data?.actions?.map((item: any) => (
                                    <Typography key={item?.id} variant="subtitle2" fontWeight={'600'} fontSize={'14px'}>
                                        {item?.name ?? '-'}
                                    </Typography>
                                ))
                            )}
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant="body2">
                                <CustomTransComponent translationKey={'COMMON:FREQUENCY'} />
                            </Typography>
                            <Typography variant="subtitle2" fontWeight={'600'} fontSize={'14px'}>
                                {data?.frequency ?? '-'}
                            </Typography>
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant="body2">
                                <CustomTransComponent translationKey={'COMMON:EXCEPTION'} />
                            </Typography>
                            <Typography variant="subtitle2" fontWeight={'600'} fontSize={'14px'}>
                                <CustomTransComponent translationKey={'SCHEDULING:HOLIDAY'} />
                            </Typography>
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant="body2">
                                <CustomTransComponent translationKey={'SCHEDULING:NEXT_SCHEDULE'} />
                            </Typography>
                            <Typography variant="subtitle2" fontWeight={'600'} fontSize={'14px'}>
                                {data?.nextRun ? moment(data?.nextRun)?.format('LL') : '-'}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions className="border-top-1">
                <Stack direction={'row'} alignItems={'center'} spacing={3}>
                    <Button variant="outlined" color="primary" onClick={(): void => onCancel(true)}>
                        <CustomTransComponent translationKey={'COMMON:CLOSE'} />
                    </Button>
                    <Button variant="outlined" color="primary" disabled={isLoading} onClick={handleNextSchedule}>
                        <CustomTransComponent translationKey={'SCHEDULING:NEXT_SCHEDULE'} />
                    </Button>
                    <Button variant="contained" color="primary" disabled={isLoading} onClick={onConfirm}>
                        <CustomTransComponent translationKey={'COMMON:ALL_EVENTS'} />
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
});
