/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import Bullet from 'highcharts/modules/bullet';
import { generatePlotBands, isHighAndLow } from 'pages/WidgetManagement/common/utils/helpers';

Bullet(Highcharts);
HighchartsMore(Highcharts);

export const HorizontalGauge = ({
    gaugeWidget,
    pointConversion,
}: {
    gaugeWidget: any;
    pointConversion: any;
}): JSX.Element => {
    const ref: any = useRef(null);
    const [chartOptions, setChartOptions] = useState({
        chart: {
            inverted: true,
            type: 'bullet',
        },
        title: {
            text: null,
        },
        legend: {
            enabled: false,
        },
        yAxis: {
            gridLineWidth: 0,
        },
        plotOptions: {
            series: {
                pointPadding: 0.25,
                borderWidth: 0,
                color: '#000',
                targetOptions: {
                    width: '200%',
                },
            },
        },
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
    });

    useEffect(() => {
        if (ref?.current && gaugeWidget?.data) {
            const isThreshold =
                Boolean(Object?.keys(gaugeWidget?.threshold ?? {}).length) &&
                Object?.values(gaugeWidget?.threshold ?? {}).some((item: any) => Boolean(item)) &&
                gaugeWidget?.viewThreshold;
            const cloneOption = JSON.parse(JSON.stringify(chartOptions));
            cloneOption['chart'] = {
                inverted: true,
                type: 'bullet',
                height: gaugeWidget?.viewScale ? 100 : 70,
            };
            cloneOption['series'] = [
                {
                    name: gaugeWidget.data?.displayName,
                    data: [
                        isHighAndLow(gaugeWidget?.threshold) && isThreshold
                            ? { target: gaugeWidget?.data?.value ?? 0 }
                            : 0,
                    ],
                    tooltip: {
                        valueSuffix: gaugeWidget?.data?.unit,
                    },
                },
            ];
            cloneOption['yAxis'] = {
                ...(chartOptions?.yAxis ?? {}),
                min: gaugeWidget?.min ?? 0,
                max: gaugeWidget?.max ?? gaugeWidget?.data?.value,
                tickPixelInterval: 72,
                tickPosition: 'inside',
                tickColor: Highcharts.defaultOptions.chart?.backgroundColor || '#FFFFFF',
                tickLength: 70,
                tickWidth: 2,
                minorTickInterval: null,
                labels: {
                    distance: 20,
                    enabled: gaugeWidget?.viewScale ?? false,
                    style: {
                        fontSize: '14px',
                    },
                },
                title: null,
                lineWidth: 0,
                plotBands: generatePlotBands(
                    gaugeWidget,
                    isThreshold,
                    isHighAndLow(gaugeWidget?.threshold) && isThreshold,
                    true
                ),
            };
            cloneOption['xAxis'] = {
                tickAmount: undefined,
                tickLength: null,
                labels: {
                    useHTML: true,
                    format: `<p class="hc-cat-title">${pointConversion(
                        gaugeWidget?.data?.value ?? 0
                    )}<span style="font-size: 18px;
    font-weight: 400;
    color: #727E84;">${gaugeWidget?.data?.unit ?? ''}</span></p>`,
                },
            };
            setChartOptions(cloneOption);
        }
    }, [gaugeWidget]);

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={ref} />;
};
