import React from 'react';
import { Create } from '@mui/icons-material';
import CustomIcon from '../../components/CustomIcon';
import { useParams, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { useDebounce, useRBAC } from '../../hooks';
import {
    Container,
    IconButton,
    List,
    Stack,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Skeleton,
    Button,
} from '@mui/material';
import { ChannelValue, InfoListItem } from '@brightlayer-ui/react-components';
import EditNotifications from '@mui/icons-material/EditNotifications';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import { useTheme } from '@mui/material/styles';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { NotificationLog } from '@brightlayer-ui/icons-mui';
import { ListSkeleton } from 'pages/WidgetManagement/common/Skeletons';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ThresholdModal } from './ThresholdModal';
import { useCustomDetailsApi, useHandleDeviceDetails } from './hooks';
import { CustomEmptyState } from 'components/CustomEmptyState';
import { CustomTransComponent } from 'components';
import { useTransformData } from 'hooks/useTransformData';
import { InputWithCustomPlaceholder } from 'components/InputWithCustomPlaceholder';
import { useTranslation } from 'react-i18next';
import { subscribeTopic } from '@fiji/common/src/mqtt_connection/mqttConnection';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { UserProfile } from '@fiji/common/src/types';
import { ApiEndpointType, ApiResponseType } from '@fiji/common/src/features/common/commonTypes';

export const DeviceDetails = (): JSX.Element => {
    const theme: any = useTheme();
    const { t } = useTranslation();
    const [searchKey, setSearchKey] = React.useState<any>(undefined);
    const navigate = useNavigate();
    const { deviceId } = useParams();
    const thresholdModalRef = React.useRef<any>(null);
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const selectedNode = useTypedSelector((state) => state['common']['selectedNode']);
    const [, debouncedValue] = useDebounce(undefined, 300, searchKey);
    const { pointConversion } = useTransformData();
    const currentOrg = useTypedSelector(selectedOrg);

    const { data: profileData } = useGetUserProfileQuery({}, { refetchOnMountOrArgChange: true }) as ApiEndpointType<
        ApiResponseType<UserProfile>
    >;

    const [deviceDetails, { isLoading, updateDetails }] = useCustomDetailsApi(deviceId, debouncedValue);

    const { thresholdUpdateLoader, handleAllExpandCollapse, expandedCategories, setExpandedCategories } =
        useHandleDeviceDetails(deviceDetails);
    const mqttConnectionStatus = useTypedSelector((state) => state['common']['mqttConnectionStatus']);

    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);
    const canUpdateThreshold = hasPermission('edit-devices');
    const canManageThreshold = hasPermission('telemetry-thresholds');

    React.useEffect(() => {
        if (mqttConnectionStatus === true && profileData?.data && selectedNode?.groupId)
            subscribeTopic(
                `BSSRM/TREND/${profileData?.data?.currentRealmId}/${currentOrg?.id}/${selectedNode?.groupId}/${deviceId}/devices`,
                (message: any, topic: any) => updateDetails(message, topic)
            );
    }, [mqttConnectionStatus, selectedNode, profileData?.data]);

    const renderDetailAccordions = (item: any): JSX.Element => (
        <Accordion
            key={item}
            expanded={expandedCategories[item] ?? true}
            onChange={(): void => {
                setExpandedCategories((prev: any) => ({ ...prev, [item]: !prev[item] }));
            }}
            defaultExpanded
        >
            <AccordionSummary
                expandIcon={<ExpandMore color="primary" />}
                aria-controls="panel1bh-content"
                id="expand"
                className="border-bottom-1"
            >
                <Typography
                    variant="body2"
                    fontWeight={'600'}
                    fontSize={'14px'}
                    sx={{ color: theme.palette?.primary?.main }}
                >
                    {item ?? '--'}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className="padding-0">
                <List disablePadding>
                    {deviceDetails?.data[item]?.map((channel: any) => (
                        <InfoListItem
                            key={channel?.channelId}
                            icon={
                                <CustomIcon
                                    family={channel?.icon?.web?.family ?? ''}
                                    iconName={channel?.icon?.web?.name ?? ''}
                                />
                            }
                            divider="full"
                            title={channel?.displayName}
                            rightComponent={
                                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                                    <ChannelValue
                                        unitSpace="show"
                                        value={
                                            channel?.value !== 'true' && channel?.value !== 'false'
                                                ? pointConversion(channel?.value)
                                                : channel?.value
                                        }
                                        units={channel?.unit}
                                    />
                                    {canManageThreshold && channel?.type?.includes?.('Trend') && (
                                        <IconButton
                                            id="threshold"
                                            disabled={selectedNode?.connectionStatus === 'Offline'}
                                            onClick={(): void => thresholdModalRef?.current?.onClick(channel)}
                                        >
                                            {canUpdateThreshold ? <EditNotifications /> : <NotificationLog />}
                                        </IconButton>
                                    )}
                                </Stack>
                            }
                        />
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    );

    const getAccordionContent = (): any => {
        if (isLoading || thresholdUpdateLoader) {
            return (
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMore color="primary" />}
                        aria-controls="panel1bh-content"
                        id="expand"
                        className="border-bottom-1"
                    >
                        <Skeleton animation="wave">
                            <Typography
                                variant="body2"
                                fontWeight={'600'}
                                fontSize={'14px'}
                                sx={{ color: theme.palette?.primary?.main }}
                            >
                                <CustomTransComponent translationKey={'COMMON:PRIORITY'} />
                            </Typography>
                        </Skeleton>
                    </AccordionSummary>
                    <AccordionDetails className="padding0">
                        <ListSkeleton total={10} customSize={25} customSx={{ height: '4.5rem' }} />
                    </AccordionDetails>
                </Accordion>
            );
        } else if (Object.keys(deviceDetails?.data ?? {})?.length) {
            return Object.keys(deviceDetails?.data)?.map((item: any) => renderDetailAccordions(item));
        }
        return <CustomEmptyState icon title={<CustomTransComponent translationKey={'COMMON:NO_DETAILS'} />} />;
    };

    return (
        <>
            <Container className="padding-y-36">
                <Stack mb={2} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'} alignItems={'center'} spacing={3}>
                        <InputWithCustomPlaceholder
                            id="searchKey"
                            value={searchKey ?? ''}
                            placeholder={t('COMMON:SEARCH_PLACEHOLDER')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                setSearchKey(e.target.value);
                            }}
                        />
                    </Stack>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <Button
                            id="toggleExpand"
                            sx={{
                                border: `1px solid ${theme?.palette?.primary?.main}`,
                                '&:hover': {
                                    backgroundColor: theme?.palette?.primary?.[50],
                                },
                            }}
                            variant="outlined"
                            onClick={handleAllExpandCollapse}
                            startIcon={<UnfoldMore />}
                        >
                            {Object?.values(expandedCategories)?.every((item: any) => item === true)
                                ? t('DASHBOARD_MANAGEMENT:COLLAPSE_ALL')
                                : t('DASHBOARD_MANAGEMENT:EXPAND_ALL')}
                        </Button>
                        <IconButton
                            id="edit-channel"
                            aria-label="settings"
                            onClick={(): void => navigate(`/${currentRealmName}/channelSelector/${deviceId}`)}
                        >
                            <Create className="color-content" />
                        </IconButton>
                    </Stack>
                </Stack>

                <Stack>{getAccordionContent()}</Stack>
            </Container>

            <ThresholdModal ref={thresholdModalRef} />
        </>
    );
};
