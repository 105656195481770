import { useDeleteVirtualLoadByIdMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { Stack, Typography } from '@mui/material';
import { CustomModal, CustomTransComponent } from 'components';
import { ModalActions } from 'components/CustomModal';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useConfirm } from '@fiji/common/src/hooks';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { useNavigationBack } from 'hooks';
import { useTranslation } from 'react-i18next';

export const DeleteVirtualModal = React.forwardRef((props: any, ref): JSX.Element => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigateBack = useNavigationBack();
    const navigateBackHandler = (): void => {
        navigateBack();
    };

    const [deleteVirtualLoad, { isLoading }] = useDeleteVirtualLoadByIdMutation();
    const submitHandler = async (data: any): Promise<void> => {
        const { error }: any = await deleteVirtualLoad(data?.id);
        if (!error) {
            if (props?.isNavigationBack) navigateBackHandler();
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: t('ROLE_MANAGEMENT:MESSAGES.DELETE_ROLE_SUCCESS', { replace: { roleName: data?.name } }),
                })
            );
            onCancel();
        }
    };
    const { data: deleteData, isVisible, onCancel, onClick, onConfirm } = useConfirm(submitHandler as any);
    const actions: ModalActions[] = [
        {
            testId: 'cancel',
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: onCancel,
        },
        {
            testId: 'delete',
            key: 'delete',
            label: <CustomTransComponent translationKey={'COMMON:DELETE'} />,
            color: 'error',
            className: 'error-btn',
            variant: 'contained',
            disabled: !deleteData?.id || isLoading,
            handleClick: onConfirm,
        },
    ];
    React.useImperativeHandle(ref, () => ({
        onClick: (action: boolean) => onClick(action),
    }));
    return (
        <CustomModal
            isOpen={isVisible}
            isLoading={isLoading}
            type="primary"
            header={
                <CustomTransComponent translationKey={'COMMON:DELETE_HEADER'} replace={{ name: deleteData?.name }} />
            }
            actions={actions}
            hidePadding
            noDividers
            actionsDivider
        >
            <Stack component="form" spacing={2} noValidate autoComplete="off" m={2}>
                <Typography variant="body1">{`${t('COMMON:SELECT_DELETE_TO_REMOVE')} ${deleteData?.name}.`}</Typography>
            </Stack>
        </CustomModal>
    );
});
