import React from 'react';

import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { setCurrentTime } from '@fiji/common/src/features/component/schedule/scheduleSlice';
import { CustomTransComponent } from 'components/CustomTransComponent';

export const CurrentDateTimeIndicator = React.memo((): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentTime = useTypedSelector((state) => state.schedule.currentTime);
    const currentHour = new Date(currentTime)?.getHours();
    const currentMinutes = new Date(currentTime)?.getMinutes();
    const currentPosition = ((currentHour * 60 + currentMinutes) / 1440) * 100;

    React.useEffect(() => {
        const interval = setInterval(() => {
            dispatch(setCurrentTime(new Date()?.toISOString()));
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div
            style={{
                position: 'relative',
                left: `${currentPosition}%`,
                width: '2px',
                backgroundColor: '#424E54',
                zIndex: 2,
                marginTop: '-68px',
            }}
            className="current-time-indicator"
        >
            <div
                style={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: '#424E54',
                    color: 'white',
                    fontSize: '12px',
                }}
                className="border-radius-18 padding-x-18 padding-y-8 fw-600 width-130"
            >
                <CustomTransComponent translationKey={'COMMON:CURRENT_TIME'} />
            </div>
        </div>
    );
});
