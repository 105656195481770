import React from 'react';

import { CustomModal, CustomTransComponent } from 'components';
import { Box, InputAdornment, List, Stack, Switch, TextField } from '@mui/material';
import { ChannelValue, InfoListItem } from '@brightlayer-ui/react-components';
import { NorthEast, SouthWest } from '@mui/icons-material';
import { CustomSlider } from './';
import { ModalActions } from 'components/CustomModal';
import { useConfirm } from '@fiji/common/src/hooks';
import { useHandleDeviceDetails } from './hooks';
import { useGetThresholdQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useParams } from 'react-router-dom';
import { useRBAC } from 'hooks';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useTypedSelector } from '@fiji/common';
import { t } from 'i18next';

export const thresholdName: any = {
    lowCritical: t('THRESHOLD_CONFIGURATION:LOW_CRITICAL'),
    lowWarning: t('THRESHOLD_CONFIGURATION:LOW_WARNING'),
    highWarning: t('THRESHOLD_CONFIGURATION:HIGH_WARNING'),
    highCritical: t('THRESHOLD_CONFIGURATION:HIGH_CRITICAL'),
};

export const ThresholdModal = React.memo(
    React.forwardRef((_props: any, ref): JSX.Element => {
        const { deviceId } = useParams();
        const { data, isVisible, onCancel, onClick, onConfirm } = useConfirm((args: any): any => {
            submitHandler(args, onCancel) as any;
        });

        const permissions = useTypedSelector(selectCurrentPermission);

        const { hasPermission } = useRBAC(permissions);
        const canUpdateThreshold = hasPermission('edit-devices');

        const [isChangedByInput, setIsChangedByInput] = React.useState(true);

        const { payload, setPayload, resetPayload, submitHandler, thresholdUpdateLoader } = useHandleDeviceDetails();

        const {
            currentData: thresholdData,
            isLoading,
            isFetching,
            isError,
        } = useGetThresholdQuery(
            { channelKey: data?.channelId, deviceId },
            { skip: !deviceId || !data?.channelId, refetchOnMountOrArgChange: true }
        );

        React.useImperativeHandle(
            ref,
            () => ({
                onClick,
            }),
            []
        );

        React.useEffect(() => {
            if (thresholdData?.data?.threshold?.length && isVisible) {
                const newPayload = JSON.parse(JSON.stringify(payload ?? []));
                setPayload(
                    newPayload?.map((item: any) => ({
                        ...item,
                        value: thresholdData.data.threshold?.find((ele: any) => ele?.name === item?.id)?.value,
                        isEnabled:
                            thresholdData.data.threshold?.find((ele: any) => ele?.name === item?.id)?.isEnabled ??
                            false,
                    }))
                );
            }
        }, [thresholdData, isVisible]);

        const getIsDisabled = (): boolean =>
            !payload?.some((item: any) => {
                const oldData: any = thresholdData?.data?.threshold?.find((ele: any) => ele?.name === item?.id);
                return oldData?.value !== item.value || oldData?.isEnabled !== item?.isEnabled;
            });
        const getSubtitle = (element: any): string => {
            let str = '';
            const newPayload = payload?.filter((item: any) => item?.isEnabled);
            const index = newPayload.findIndex((item: any) => item?.id === element?.id);
            const prev = index > 0 ? newPayload[index - 1] : {};

            const next = index < newPayload?.length - 1 && index > -1 ? newPayload[index + 1] : {};

            if (prev?.value >= element?.value) {
                str = `${(<CustomTransComponent translationKey={'COMMON:MUST_BE_GREATER_THAN'} />) as any} ${
                    prev?.name
                }`;
            } else if (next?.value <= element?.value) {
                str = `${(<CustomTransComponent translationKey={'COMMON:MUST_BE_LESS_THAN'} />) as any} ${next?.name}`;
            }
            return str;
        };
        const disableSave = (): boolean =>
            !payload || payload?.filter((item: any) => item?.isEnabled)?.some((ele: any) => getSubtitle(ele)?.length);

        const actions: ModalActions[] = [
            {
                key: 'cancel',
                label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
                close: true,
                variant: 'outlined',
                handleClick: (): void => {
                    onCancel(true);
                    resetPayload();
                },
            },
            {
                key: 'save',
                label: thresholdUpdateLoader ? t('COMMON:SAVING') : t('COMMON:SAVE_LABEL'),
                variant: 'contained',
                isLoading: thresholdUpdateLoader,
                disabled: getIsDisabled() || isError || disableSave(),
                handleClick: onConfirm,
            },
        ];

        const handleThresholdChange = (e: any): void => {
            if (!isNaN(e.target?.value) && +e.target?.value <= (thresholdData?.data?.max || data?.value || 10)) {
                const { id, value } = e.target;
                const updatedPayload = payload.map((item: any) =>
                    item?.id === id ? { ...item, value: +value } : item
                );
                setPayload(updatedPayload);
                setIsChangedByInput(true);
            }
        };

        const toggleEnabledHandler = (eleName: string, isEnabled: boolean): void => {
            const updatedPayload = payload.map((item: any) =>
                item?.id === eleName ? { ...item, isEnabled: isEnabled } : item
            );
            setPayload(updatedPayload);
            setIsChangedByInput(true);
        };

        const getIcon = (eleName: string): JSX.Element => {
            switch (eleName) {
                case 'lowCritical':
                    return <SouthWest color="error" />;
                case 'lowWarning':
                    return <SouthWest color="warning" />;
                case 'highWarning':
                    return <NorthEast color="warning" />;
                case 'highCritical':
                    return <NorthEast color="error" />;
                default:
                    return <></>;
            }
        };

        return (
            <CustomModal
                isOpen={isVisible}
                isLoading={isLoading || isFetching}
                type="medium"
                header={`${canUpdateThreshold ? t('COMMON:EDIT') : t('COMMON:VIEW')} ${
                    data?.displayName ?? '-'
                } Thresholds`}
                actions={
                    canUpdateThreshold
                        ? actions
                        : ([
                              {
                                  key: 'cancel',
                                  label: t('blui:ACTIONS.OKAY'),
                                  close: true,
                                  variant: 'contained',
                                  handleClick: onCancel,
                              },
                          ] as any)
                }
                hidePadding
                noDividers
                actionsDivider
                subHeader={<CustomTransComponent translationKey={'DEVICE_DETAILS:SET_THRESHOLDS_VALUES'} />}
                footerClass={canUpdateThreshold ? 'space-between-footer' : ''}
            >
                <Stack py={2} px={3} m={2}>
                    <List disablePadding>
                        {payload?.map(
                            (ele: any): JSX.Element => (
                                <InfoListItem
                                    key={ele?.id}
                                    sx={{
                                        '& .BluiInfoListItem-subtitle': {
                                            color: 'red',
                                        },
                                    }}
                                    disableGutters
                                    icon={getIcon(ele?.id)}
                                    title={ele?.name}
                                    subtitle={ele?.isEnabled ? getSubtitle(ele) : ''}
                                    rightComponent={
                                        <Box
                                            maxWidth={'200px'}
                                            display={'flex'}
                                            flexDirection={'row'}
                                            alignItems={'center'}
                                        >
                                            {canUpdateThreshold ? (
                                                <>
                                                    <TextField
                                                        variant="filled"
                                                        value={ele?.value}
                                                        onChange={handleThresholdChange}
                                                        id={ele?.id}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    {data?.unit}
                                                                </InputAdornment>
                                                            ),
                                                            readOnly: !ele.isEnabled,
                                                        }}
                                                    />
                                                    <Switch
                                                        checked={ele?.isEnabled}
                                                        onChange={() => toggleEnabledHandler(ele?.id, !ele?.isEnabled)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </>
                                            ) : (
                                                <ChannelValue value={ele?.value ?? 0} units={data?.unit} />
                                            )}
                                        </Box>
                                    }
                                />
                            )
                        )}
                    </List>
                    <CustomSlider
                        isDisabled={!canUpdateThreshold}
                        payload={payload}
                        setPayload={setPayload}
                        min={thresholdData?.data?.min || 0}
                        max={thresholdData?.data?.max || data?.value}
                        unit={data?.unit}
                        thresholdData={thresholdData}
                        isVisible={isVisible}
                        isChangedByInput={isChangedByInput}
                        setIsChangedByInput={setIsChangedByInput}
                    />
                </Stack>
            </CustomModal>
        );
    })
);
