import { CloudOffFilled } from '@brightlayer-ui/icons-mui';
import { Hero, HeroBanner, InfoListItem, ListItemTag } from '@brightlayer-ui/react-components';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import HelpCenter from '@mui/icons-material/HelpCenter';
import Info from '@mui/icons-material/Info';
import NotificationsActive from '@mui/icons-material/NotificationsActive';
import Warning from '@mui/icons-material/Warning';
import { Avatar, Divider, List, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import CustomIcon from '../../../../components/CustomIcon';
import { WidgetFooter } from 'pages/WidgetManagement/common/WidgetFooter';
import { useTheme } from '@mui/material/styles';
import { CustomTransComponent, GroupPath } from 'components';
import { getListItemBackgroundColor, getListItemTextColor } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';
import { useTransformData } from 'hooks/useTransformData';
import { CustomEmptyState } from 'components/CustomEmptyState';
import { getAlarmLabelValue } from '@fiji/common/src/utils/helpers';

const heroTitle: any = {
    alarms: { title: 'Alarms', subtitle: 'Active' },
    offline: { title: 'Offline', subtitle: 'Active' },
    warnings: { title: 'Warning', subtitle: 'Active' },
    information: { title: 'Informational', subtitle: 'Active' },
};

const filterName: any = {
    alarms: 'Alarm Active',
    offline: 'Offline',
    warnings: 'Warning Active',
    information: 'Informational',
};

const Component = ({
    widgetData: detailWidget,
    mode,
    dropType,
}: {
    widgetData: any;
    mode: string;
    dropType?: string;
}): JSX.Element => {
    const theme: any = useTheme();
    const navigate = useNavigate();
    const { convertDateTime } = useTransformData();

    const getHeaderTitle = (): JSX.Element => (
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            ml={1}
            sx={
                mode === 'edit'
                    ? { position: 'absolute', top: '0px', height: '48px', left: '50px' }
                    : { height: '48px', padding: '16px' }
            }
        >
            <Typography variant="subtitle2" color="primary" fontWeight={'600'} fontSize={'14px'}>
                {detailWidget?.widgetName}
            </Typography>
        </Stack>
    );

    const getIcon = (count: number, status: string): JSX.Element => {
        switch (status) {
            case 'alarms':
                return <NotificationsActive {...(count && { color: 'error' })} fontSize={'inherit'} />;
            case 'offline':
                return <CloudOffFilled className={count ? 'sky-800' : ''} fontSize={'inherit'} />;
            case 'warnings':
                return <Warning {...(count && { color: 'warning' })} fontSize={'inherit'} />;
            case 'information':
                return <Info sx={{ color: count ? theme?.palette?.primary?.main : '' }} fontSize={'inherit'} />;
            default:
                return <></>;
        }
    };

    const getRightComponent = (data: any): JSX.Element => {
        if (data?.status === 'Active' || data?.status === 'Cleared' || data?.status === 'Acknowledged') {
            return (
                <ListItemTag
                    sx={{
                        border: data?.status === 'Cleared' ? '1px solid #424E54' : 'none',
                    }}
                    label={getAlarmLabelValue(data)}
                    fontColor={getListItemTextColor(data)}
                    backgroundColor={getListItemBackgroundColor(data)}
                />
            );
        }
        return <></>;
    };
    return (
        <Paper sx={{ height: '100%' }}>
            {getHeaderTitle()}
            {mode !== 'edit' && <Divider />}
            <Stack sx={{ height: '100%' }}>
                {detailWidget?.showSeverityCount && (
                    <HeroBanner className="border-bottom-1">
                        {Object.keys(detailWidget?.statusCount ?? {}).map(
                            (status: string) =>
                                detailWidget?.preferences?.includes?.(filterName[status]) && (
                                    <Hero
                                        key={status}
                                        icon={getIcon(detailWidget?.statusCount?.[status], status)}
                                        label={
                                            (
                                                <Stack>
                                                    <Typography fontSize={'14px'} fontWeight={'700'}>
                                                        {heroTitle[status]?.title}
                                                    </Typography>
                                                    <Typography fontSize={'12px'} fontWeight={'400'}>
                                                        {heroTitle[status]?.subtitle}
                                                    </Typography>
                                                </Stack>
                                            ) as any
                                        }
                                        ChannelValueProps={{ value: detailWidget?.statusCount?.[status] }}
                                    />
                                )
                        )}
                    </HeroBanner>
                )}

                <Stack
                    sx={{
                        height: `calc(100% - ${
                            detailWidget?.preferences?.length && detailWidget?.showSeverityCount ? 245 : 98
                        }px)`,
                        overflow: 'auto',
                    }}
                >
                    {detailWidget?.secondary?.length ? (
                        detailWidget?.secondary
                            ?.slice(0, detailWidget?.maxCount ?? detailWidget?.secondary?.length)
                            .map((item: any) => (
                                <List disablePadding key={item?.id}>
                                    <InfoListItem
                                        title={item?.name ?? ''}
                                        icon={
                                            <Avatar
                                                sx={{ bgcolor: item?.icons?.status?.icon?.web?.style?.backgroundColor }}
                                            >
                                                <CustomIcon
                                                    iconName={item?.icons?.status?.icon?.web?.name ?? ''}
                                                    family={item?.icons?.status?.icon?.web?.family ?? ''}
                                                    iconProps={{
                                                        sx: {
                                                            color: item?.icons?.status?.icon?.web?.style?.color,
                                                        },
                                                    }}
                                                    defaultIcon={<HelpCenter color="primary" />}
                                                />
                                            </Avatar>
                                        }
                                        rightComponent={getRightComponent(item)}
                                        statusColor={item?.icons?.status?.icon?.web?.style?.backgroundColor}
                                        {...((detailWidget?.multiDevice || dropType === 'Group') && {
                                            subtitle: [
                                                <GroupPath
                                                    key={item?.id}
                                                    pathName={[...(item?.groupPath ?? [])]}
                                                    deviceName={item?.deviceName}
                                                />,
                                            ],
                                        })}
                                        leftComponent={
                                            <Stack>
                                                {item?.alarmUpdated ? (
                                                    <>
                                                        <Typography variant="body2" fontSize={'12px'}>
                                                            {convertDateTime({
                                                                timestamp: parseInt(item?.alarmUpdated),
                                                                customFormat: 'HH:mm',
                                                            })}{' '}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {convertDateTime({
                                                                timestamp: parseInt(item?.alarmUpdated),
                                                            })}
                                                        </Typography>
                                                    </>
                                                ) : (
                                                    <>--</>
                                                )}
                                            </Stack>
                                        }
                                        divider="full"
                                        chevron
                                        onClick={(): void => {
                                            if (detailWidget?.detailRoute && mode === 'view')
                                                navigate(`${detailWidget?.detailRoute}/${item.id}`);
                                        }}
                                    />
                                </List>
                            ))
                    ) : (
                        <CustomEmptyState
                            className="padding-y-96"
                            icon={<FormatListBulleted fontSize={'inherit'} />}
                            title={<CustomTransComponent translationKey={'TIMELINE:NO_TIMELINE'} />}
                        />
                    )}
                </Stack>
            </Stack>

            <WidgetFooter
                mode={mode}
                ids={[]}
                actionBtnName={<CustomTransComponent translationKey={'COMMON:VIEW_ALL_EVENTS'} />}
                widgetData={{ ...detailWidget, viewAllButton: true }}
                disabled={!detailWidget?.secondary?.length}
            />
        </Paper>
    );
};

export default Component;
