import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initialState = {
    cloneProfileModal: { payload: {} },
    renameProfileModal: { payload: {} },
    importProfileModal: { payload: {} },
    applyProfileModal: { payload: {} },
    payload: {},
};

const channelManagerSlice = createSlice({
    name: 'channelManager',
    initialState: initialState,
    reducers: {
        setImportProfilePayload: (state: RootState, action: PayloadAction<any>) => {
            state['importProfileModal']['payload'][action.payload.key] = action.payload.value;
        },
        resetImportProfile: (state: RootState) => {
            state['importProfileModal'] = { payload: {} };
        },
        resetCloneProfileModal: (state: RootState) => {
            state['cloneChannelModal'] = {
                payload: {},
            };
        },
        resetRenameProfileModal: (state: RootState) => {
            state['renameProfileModal'] = { payload: {} };
        },
        setCloneProfilePayload: (state: RootState, action: PayloadAction<any>) => {
            state['cloneProfileModal']['payload'][action.payload.key] = action.payload.value;
        },
        setRenameProfilePayload: (state: RootState, action: PayloadAction<any>) => {
            state['renameProfileModal']['payload'][action.payload.key] = action.payload.value;
        },
        setApplyProfilePayload: (state: RootState, action: PayloadAction<any>) => {
            state['applyProfileModal']['payload'][action.payload.key] = action.payload.value;
        },
        resetApplyProfileModal: (state: RootState) => {
            state['applyProfileModal'] = { payload: {} };
        },
        setPayload: (state: RootState, action: PayloadAction<any>) => {
            state.payload = { ...state.payload, [action?.payload?.key]: action?.payload?.value };
        },
        resetProfile: () => {
            return initialState;
        },
    },
});

export const {
    setPayload,
    resetProfile,
    setImportProfilePayload,
    resetImportProfile,
    resetRenameProfileModal,
    setRenameProfilePayload,
    setCloneProfilePayload,
    resetCloneProfileModal,
    setApplyProfilePayload,
    resetApplyProfileModal,
} = channelManagerSlice.actions;

export const cloneProfileModalData = (state: RootState): any => state.channelManager.cloneProfileModal;
export const renameProfileModalData = (state: RootState): any => state.channelManager.renameProfileModal;
export const importProfileModalData = (state: RootState): any => state.channelManager.importProfileModal;
export const applyProfileModalData = (state: RootState): any => state.channelManager.applyProfileModal;

export default channelManagerSlice;
