import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { selectLoadModal, setLoadModal } from '@fiji/common/src/features/Loads/loadSlice';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { widgetList } from '@fiji/common/src/features/dashboardManagement/commonDashboardSlice';
import { useUpdateStatusOfLoadMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useGetWidgetDetails } from '@fiji/common/src/hooks';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import { Button, CircularProgress } from '@mui/material';
import { CustomModal, CustomTransComponent } from 'components';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

import React from 'react';
import { useParams } from 'react-router-dom';

export const LoadModal = (): JSX.Element => {
    const modalState = useTypedSelector(selectLoadModal);

    const { deviceId } = useParams();
    const [updateStatus, { isLoading, isSuccess, isError }] = useUpdateStatusOfLoadMutation();
    const widgets = useTypedSelector(widgetList);

    const { payload: widgetPayload, response } = useGetWidgetDetails({
        selectorType: 'commonDashboard',
        dataGetter: { deviceId: modalState?.details?.deviceId },
    });

    const [snackId, setSnackId] = React.useState(0);
    const [loadsData, setLoadsData] = React.useState(undefined);

    const selectedWidgetConfig = JSON.parse(
        JSON.stringify(widgets?.find((item: any) => item?.id === modalState?.widgetId) ?? {})
    );
    const loadsCachedData = useTypedSelector(
        (state) =>
            state.api.queries[
                `getMultiDeviceLoads(${JSON.stringify(widgetPayload?.['loads'](selectedWidgetConfig?.config))})`
            ]
    );

    const action = (snackbarKey: any): JSX.Element => (
        <Button
            variant="text"
            sx={{ color: '#80BDE0' }}
            name={snackbarKey}
            onClick={() => {
                closeSnackbar(+snackbarKey);
            }}
        >
            <CustomTransComponent translationKey={'blui:ACTIONS.OKAY'} />
        </Button>
    );

    const snackbarProps: any = {
        variant: 'notificationWithIcon',
        subtitle:
            modalState?.command === 'TURN_OFF' ? (
                <CustomTransComponent translationKey={'LOADS:LOAD_IS_TURNING_OFF'} />
            ) : (
                <CustomTransComponent translationKey={'LOADS:LOAD_IS_TURNING_ON'} />
            ),
        autoHideDuration: 60000,
        Icon: <CircularProgress style={{ color: '#fff' }} />,
        notificationWithAction: true,
        notificationAction: action,
        setId: setSnackId,
    };

    React.useEffect(() => {
        if (isSuccess) {
            setLoadsData(loadsCachedData);
            closeSnackbar(snackId);
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message:
                        modalState?.command === 'TURN_ON' ? (
                            <CustomTransComponent translationKey={'LOADS:LOAD_TURN_ON_COMPLETED'} />
                        ) : (
                            <CustomTransComponent translationKey={'LOADS:LOAD_TURN_OFF_COMPLETED'} />
                        ),
                })
            );
        }
    }, [isSuccess]);

    React.useEffect(() => {
        if (isError) closeSnackbar(snackId);
    }, [isError]);

    React.useEffect(() => {
        if (isLoading) {
            enqueueSnackbar(
                modalState?.command === 'TURN_OFF' ? (
                    <CustomTransComponent translationKey={'LOADS:TURNING_OFF_LOAD'} />
                ) : (
                    <CustomTransComponent translationKey={'LOADS:TURNING_ON_LOAD'} />
                ),
                snackbarProps
            );
        }
    }, [isLoading]);
    React.useEffect(() => {
        if (loadsData && JSON.stringify(loadsData) !== JSON.stringify(loadsCachedData)) {
            response['loads']?.([loadsCachedData], modalState?.widgetId);
            dispatch(
                setLoadModal({
                    isOpen: false,
                    command: '',
                    details: {},
                })
            );
            setLoadModalActions(initialState);
            setLoadsData(undefined);
        }
    }, [loadsData, loadsCachedData]);

    const clickHandler = async (): Promise<void> => {
        await updateStatus({
            id: deviceId,
            loadId: modalState?.details?.loadId,
            command: 'TURN_OFF_LOAD',
        });
        if (!modalState?.widgetId) {
            dispatch(
                setLoadModal({
                    isOpen: false,
                    command: '',
                    details: {},
                })
            );
            setLoadModalActions(initialState);
        }
    };
    const initialState: any = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',

            handleClick: (): void => {
                dispatch(
                    setLoadModal({
                        isOpen: false,
                        command: '',
                        details: {},
                    })
                );
                setLoadModalActions(initialState);
            },
        },

        {
            key: 'Turn Off',
            label: isLoading ? 'Turning off...' : <CustomTransComponent translationKey={'LOADS:TURN_OFF'} />,
            stackedKey: 'stacked',
            isLoading: isLoading,
            icon: <PowerSettingsNew />,
            className: 'contained-danger-rounded',
            color: 'error',
            variant: 'contained',
            handleClick: () => clickHandler() as any,
        },
    ];
    const dispatch = useAppDispatch();
    const [loadModalActions, setLoadModalActions] = React.useState(initialState);
    React.useEffect(() => {
        if (modalState?.command === 'TURN_ON') {
            const newModalActions = [...loadModalActions].filter((item) => !item?.stackedKey);
            newModalActions.push({
                key: 'Turn on',
                label: isLoading ? 'Turning On...' : <CustomTransComponent translationKey={'LOADS:TURN_ON'} />,
                stackedKey: 'stacked',
                icon: <PowerSettingsNew />,
                className: 'contained-success-rounded',
                isLoading: isLoading,
                variant: 'contained',
                handleClick: async (): Promise<void> => {
                    await updateStatus({
                        id: deviceId,
                        loadId: modalState?.details?.loadId,
                        command: 'TURN_ON_LOAD',
                    });

                    if (!modalState?.widgetId) {
                        dispatch(
                            setLoadModal({
                                isOpen: false,
                                command: '',
                                details: {},
                            })
                        );
                        setLoadModalActions(initialState);
                    }
                },
            });

            setLoadModalActions(newModalActions);
        } else {
            setLoadModalActions(initialState);
        }
    }, [modalState]);

    const modalId = React.useId();
    const modalRef = React.useRef<any>(null);
    return (
        <CustomModal
            key={modalId}
            ref={modalRef}
            isOpen={modalState?.isOpen}
            footerClass="space-between-footer"
            isLoading={false}
            isStacked
            type="primary"
            actions={loadModalActions}
            header={
                modalState?.command === 'TURN_OFF' ? (
                    <CustomTransComponent translationKey={'LOADS:LOAD_OFF'} />
                ) : (
                    <CustomTransComponent translationKey={'LOADS:LOAD_ON'} />
                )
            }
            subHeader={`${(<CustomTransComponent translationKey={'LOADS:WOULD_YOU_LIKE_TO'} />) as any} ${
                modalState?.command === 'TURN_OFF'
                    ? ((<CustomTransComponent translationKey={'LOADS:TURN_OFF'} />) as any)
                    : ((<CustomTransComponent translationKey={'LOADS:TURN_ON'} />) as any)
            } ${modalState?.details?.loadName ?? ''} ?`}
            noDividers
            actionsDivider
        />
    );
};
