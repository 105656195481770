import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { CustomTransComponent, Hierarchy, renderGroupHierarchyIcons } from '../../components';
import {
    useCreateGroupMutation,
    useDeleteGroupMutation,
    useGetAllGroupsMutation,
    useMoveExistingGroupsAndDevicesMutation,
} from '@fiji/common/src/features/group/groupApi';
import { useTheme } from '@mui/material/styles';
import { EmptyState } from '@brightlayer-ui/react-components';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { Box, Button, Stack, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';
import NotificationsActive from '@mui/icons-material/NotificationsActive';
import NotificationsActiveOutlined from '@mui/icons-material/NotificationsActiveOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
    addGroupModal,
    selectRefetchGroupHierarchy,
    setRefetchGroupHierarchy,
} from '@fiji/common/src/features/group/groupSlice';
import { GroupHierarchySkeleton } from './GroupHierarchySkeleton';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from '../../hooks';
import * as Colors from '@brightlayer-ui/colors';
import { useSwitchOrgMutation } from '@fiji/common/src/features/orgManagement/orgApi';
import { useDeleteDeviceMutation } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { DEFAULT_HIERARCHY_SIZE } from '@fiji/common';
import { Device } from '@brightlayer-ui/icons-mui';
import { setAppHeaderTitle, setDrawerHeaderTranslationKey } from '@fiji/common/src/features/common/commonSlice';
import { useTranslation } from 'react-i18next';

export const GroupHierarchy = ({ handleHideDrawer }: { handleHideDrawer: any }): JSX.Element => {
    const theme: any = useTheme();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selectedOrg = useTypedSelector((rootstate) => rootstate.org.selectedOrg);
    const currentRealmName = useTypedSelector((rootstate) => rootstate.common.selectedRealm);
    const hierarchyRefresh = useTypedSelector(selectRefetchGroupHierarchy);
    const [getFirstLevelGroup, { data }]: any = useGetAllGroupsMutation();
    const [getGroupChildren]: any = useGetAllGroupsMutation();
    const { t } = useTranslation();

    const [, { isSuccess: createSuccess }] = useCreateGroupMutation({
        fixedCacheKey: 'createGroup',
    });
    const [, { isSuccess: deletionSuccess }] = useDeleteGroupMutation({
        fixedCacheKey: 'deleteGroup',
    });
    const [, { isSuccess: deviceDeletionSuccess }] = useDeleteDeviceMutation({
        fixedCacheKey: 'deleteDevice',
    });
    const [, { isSuccess: moveSuccess }] = useMoveExistingGroupsAndDevicesMutation({
        fixedCacheKey: 'moveData',
    });
    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);
    const canCreateGroup = hasPermission('create-groups');
    const canCreateDevice = hasPermission('create-devices');
    const selectedNode = useTypedSelector((rootstate: RootState) => rootstate?.common?.selectedNode);
    const [, { isSuccess: switchOrgSuccess }] = useSwitchOrgMutation({ fixedCacheKey: 'switchOrg' });
    const [groupsData, setGroupsData] = useState<any>();
    const [hierarchyMappingData, setHierarchyMappingData] = useState<any>({});
    const [loadingHierarchyNode, setLoadingHierarchyNode] = useState([]);
    const [controlledExpandedNodes, setControlledExpandedNodes] = useState<string[]>([]);

    const resetHandler = (): void => {
        getFirstLevelGroup({ body: { page: 0, size: DEFAULT_HIERARCHY_SIZE } });
        setGroupsData(null);
        setHierarchyMappingData({});
        setControlledExpandedNodes([]);
    };

    React.useEffect(() => {
        if (hierarchyRefresh?.refetch) {
            dispatch(setRefetchGroupHierarchy({}));
            resetHandler();
        }
    }, [hierarchyRefresh?.refetch]);

    React.useEffect(() => {
        if (createSuccess || deletionSuccess || moveSuccess || deviceDeletionSuccess) {
            resetHandler();
        }
    }, [createSuccess, deletionSuccess, moveSuccess, deviceDeletionSuccess]);

    React.useEffect(() => {
        if (!groupsData?.length) {
            setGroupsData(data?.data);
        }
    }, [data]);

    React.useEffect(() => {
        if (selectedOrg?.id) {
            getFirstLevelGroup({ body: { page: 0, size: DEFAULT_HIERARCHY_SIZE } });
        }
        if (switchOrgSuccess) {
            setGroupsData(null);
        }
    }, [selectedOrg?.id, switchOrgSuccess]);

    React.useEffect(() => {
        if (!groupsData?.records?.length && !canCreateGroup) {
            dispatch(setAppHeaderTitle(t('COMMON:ALL_DEVICES')));
            dispatch(setDrawerHeaderTranslationKey('COMMON:ALL_DEVICES'));
        } else {
            dispatch(setAppHeaderTitle(selectedNode?.name));
            dispatch(setDrawerHeaderTranslationKey('COMMON:ALL_GROUPS'));
        }

        return () => {
            dispatch(setAppHeaderTitle(undefined));
        };
    }, [selectedNode, canCreateGroup, groupsData]);

    const handleFetchHierarchy = async (currentNode: any, paginationPayload?: any): Promise<void> => {
        setLoadingHierarchyNode((prevState): any => [
            ...prevState,
            paginationPayload?.page ? `more-button-${currentNode?.id}` : currentNode?.id,
        ]);
        const { data: childHierarchyData }: any = await getGroupChildren({
            parent: currentNode?.id,
            body: paginationPayload,
        });
        if (childHierarchyData?.success) {
            if (currentNode?.id === undefined) {
                setGroupsData((prev: any) => ({
                    records: [
                        ...(paginationPayload?.page ? prev?.records ?? [] : []),
                        ...(childHierarchyData?.data?.records ?? []),
                    ],
                    total: childHierarchyData?.data?.total,
                }));
            } else {
                setHierarchyMappingData((prev: any) => ({
                    ...prev,
                    [currentNode?.id]: {
                        records: [
                            ...(paginationPayload?.page ? prev?.[currentNode?.id]?.records ?? [] : []),
                            ...(childHierarchyData?.data?.records ?? []),
                        ],
                        total: childHierarchyData?.data?.total,
                    },
                }));
            }
        }
        setLoadingHierarchyNode((prevState): any =>
            prevState.filter(
                (loadingNode) =>
                    loadingNode !== (paginationPayload?.page ? `more-button-${currentNode?.id}` : currentNode?.id)
            )
        );
    };

    const handleCleanHierarchyNode = (parentId: string): void => {
        setHierarchyMappingData((prevState: any) => {
            const newHierarchyMappingData: any = { ...prevState };
            delete newHierarchyMappingData[parentId];
            return newHierarchyMappingData;
        });
    };

    const handleNodeClick = (selectedNodeData: any): void => {
        if (selectedNodeData?.type === 'GROUP') {
            navigate(`/${currentRealmName}/group/${selectedNodeData.id}`);
        } else {
            navigate(`/${currentRealmName}/device/${selectedNodeData.id}`);
        }
    };

    const handleOpenAddGroupModal = (group?: any): any => dispatch(addGroupModal({ isOpen: true, group }));

    const hasChildNode = (treeItem: any): boolean =>
        treeItem?.groupCount || treeItem?.deviceCount || hierarchyMappingData[treeItem?.id]?.length;

    const getActions = ({ treeItem }: any): JSX.Element => {
        if (treeItem?.alarmCount && treeItem?.type === 'GROUP') {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <NotificationsActiveOutlined sx={{ color: Colors.gray[500], marginRight: 1 }} />
                    <Typography sx={{ paddingRight: 1, fontWeight: '600', fontSize: 16 }}>
                        {treeItem?.alarmCount + (treeItem?.warningCount ?? 0)}
                    </Typography>
                </Box>
            );
        }
        if (treeItem?.alarmCount && (treeItem?.type === 'DEVICE' || treeItem?.type === 'GATEWAY')) {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <NotificationsActive sx={{ color: Colors.red[500], marginRight: 1 }} />
                    <Typography sx={{ paddingRight: 1, fontWeight: '600', fontSize: 16, color: Colors.red[500] }}>
                        {treeItem?.alarmCount + (treeItem?.warningCount ?? 0)}
                    </Typography>
                </Box>
            );
        }
        return <></>;
    };

    const getEmptyStateButton = (): JSX.Element => {
        if (canCreateGroup) {
            return (
                <Button
                    id="add-group"
                    sx={{
                        border: `1px solid ${theme?.palette?.primary?.main}`,
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.[50],
                        },
                    }}
                    variant={'outlined'}
                    color={'primary'}
                    startIcon={<Add />}
                    onClick={(): void => handleOpenAddGroupModal()}
                >
                    <CustomTransComponent translationKey={'GROUP_MANAGEMENT:BTN.ADD_A_GROUP'} />
                </Button>
            );
        } else if (canCreateDevice) {
            return (
                <Button
                    id="add-group"
                    sx={{
                        border: `1px solid ${theme?.palette?.primary?.main}`,
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.[50],
                        },
                    }}
                    variant={'contained'}
                    color={'primary'}
                    startIcon={<Add />}
                    onClick={(): void => navigate(`/${currentRealmName}/add-device`)}
                >
                    <CustomTransComponent translationKey={'COMMON:Add Device'} />
                </Button>
            );
        }
        return <></>;
    };

    return (
        <Hierarchy
            labelKey={'name'}
            data={groupsData}
            loadMore={handleFetchHierarchy}
            cleanHiearchyNode={handleCleanHierarchyNode}
            hierarchyMappingData={hierarchyMappingData}
            loadingNode={loadingHierarchyNode}
            labelColor={'error'}
            handleTreeNodeSelection={handleNodeClick}
            controlledExpandedNodes={controlledExpandedNodes}
            setControlledExpandedNodes={setControlledExpandedNodes}
            selectionType={'node'} // radio, checkbox, node
            selectedNodes={selectedNode?.id}
            isLoading={!groupsData}
            noDataFoundComponent={
                <Stack className="center w-100">
                    <EmptyState
                        icon={canCreateGroup ? <WorkspacesIcon fontSize={'inherit'} /> : <Device fontSize="inherit" />}
                        title={canCreateGroup ? t('GROUP_MANAGEMENT:LABELS.NO_GROUPS') : t('COMMON:NO_DEVICES')}
                        {...(canCreateGroup && {
                            description: t('COMMON:SELECT_GROUP_NO_DESC'),
                        })}
                        actions={
                            <Stack spacing={2}>
                                {getEmptyStateButton()}
                                <Button
                                    id="hide-panel"
                                    sx={{
                                        border: `1px solid ${theme?.palette?.primary?.main}`,
                                        '&:hover': {
                                            backgroundColor: theme?.palette?.primary?.[50],
                                        },
                                    }}
                                    variant={'outlined'}
                                    color={'primary'}
                                    startIcon={<VisibilityOffIcon />}
                                    onClick={handleHideDrawer}
                                >
                                    <CustomTransComponent translationKey={'GROUP_HIERARCHY:HIDE_THIS_PANEL'} />
                                </Button>
                            </Stack>
                        }
                    />
                </Stack>
            }
            loadingSkeleton={<GroupHierarchySkeleton row={1} />}
            hasChild={hasChildNode}
            getAvatarChild={renderGroupHierarchyIcons}
            pagination={{ records: 'records', total: 'total', size: DEFAULT_HIERARCHY_SIZE }}
            actions={getActions}
        />
    );
};
