import React from 'react';

import { reducer } from '../helpers';
import { isNullOrUndefined } from '@fiji/common/src/utils/helpers';
import { useGetChannelDetailsWithCategoryQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';

type ReturnProps = [
    any,
    {
        updateDetails: (args0: any, args1: any) => void;
        isLoading: boolean;
    }
];

export const useCustomDetailsApi = (deviceId: any, debouncedValue?: any): ReturnProps => {
    const { data, isLoading, isFetching } = useGetChannelDetailsWithCategoryQuery(
        {
            deviceId: deviceId,
            params: {
                channelType: 'DETAIL',
                ...(debouncedValue && { searchKey: 'displayName', searchValue: debouncedValue }),
            },
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    React.useEffect(() => {
        if (data?.data && isNullOrUndefined(state)) {
            dispatch({ type: 'SET_DATA', payload: data });
        }
    }, [data]);

    const updateChannelValue = (response: any, responseTopic: any): any => {
        const splitedArr: any = responseTopic.split('/');
        const requiredTopic = splitedArr[0]?.concat(`/${splitedArr[1]}`);
        if (requiredTopic === 'BSSRM/TREND') {
            dispatch({ type: 'UPDATE_DATA', payload: response });
        }
    };

    const [state, dispatch] = React.useReducer(reducer, undefined);
    return [state, { isLoading: isLoading || isFetching, updateDetails: updateChannelValue }];
};
