import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { setAuthModal, setMessageContent } from '../../features/common/commonSlice';
import { setIsCurrentRealmSuspended } from '../../features/realmManagement/realmSlice';

// Create our baseQuery instance
const baseQuery = (apiUrl: string): any =>
    fetchBaseQuery({
        baseUrl: apiUrl,
        // credentials: 'include',
        prepareHeaders: (headers: any, { getState }) => {
            const token = (getState() as RootState)?.auth?.token;
            const realm = (getState() as RootState)?.common?.xRealm;
            const selectedOrg = (getState() as RootState)?.org?.selectedOrg;
            const urlSuffix = (getState() as RootState)?.apiSlice?.urlSuffix;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            if (realm && !headers.get('x-realm')) {
                headers.set('x-realm', urlSuffix ? realm.replace(`${urlSuffix}_`, '') : realm);
            }

            if (selectedOrg?.id?.length && !headers.get('organizationId')) {
                headers.set('organizationId', selectedOrg?.id);
            }

            return headers;
        },
    });

const baseQueryWithInterceptor: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args: any,
    api: any,
    extraOptions: any
) => {
    const { getState } = api;
    const result: any = await baseQuery(getState()?.apiSlice?.apiUrl)(args, api, extraOptions);
    switch (result?.error?.status) {
        case 401:
            api.dispatch(setAuthModal(true));
            break;
        case 500:
        case 403:
        case 400:
            if (!args.url.includes('signup') && !extraOptions) {
                api.dispatch(
                    setMessageContent({
                        isOpen: true,
                        message: result?.error?.data.errorMessage ?? result?.error?.data.message,
                        duration: 3000,
                    })
                );
            }
            break;
        case 423:
            api.dispatch(setIsCurrentRealmSuspended(true));
            break;
        case 'FETCH_ERROR':
            api.dispatch(
                setMessageContent({
                    isOpen: true,
                    message: 'Can`t connect to network from app.',
                    duration: 3000,
                })
            );
            break;
        default:
            break;
    }

    return result;
};
//
/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
    reducerPath: 'api',
    tagTypes: [
        'Realms',
        'RealmById',
        'Orgs',
        'Dashboards',
        'device-settings',
        'Schedules',
        'Profile',
        'ActiveDashboards',
        'Users',
        'Notifications',
        'DashboardById',
        'GroupsHierarchy',
        'DeviceList',
        'Timeline',
        'GroupById',
        'AvailableCommands',
        'WidgetById',
        'UserById',
        'DeviceTags',
        'RealmChildren',
        'RealmSettings',
        'Roles',
        'Widgets',
        'RoleById',
        'DEVICEMODELS',
        'MultiLoads',
        'UsersByRoleId',
        'CustomNotifications',
        'Notifications',
        'Automations',
        'PlanDetails',
        'allPlans',
        'subscriptionDetails',
        'allSubscriptions',
        'Threshold',
        'AllLoads',
        'LoadDetails',
        'Properties',
        'TimelineById',
        'RealmTimelineTags',
        'SnoozeByDeviceId',
        'Virtual_Loads',
        'DeviceById',
        'UpcomingById',
        'VirtualLoadDetails',
        'TimelineComment',
        'orgSubscription',
        'orgDetails',
        'PaginatedRealms',
        'PaginatedOrgs',
        'ServiceContact',
        'UserEula',
        'CommentBySerialNumber',
        'userRoles',
        'ChannelByDeviceId',
        'ChannelLibrary',
        'OpenRealmSettings',
    ],
    /**
     * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
     */
    baseQuery: baseQueryWithInterceptor,
    /**
     * This api has endpoints injected in adjacent files,
     * which is why no endpoints are shown below.
     * If you want all endpoints defined in the same file, they could be included here instead
     */
    endpoints: () => ({}),
});
