import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as Colors from '@brightlayer-ui/colors';
import { Theme, useTheme } from '@mui/material/styles';
import DOMPurify from 'dompurify';
import { useGetEulaQuery } from '@fiji/common/src/features/userManagement/userApi';
import { getRealmNameFromSuffix } from 'utils/helpers';
import { RootState, useTypedSelector } from '@fiji/common';
import { useTranslation } from 'react-i18next';
import { selectLocale } from '@fiji/common/src/features/localization/localizaitonSlice';
import { DEFAULT_LANGUAGE } from '@fiji/common/src/constants/cacheTimeout';
interface EulaAndPrivacyPolicyContent {
    eulaAPIContent: string;
    fetchedPrivacyPolicyContent: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    muiDialogpaper: {
        padding: '24px 16px',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 56px)',
            width: '90%', // Adjust width for small screens
        },
        [theme.breakpoints.up('md')]: {
            width: '400px', // Adjust width for medium screens
        },
        [theme.breakpoints.up('lg')]: {
            width: '500px', // Adjust width for large screens
        },
        '& .MuiPaper-root': {
            maxWidth: '100%', // Ensure it doesn't exceed the screen width
            height: '100%',
            maxHeight: '760px',
            overflow: 'hidden',
            overflowY: 'auto',
        },
    },
    typographyTitle: {
        marginBottom: theme.spacing(2),
        fontSize: '20px',
        color: Colors.black[800],
    },
    typographyHeader: {
        fontSize: '28px',
        fontWeight: '600',
        color: '#2B353A',
    },
    typographySubHeader: {
        textTransform: 'capitalize',
        fontSize: '16px',
        fontWeight: '700',
        color: '#424E54',
        paddingBottom: '24px',
    },
    typographyPara: {
        fontSize: '16px',
        fontWeight: '400',
        color: '#424E54',
        paddingBottom: '16px',
    },
    typographyParaHeader: {
        fontSize: '16px',
        fontWeight: '600',
        color: '#424E54',
        paddingBottom: '16px',
        textDecoration: 'underline',
    },
}));

const isValidUrl = (url: string): URL | null => {
    try {
        const parsedUrl = new URL(url);
        if (parsedUrl.protocol === 'https:' || parsedUrl.protocol === 'http:') {
            return parsedUrl;
        }
        throw new Error('Invalid protocol');
    } catch (error) {
        console.error('Invalid URL:', url);
        return null;
    }
};

export const PrivacyPolicy = React.forwardRef((props, ref): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [privacyPolicyContent, setPrivacyPolicyContent] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const currentRealmName = useTypedSelector((state: RootState) => state.common.selectedRealm);
    const { t } = useTranslation();
    const realmPrefix = getRealmNameFromSuffix(currentRealmName);
    const locale = useTypedSelector(selectLocale);
    const { data: eulaProfileContent } = useGetEulaQuery({
        realmPrefix,
        locale: locale ?? window.localStorage.getItem('globalLocale') ?? DEFAULT_LANGUAGE,
    });
    const theme = useTheme();
    const classes = useStyles(theme);

    const modalHandler = (action: boolean): void => {
        setIsOpen(action);
    };

    React.useImperativeHandle(ref, () => ({
        modalHandler,
    }));

    const getEulaAndPrivacyPolicyContent = async (
        eulaUrl: string,
        privacyPolicyUrl: string
    ): Promise<EulaAndPrivacyPolicyContent> => {
        const validatedEulaUrl = isValidUrl(eulaUrl);
        const validatedPrivacyPolicyUrl = isValidUrl(privacyPolicyUrl);

        if (!validatedEulaUrl || !validatedPrivacyPolicyUrl) {
            throw new Error('One or both URLs are invalid');
        }

        try {
            const [eulaResponse, privacyPolicyResponse] = await Promise.all([
                fetch(validatedEulaUrl.toString()),
                fetch(validatedPrivacyPolicyUrl.toString()),
            ]);

            if (!eulaResponse.ok || !privacyPolicyResponse.ok) {
                throw new Error('Failed to fetch one or both documents');
            }

            const eulaAPIContent = await eulaResponse.text();
            const fetchedPrivacyPolicyContent = await privacyPolicyResponse.text();

            if (!eulaAPIContent || !fetchedPrivacyPolicyContent) {
                throw new Error('EULA or Privacy Policy content is not available.');
            }

            return { eulaAPIContent, fetchedPrivacyPolicyContent };
        } catch (error) {
            console.error('Error fetching EULA and Privacy Policy:', error);
            throw new Error('Failed to load EULA and Privacy Policy content');
        }
    };

    useEffect(() => {
        if (eulaProfileContent?.data?.latestEulaPath && eulaProfileContent?.data?.latestPrivacyPolicyPath) {
            const fetchEulaData = async (): Promise<void> => {
                try {
                    setIsLoading(true);
                    const { fetchedPrivacyPolicyContent } = await getEulaAndPrivacyPolicyContent(
                        eulaProfileContent?.data?.latestEulaPath,
                        eulaProfileContent?.data?.latestPrivacyPolicyPath
                    );
                    setPrivacyPolicyContent(DOMPurify.sanitize(fetchedPrivacyPolicyContent));
                } catch (error) {
                    console.error('Error fetching EULA or Privacy Policy content:', error);
                } finally {
                    setIsLoading(false);
                }
            };

            void fetchEulaData();
        }
    }, [eulaProfileContent?.data?.latestEulaPath, eulaProfileContent?.data?.latestPrivacyPolicyPath]);

    return (
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.muiDialogpaper }}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="body1" className={classes.typographyHeader}>
                    {t('blui:MESSAGES.PRIVACY')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {isLoading ? (
                    <Typography variant="body2">Loading...</Typography>
                ) : (
                    <div
                        className={classes.typographyPara}
                        dangerouslySetInnerHTML={{
                            __html: privacyPolicyContent,
                        }}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        backgroundColor: theme?.palette?.primary?.main,
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.main,
                        },
                    }}
                    variant="contained"
                    onClick={(): void => setIsOpen(false)}
                >
                    {t('COMMON:CLOSE')}
                </Button>
            </DialogActions>
        </Dialog>
    );
});
