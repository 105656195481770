import { api } from '../../app/api/baseApi';
import { PAGINATION_PAYLOAD } from '../../constants/paginationQuery';
export const entitlementApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllPlans: builder.query<any, any>({
            query: ({ pagePayload, realmPrefix }) => ({
                url: 'es/v1/plans/filter',
                method: 'POST',
                body: pagePayload,
                headers: realmPrefix && { 'x-realm': realmPrefix },
            }),
            providesTags: ['allPlans'],
        }),
        getBasePlan: builder.query<any, any>({
            query: () => ({
                url: 'es/v1/plans/filter',
                method: 'POST',
                body: {
                    page: 0,
                    size: 10,
                    filters: {
                        basePlan: true,
                    },
                },
            }),
            providesTags: ['allPlans'],
        }),
        createPlan: builder.mutation<object, object>({
            query: (body) => ({
                url: `es/v1/plans`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['allPlans'] : []),
        }),
        deletePlan: builder.mutation({
            query: (id) => ({
                url: `/es/v1/plans/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error): any => (!error ? ['allPlans'] : []),
        }),
        getPlanDetailsById: builder.query({
            query: (id) => ({
                url: `es/v1/plans/${id}`,
                method: 'GET',
            }),
            providesTags: ['PlanDetails'],
        }),
        updatePlan: builder.mutation({
            query: ({ id, body, realmPrefix }) => ({
                url: `es/v1/plans/${id}`,
                method: 'PATCH',
                body,
                headers: realmPrefix && { 'x-realm': realmPrefix },
            }),
            invalidatesTags: (result, error): any => (!error ? ['PlanDetails'] : []),
        }),
        getAssociatedRealms: builder.query({
            query: ({ id, body, realmPrefix }) => ({
                url: `es/v1/plans/${id}/filter`,
                method: 'POST',
                body,
                headers: realmPrefix && { 'x-realm': realmPrefix },
            }),
        }),
        getPaginatedPlans: builder.query({
            query: (params: any) => {
                const payloadClone = JSON.parse(JSON.stringify(params));
                if (payloadClone.pullRefresh) {
                    delete payloadClone.pullRefresh;
                }
                return {
                    url: 'es/v1/plans/filter',
                    method: 'POST',
                    body: payloadClone,
                };
            },
            ...PAGINATION_PAYLOAD,
        }),
    }),
});

export const {
    useGetAllPlansQuery,
    useDeletePlanMutation,
    useCreatePlanMutation,
    useGetPlanDetailsByIdQuery,
    useUpdatePlanMutation,
    useGetAssociatedRealmsQuery,
    useGetBasePlanQuery,
    useGetPaginatedPlansQuery,
} = entitlementApi;
