import Edit from '@mui/icons-material/Edit';
import { Box, Container, FormHelperText, IconButton, Paper, Stack, Switch, TextField, Typography } from '@mui/material';
import React from 'react';
import { CustomModal, ModalActions } from '../../components/CustomModal';
import { useParams } from 'react-router-dom';
import {
    useGetDetailsOfLoadByIdQuery,
    useGetDeviceByIdQuery,
    useUpdateLoadDetailsMutation,
} from '@fiji/common/src/features/deviceManagement/deviceApi';
import { useConfirm } from '@fiji/common/src/hooks';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setLoadModal } from '@fiji/common/src/features/Loads/loadSlice';
import { useTheme } from '@mui/material/styles';
import { CustomTransComponent } from 'components';
import {
    setAppHeaderSubTitle,
    setAppHeaderTitle,
    setMessageContent,
} from '@fiji/common/src/features/common/commonSlice';
import { useTranslation } from 'react-i18next';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from 'hooks';

const loadNumberStyle = {
    fontSize: '14px',
    fontWeight: '600',
    backgroundColor: '#727E84',
    color: '#fff',
    width: '18px',
    height: '18px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};
export const LoadDetails = (): JSX.Element => {
    const theme: any = useTheme();
    const { t } = useTranslation();
    const { deviceId, loadId } = useParams();
    const permissions = useTypedSelector(selectCurrentPermission);
    const { hasPermission } = useRBAC(permissions);
    const canUpdateLoads = hasPermission('edit-devices');
    const [editPayload, setEditPayload] = React.useState({
        loadName: '',
        description: '',
    });
    const dispatch = useAppDispatch();
    const {
        data: loadDetails,
        isLoading,
        isFetching,
    } = useGetDetailsOfLoadByIdQuery({
        id: deviceId,
        loadId,
    });
    const { data: deviceData } = useGetDeviceByIdQuery(deviceId);

    const disableSave = (): boolean => {
        if (
            (editPayload?.loadName && editPayload.loadName !== loadDetails?.data?.loadName) ||
            editPayload.description !== loadDetails?.data?.loadDescription
        ) {
            return false;
        }
        return true;
    };

    const [updateDetails, { isLoading: isUpdating }] = useUpdateLoadDetailsMutation();
    React.useEffect(() => {
        if (loadDetails) {
            setEditPayload({
                loadName: loadDetails?.data?.loadName,
                description: loadDetails?.data?.loadDescription,
            });
            dispatch(setAppHeaderTitle(loadDetails?.data?.loadName));
        }
        return () => {
            dispatch(setAppHeaderTitle(''));
        };
    }, [loadDetails]);

    React.useEffect(() => {
        if (deviceData) {
            dispatch(setAppHeaderSubTitle(deviceData?.data?.name));
        }
        return () => {
            dispatch(setAppHeaderSubTitle(''));
        };
    }, [deviceData]);

    const handleConfirmEdit = async (): Promise<void> => {
        const { error }: any = await updateDetails({
            id: deviceId,
            loadId,
            body: editPayload,
        });
        if (!error) {
            dispatch(setMessageContent({ isOpen: true, message: t('COMMON:CHANGES_SAVED_SUCCESS') }));
            onCancel();
        }
    };
    const { isVisible, onClick, onCancel, onConfirm } = useConfirm(handleConfirmEdit);
    const handleSwitch = (data: any): void => {
        if (data?.isLoadEnabled) {
            dispatch(
                setLoadModal({
                    isOpen: true,
                    command: 'TURN_OFF',
                    details: data,
                })
            );
            return;
        }
        dispatch(
            setLoadModal({
                isOpen: true,
                command: 'TURN_ON',
                details: data,
            })
        );
    };
    const editorModalActions: ModalActions[] = [
        {
            testId: 'cancel',
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                onCancel();
            },
        },
        {
            testId: 'save',
            key: 'save',
            label: <CustomTransComponent translationKey={'COMMON:SAVE_LABEL'} />,
            disabled: disableSave(),
            variant: 'contained',
            handleClick: (): void => {
                onConfirm();
            },
        },
    ];

    const modalId = React.useId();
    const modalRef = React.useRef<any>(null);

    return (
        <>
            <Container maxWidth="xl" className="padding-y-24">
                <Paper>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        px={3}
                        py={1}
                        className="border-bottom-1"
                    >
                        <Stack direction={'row'} alignItems={'center'} spacing={2}>
                            <Box sx={loadNumberStyle}>{parseInt(loadDetails?.data?.['loadNumber'] ?? 0) + 1}</Box>
                            <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                                {loadDetails?.data?.loadName || '-'}
                            </Typography>
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'}>
                            <IconButton
                                disabled={isLoading || isFetching || !canUpdateLoads}
                                onClick={(): void => onClick()}
                                id="edit"
                            >
                                <Edit />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        px={3}
                        py={1}
                        className="border-bottom-1"
                    >
                        <Typography variant="body2" fontSize={'14px'} fontWeight={'400'}>
                            <CustomTransComponent translationKey={'COMMON:STATE'} />
                        </Typography>
                        <Switch
                            disabled={
                                !loadDetails?.data?.isLoadEnabledInMap ||
                                loadDetails?.data?.offlineDevice ||
                                !canUpdateLoads
                            }
                            onClick={(): void => handleSwitch(loadDetails?.data)}
                            checked={!!loadDetails?.data?.isLoadEnabled}
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: theme?.palette?.primary?.main,
                                    '& + .MuiSwitch-track': {
                                        backgroundColor: theme?.palette?.primary?.main,
                                    },
                                },
                                '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
                                    color: theme?.palette?.primary?.[50],
                                    '& + .MuiSwitch-track': {
                                        backgroundColor: theme?.palette?.primary?.[200],
                                    },
                                },
                            }}
                        />
                    </Stack>
                    <Stack px={3} py={1}>
                        <Typography variant="body2" fontWeight={'400'} fontSize={'14px'}>
                            <CustomTransComponent translationKey={'COMMON:DESCRIPTION'} />
                        </Typography>
                        <Typography variant="body1" fontWeight={'600'} mt={3}>
                            {loadDetails?.data?.loadDescription || '-'}
                        </Typography>
                    </Stack>
                </Paper>
            </Container>

            {/* Edit Load Details Modal */}
            <CustomModal
                key={modalId}
                ref={modalRef}
                isOpen={isVisible}
                isLoading={isUpdating || isLoading || isFetching}
                type="primary"
                actions={editorModalActions}
                header={t('LOADS:LOAD_DETAILS')}
            >
                <Stack>
                    <TextField
                        id="load-name"
                        label={<CustomTransComponent translationKey={'LOADS:LOAD_NAME'} />}
                        onChange={(e): void => {
                            if (e.target.value.length > 24) {
                                return;
                            }
                            setEditPayload((prev) => ({ ...prev, loadName: e.target.value }));
                        }}
                        value={editPayload?.loadName}
                        variant="filled"
                        fullWidth
                        required
                    />
                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'end'} px={2}>
                        <FormHelperText>{editPayload?.loadName?.length ?? 0}/24</FormHelperText>
                    </Stack>

                    <TextField
                        id="description"
                        multiline
                        value={editPayload?.description}
                        onChange={(e: any): void => {
                            if (e.target.value.length > 256) {
                                return;
                            }
                            setEditPayload((prev: any) => ({ ...prev, description: e.target.value }));
                        }}
                        label={<CustomTransComponent translationKey={'COMMON:DESCRIPTION'} />}
                        variant="filled"
                        fullWidth
                        className="margin-top-16"
                    />
                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'end'} px={2}>
                        <FormHelperText>{editPayload?.description?.length ?? 0}/256</FormHelperText>
                    </Stack>
                </Stack>
            </CustomModal>
        </>
    );
};
