import React, { useEffect } from 'react';

import {
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridActionsCellItem,
    GridActionsCellItemProps,
    GridCellParams,
    GridColDef,
    GridRowParams,
} from '@mui/x-data-grid-pro';
import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material';
import CustomIcon from 'components/CustomIcon';
import HelpCenter from '@mui/icons-material/HelpCenter';
import { DevicesUpdateMenu } from './DevicesUpdateMenu';
import { useAppDispatch, useTypedSelector } from '@fiji/common';
import { moveGroupsDevicesModal } from '@fiji/common/src/features/group/groupSlice';
import AddBox from '@mui/icons-material/AddBox';
import ChevronRight from '@mui/icons-material/ChevronRight';
import NotificationsNone from '@mui/icons-material/NotificationsNone';

import { CustomTransComponent, GroupPath } from 'components';
import { useNavigate } from 'react-router-dom';
import { setAllVisibleColumns } from '@fiji/common/src/features/component/dataGridSlice';
import { ChannelValue } from '@brightlayer-ui/react-components';

const getStatusStyles = (device: any): any => {
    switch (device?.status?.indicator) {
        case '#F0AA1F':
            return 'indicator-bg-warning';
        case '#69B1C3':
            return 'indicator-bg-offline';
        case '#983fef':
            return 'indicator-bg-snooze';
        default:
            return 'indicator-bg-alarm';
    }
};

export const DeviceColumns = (props: any): GridColDef[] => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const selectedNode = useTypedSelector((state) => state.common.selectedNode);
    const [type, setType] = React.useState<string | undefined>(undefined);

    useEffect(() => {
        if (!selectedNode?.wrapperDeviceType && selectedNode) {
            setType('group');
        } else if (selectedNode && selectedNode?.wrapperDeviceType === 'Gateway') {
            setType('gateway');
        }
    }, [selectedNode]);

    const getDeviceDetails = ({ row: data }: any): JSX.Element => (
        <Stack>
            {' '}
            <Typography variant="body2" fontSize={'14px'} fontWeight={'600'} className="text-secondary">
                {data?.name ?? '--'}
            </Typography>
            <Typography variant="body2" fontSize={'12px'} fontWeight={'400'} className="color-gray-500">
                {`Model# ${data?.deviceModel ?? '--'}`}
            </Typography>
        </Stack>
    );

    const getTags = ({ row: data }: any): any => {
        if (data?.tags) {
            return data?.tags?.map((tag: any, index: number) => (
                <React.Fragment key={tag?.id}>
                    <span>{tag?.name}</span>
                    {index < data?.tags?.length - 1 && ', '}
                </React.Fragment>
            ));
        }
        return '--';
    };

    const getGroupPath = ({ row: cell }: any): JSX.Element => {
        if (cell?.groupPath) {
            return <GroupPath pathName={cell?.groupPath ?? []} />;
        }
        return <>--</>;
    };

    const getAlert = ({ row: cell }: any): JSX.Element => (
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <NotificationsNone className="color-gray-500 height-18 width-18" sx={{ color: '#727E84' }} />
            <Typography variant="body2" fontWeight={'600'} fontSize={'15px'} className="color-black-500">
                {cell?.count?.alarmCount ?? '0'}
            </Typography>
        </Stack>
    );

    const handleEditDevice = (id: string): void => {
        navigate(`/${currentRealmName}/edit-device/${id}`);
    };

    const columns: GridColDef[] = [
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            width: 100,
            cellClassName: ({ row }: GridCellParams<any, number>): string => {
                if (row?.status?.indicator?.length) {
                    return `indicator-class ${getStatusStyles(row)}`;
                }
                return '';
            },
        },

        {
            field: 'deviceStatus',
            headerName: (<CustomTransComponent translationKey={'COMMON:STATUS'} />) as any,
            sortable: true,
            width: 100,
            renderCell: ({ row: data }: any): JSX.Element => {
                if (data?.status?.icon?.web?.name && data?.status?.icon?.web?.family) {
                    const iconProps = {
                        sx: {
                            color: data?.status?.icon?.web?.style?.color,
                        },
                    };
                    return (
                        <Avatar sx={{ bgcolor: data?.status?.icon?.web?.style?.backgroundColor }}>
                            <CustomIcon
                                iconName={data?.status?.icon?.web?.name}
                                family={data?.status?.icon?.web?.family}
                                iconProps={iconProps}
                            />
                        </Avatar>
                    );
                }
                return <HelpCenter color="primary" />;
            },
        },
        {
            headerName: (<CustomTransComponent translationKey={'COMMON:DEVICE'} />) as any,
            field: 'deviceName',
            width: 200,
            hideable: false,
            renderCell: getDeviceDetails,
        },
        {
            headerName: (<CustomTransComponent translationKey={'COMMON:TYPE'} />) as any,
            width: 200,
            field: 'modelCategory',
        },
        {
            headerName: (<CustomTransComponent translationKey={'COMMON:GROUP'} />) as any,
            field: 'groupPath',
            width: 350,
            sortable: false,
            renderCell: getGroupPath,
        },
        {
            field: 'count',
            headerName: (<CustomTransComponent translationKey={'COMMON:ALERTS'} />) as any,
            width: 100,
            sortable: false,
            renderCell: getAlert,
        },
        {
            headerName: (<CustomTransComponent translationKey={'COMMON:TAGS'} />) as any,
            field: 'tags',
            width: 300,
            sortable: false,
            renderCell: getTags,
        },
        {
            headerName: (<CustomTransComponent translationKey={'COMMON:DETAILS'} />) as any,
            field: 'attributes',
            sortable: false,
            width: 300,
            renderCell: ({ row: data }: any): JSX.Element =>
                data?.configuredChannels?.length ? (
                    data?.configuredChannels?.map((attribute: any, index: number) => (
                        <Stack
                            key={attribute?.key}
                            flexDirection="row"
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <CustomIcon
                                iconName={attribute?.icon?.web?.name ?? ''}
                                family={attribute?.icon?.web?.family ?? ''}
                                iconProps={{ className: 'height-18 width-18', sx: { color: '#727E84' } }}
                            />
                            <ChannelValue mx={1} value={attribute?.value} unitSpace="hide" units={attribute?.unit} />
                            {index !== data?.configuredChannels?.length - 1 && (
                                <Box className="border-radius-50 bg-content height-4 width-4 margin-right-8" />
                            )}
                        </Stack>
                    ))
                ) : (
                    <></>
                ),
        },

        {
            headerName: '',
            field: 'custom',
            type: 'actions',
            sortable: false,
            renderCell: ({ row: data }: any): JSX.Element => (
                <IconButton data-test="device-action-btn">
                    <DevicesUpdateMenu
                        data={data}
                        deleteClick={props.handleDeleteConfirmModal}
                        moveClick={(): void => {
                            dispatch(
                                moveGroupsDevicesModal({
                                    isOpen: true,
                                    deviceOnly: true,
                                    deviceIds: [
                                        {
                                            id: data?.id,
                                            name: data?.name,
                                        },
                                    ],
                                })
                            );
                        }}
                        tagClick={() => props?.updateTags?.(true, { ...data, key: 'tags', isDeviceTagUpdate: true })}
                        editClick={handleEditDevice}
                    />
                </IconButton>
            ),
        },
        {
            field: 'actions',
            type: 'actions',
            renderHeader: (): any =>
                props.canCreateDevice && (
                    <IconButton
                        id="addDevice"
                        onClick={(): void => {
                            navigate(`/${currentRealmName}/add-device`, {
                                state: {
                                    ...(type === 'gateway'
                                        ? { deviceId: selectedNode.id }
                                        : { groupId: selectedNode.id }),
                                    type,
                                },
                            });
                        }}
                    >
                        <AddBox className="color-gray-500" />
                    </IconButton>
                ),
            getActions: ({ row: data }: GridRowParams): Array<React.ReactElement<GridActionsCellItemProps>> => [
                <GridActionsCellItem
                    key="user"
                    icon={<ChevronRight className="color-content" />}
                    label="edit"
                    onClick={(): void => {
                        navigate(`/${currentRealmName}/device/${data?.id}`);
                    }}
                />,
            ],
        },
    ];

    React.useEffect(() => {
        if (columns?.length) {
            const visibleColumns: any = {};
            columns?.forEach((item: any) => {
                visibleColumns[item.field] = true;
            });
            dispatch(setAllVisibleColumns(visibleColumns));
        }
    }, []);
    return columns;
};
