import { current } from '@reduxjs/toolkit';
import { isNullOrUndefined } from '../../utils/helpers';

function setDashboardWidgets(state: any, action: any): void {
    const payloadClone = current(state);
    const prevWidgetIndex = payloadClone?.widgetList?.findIndex(
        (widget: any) => widget?.id === action?.payload?.widgetId
    );
    if (prevWidgetIndex === -1) {
        state.widgetList.push(action.payload.data);
    } else {
        state.widgetList[prevWidgetIndex] = { ...action.payload.data };
    }
}

function apiResponseHelper(state: any, action: any): void {
    const stateClone = current(state);
    const responseIndex = stateClone?.apiResponse?.findIndex((res: any) => res.widgetId === action.payload?.widget?.id);
    if (responseIndex === -1) {
        state?.apiResponse?.push({
            widgetId: action?.payload?.widget?.id,
            type: action?.payload?.widget?.widgetType?.id,
            responses: action?.payload?.responses,
        });
    } else if (!isNullOrUndefined(responseIndex)) {
        delete state['loaders'][action?.payload?.widget?.id];
        state.apiResponse[responseIndex] = { ...state.apiResponse[responseIndex], responses: action.payload.responses };
    }
}

export { setDashboardWidgets, apiResponseHelper };
