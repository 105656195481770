import { useTypedSelector } from '@fiji/common';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useGetAllUsersQuery } from '@fiji/common/src/features/userManagement/userApi';
import { Autocomplete, Chip, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useDebounce } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const AutoCompleteInput = (props: any): JSX.Element => {
    const { t } = useTranslation();
    const currentOrg = useTypedSelector(selectedOrg);
    const [, debouncedValue] = useDebounce(undefined, undefined, props?.inputValue);
    const {
        data: users,
        isLoading,
        isFetching,
    } = useGetAllUsersQuery(
        {
            body: {
                page: 0,
                size: 5,
                filters: {
                    email: debouncedValue,
                },
            },
        },
        {
            skip: !currentOrg?.id,
            refetchOnMountOrArgChange: true,
        }
    );

    return (
        <Autocomplete
            multiple
            sx={{
                '& .MuiInputBase-root': {
                    backgroundColor: '#fff',
                },
            }}
            id="tagIds"
            value={props?.value}
            loading={isLoading || isFetching}
            loadingText={t('COMMON:LOADING')}
            onInputChange={props?.onInputChange}
            getOptionLabel={(option: any) => option?.email}
            options={
                users?.data?.records?.filter(
                    (user: any) => !props?.value?.find?.((val: any) => val?.email === user?.email)
                ) ?? []
            }
            renderOption={(optionProps, option) => (
                <MenuItem onClick={(e: any) => props?.onChange(e, option)}>
                    <Stack direction={'row'} spacing={1}>
                        <Typography fontWeight={'600'}>{option?.lastName},</Typography>
                        <Typography fontWeight={'600'}>{option?.firstName}</Typography>
                        <Typography>{`<${option?.email}>`}</Typography>
                    </Stack>
                </MenuItem>
            )}
            inputValue={props?.inputValue ?? ''}
            noOptionsText={
                !users?.data?.records?.length ||
                users?.data?.records?.every((item: any) =>
                    props?.value?.find?.((val: any) => val?.email === item?.email)
                )
                    ? t('COMMON:NO_OPTIONS')
                    : t('COMMON:LOADING')
            }
            renderTags={(value: any[], getTagProps): any =>
                value.map((option: any, index: number) => (
                    // eslint-disable-next-line react/jsx-key
                    <Chip
                        sx={{ marginRight: props?.hideCross ? 1 : 0 }}
                        {...(!props?.hideCross && getTagProps({ index }))}
                        label={
                            <Stack direction={'row'} spacing={1}>
                                <Typography fontWeight={'600'}>{option?.lastName ? option?.lastName : '-'},</Typography>
                                <Typography fontWeight={'600'}>
                                    {option?.firstName ? option?.firstName : '-'}
                                </Typography>
                                <Typography>{`<${option?.email ? option?.email : '-'}>`}</Typography>
                            </Stack>
                        }
                        {...(!props?.hideCross && {
                            onDelete: (): void =>
                                props?.handleChipDelete(typeof option === 'object' ? option?.id : option, index),
                        })}
                    />
                ))
            }
            renderInput={(params): JSX.Element => (
                <TextField
                    error={props?.error}
                    className={props?.className}
                    {...params}
                    variant="filled"
                    hiddenLabel={true}
                    {...(props?.handleInput && {
                        onChange: (e: any) => props?.handleInput(e.target.value),
                    })}
                    id="name"
                    {...(props?.value?.length !== (props?.maxValue ?? 6) && { placeholder: props.placeholder })}
                    // onKeyDown={props?.onKeyDown}
                    InputProps={{
                        ...params.InputProps,
                        maxLength: 16,
                    }}
                />
            )}
            {...(props?.value?.length === (props?.maxValue ?? 6) && { readOnly: true })}
        />
    );
};
