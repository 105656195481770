import React from 'react';

import { useConfirm } from '@fiji/common/src/hooks';
import moment from 'moment';
import { CustomModal, CustomTransComponent, Loader } from '../../../components';
import { FormHelperText, Grid, List, Stack, Switch, TextField, Typography } from '@mui/material';
import { ModalActions } from '../../../components/CustomModal';
import {
    useGetDashboardByIdQuery,
    useUpdateDashboardMutation,
} from '@fiji/common/src/features/dashboardManagement/dashboardApi';
import { InfoListItem } from '@brightlayer-ui/react-components';
import CustomIcon from '../../../components/CustomIcon';
import { useParams } from 'react-router-dom';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { useAppDispatch } from '@fiji/common/src/app/store';
import { useGetAllWidgetsQuery } from '@fiji/common/src/features/widgetManagement/widgetApi';
import { useTheme } from '@mui/material/styles';
import { resetLoaders, resetLocalDashboard } from '@fiji/common/src/features/dashboardManagement/dashboardSlice';
import { useTranslation } from 'react-i18next';

export const EditDashboardDetails = React.forwardRef((_props, ref): JSX.Element => {
    const { t } = useTranslation();
    const theme: any = useTheme();
    const { data: dashboardData, onClick, onCancel, isVisible, onConfirm } = useConfirm(submitHandler);
    const { dashboardId } = useParams();
    const {
        data: widgets,
        isLoading: widgetLoader,
        isFetching: widgetFetcher,
    }: any = useGetAllWidgetsQuery(
        {
            body: { page: 0, size: 20, filters: { dashboardId: dashboardId ?? dashboardData?.id } },
        },
        { skip: !(dashboardId || dashboardData?.id) }
    );
    const { currentData: dashboard, isFetching }: any = useGetDashboardByIdQuery(dashboardId ?? dashboardData?.id, {
        skip: !(dashboardId || dashboardData?.id),
    });
    const dispatch = useAppDispatch();
    const [updateDashboard, { isLoading }] = useUpdateDashboardMutation();
    const [payload, setPayload] = React.useState<any>({ name: '', description: '', dashboardId: dashboardId ?? '' });

    async function submitHandler(): Promise<void> {
        const bodyClone: any = {
            name: payload?.name,
            description: payload?.description,
            // assignType: payload?.assignType,
        };

        const { error }: any = await updateDashboard({
            body: bodyClone,
            params: {
                dashboardId: dashboardId ?? dashboardData?.id,
            },
        });
        if (!error) {
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: t('DASHBOARDS:UPDATE_DASHBOARD_DETAILS_MSG', {
                        name: payload?.name,
                    }),
                })
            );
            if (dashboardId) {
                dispatch(resetLocalDashboard());
                dispatch(resetLoaders());
            }
            onCancel();
        }
    }

    const detailsActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: onCancel,
        },
        {
            key: 'Save',
            label: <CustomTransComponent translationKey={'COMMON:SAVE_LABEL'} />,
            variant: 'contained',
            handleClick: onConfirm,
        },
    ];

    React.useEffect(() => {
        if (dashboard) {
            setPayload({
                name: dashboard?.data?.name,
                description: dashboard?.data?.description,
                sourceModal: Boolean(dashboard?.data?.assignType === 'SOURCE'),
                assignType: dashboard?.data?.assignType,
            });
        }
    }, [dashboard]);

    const handleModalAction = (action: any, data: any): void => {
        if (action) {
            onClick(data);
            return;
        }
        onCancel();
    };

    const handlePayloadChange = (e: any): void => {
        setPayload((prev: any) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    React.useImperativeHandle(
        ref,
        () => ({
            handleModalAction: (action: any, data: any) => handleModalAction(action, data),
        }),
        []
    );

    return (
        <CustomModal
            key="#detailsModal"
            isOpen={isVisible}
            actions={detailsActions}
            isLoading={isFetching || isLoading}
            type="secondary"
            header={<CustomTransComponent translationKey={'DASHBOARDS:EDIT_DASHBOARD.DASHBOARD_DETAILS_LABEL'} />}
        >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="subtitle2" fontWeight={'600'} fontSize={'14px'}>
                            <CustomTransComponent translationKey={'COMMON:MODIFIED'} />
                        </Typography>
                        <Stack direction={'row'}>
                            <Typography variant="body2" fontSize={'14px'}>
                                <span className="black-500 f-14 fw-700">
                                    {moment(dashboard?.data?.updatedAt ?? Date.now()).format('h:mm')}
                                </span>{' '}
                                {moment(dashboard?.data?.updatedAt ?? Date.now()).format('A')}
                            </Typography>
                            <Typography variant="body2" fontWeight={'400'} fontSize={'14px'} ml={1}>
                                {moment(dashboard?.data?.updatedAt ?? Date.now()).format('DD/MM/YYYY')}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={2}>
                        <Typography variant="subtitle2" fontWeight={'600'} fontSize={'14px'}>
                            <CustomTransComponent translationKey={'COMMON:DATA_SOURCE'} />
                        </Typography>
                        <Typography variant="body2" fontWeight={'400'} fontSize={'14px'}>
                            {dashboard?.data?.sourceName ?? '--'}
                        </Typography>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={2}>
                        <Typography variant="subtitle2" fontWeight={'600'} fontSize={'14px'}>
                            <CustomTransComponent translationKey={'COMMON:ASSIGNMENT'} />
                        </Typography>
                        <Typography variant="body2" fontWeight={'400'} fontSize={'14px'}>
                            {dashboard?.data?.isSystemDashboard ? 'System' : dashboard?.data?.assignment?.name}
                        </Typography>
                    </Stack>
                    <Typography variant="body1" mt={2}>
                        <CustomTransComponent translationKey={'DASHBOARDS:DASHBOARD_DETAILS_MODAL.DESCRIPTION'} />
                    </Typography>
                    <TextField
                        className="margin-top-16"
                        id="name"
                        label={<CustomTransComponent translationKey={'COMMON:NAME'} />}
                        defaultValue={'FX30S'}
                        variant="filled"
                        fullWidth
                        inputProps={{ maxLength: 32 }}
                        value={payload?.name}
                        onChange={handlePayloadChange}
                        required
                    />
                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} px={2} mb={2}>
                        <FormHelperText>Required</FormHelperText>
                        <FormHelperText>{`${payload?.name?.length ?? 0}/32`}</FormHelperText>
                    </Stack>
                    <TextField
                        id="description"
                        label={<CustomTransComponent translationKey={'COMMON:DESCRIPTION'} />}
                        variant="filled"
                        inputProps={{ maxLength: 256 }}
                        multiline
                        value={payload?.description}
                        onChange={handlePayloadChange}
                        rows={4}
                        fullWidth
                    />
                    <FormHelperText className="text-align-end padding-x-16">{`${
                        payload?.description?.length ?? 0
                    }/256`}</FormHelperText>
                    {dashboard?.data?.assignType !== 'GROUP' && (
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                                <CustomTransComponent
                                    translationKey={'DASHBOARDS:DASHBOARD_DETAILS_MODAL.SET_AS_DEFAULT'}
                                    replace={{ sourceName: dashboard?.data?.sourceName }}
                                />
                            </Typography>
                            <Switch
                                disabled
                                checked={payload?.sourceModal}
                                onChange={(): void =>
                                    setPayload((prev: any) => ({ ...prev, sourceModal: !prev.sourceModal }))
                                }
                                sx={{
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: theme?.palette?.primary?.main,
                                        '& + .MuiSwitch-track': {
                                            backgroundColor: theme?.palette?.primary?.main,
                                        },
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
                                        color: theme?.palette?.primary?.[50],
                                        '& + .MuiSwitch-track': {
                                            backgroundColor: theme?.palette?.primary?.[200],
                                        },
                                    },
                                }}
                            />
                        </Stack>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body1" fontWeight={'600'} fontSize={'16px'}>
                        <CustomTransComponent translationKey={'DASHBOARDS:DASHBOARD_DETAILS_MODAL.EMBEDED_WIDGETS'} />
                    </Typography>
                    <Stack p={2} mt={2} className="bg-content-wrapper">
                        <Stack className="bg-white height-390">
                            <List disablePadding className="max-height-650 overflow-y-scroll">
                                {(widgetLoader || widgetFetcher) && <Loader size={10} />}
                                {Boolean(widgets?.data?.records?.length) &&
                                    widgets?.data?.records?.map((widget: any) => (
                                        <InfoListItem
                                            key={widget.id}
                                            icon={
                                                <CustomIcon
                                                    family={widget?.widgetType?.icon?.web?.family ?? ''}
                                                    iconName={widget?.widgetType?.icon?.web?.name ?? ''}
                                                />
                                            }
                                            title={widget?.name}
                                            subtitle={widget?.widgetType?.name}
                                            divider="full"
                                            rightComponent={
                                                <Stack spacing={2} direction={'row'} alignItems={'center'}>
                                                    <Stack alignItems={'center'} justifyContent={'space-between'}>
                                                        <Typography variant="body2" className="fw-400">
                                                            {widget?.sourceName ?? (
                                                                <CustomTransComponent
                                                                    translationKey={'COMMON:DEFAULT'}
                                                                />
                                                            )}
                                                        </Typography>
                                                        <Stack direction={'row'}>
                                                            <Typography variant="body2" fontSize={'14px'}>
                                                                <span className="black-500 f-14 fw-700">
                                                                    {moment(widget?.updatedAt ?? Date.now()).format(
                                                                        'h:mm'
                                                                    )}
                                                                </span>{' '}
                                                                {moment(widget?.updatedAt ?? Date.now()).format('A')}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                fontWeight={'400'}
                                                                fontSize={'14px'}
                                                                ml={1}
                                                            >
                                                                {moment(widget?.updatedAt ?? Date.now()).format(
                                                                    'DD/MM/YYYY'
                                                                )}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            }
                                        />
                                    ))}
                            </List>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </CustomModal>
    );
});
