import React, { RefObject } from 'react';
import Typography from '@mui/material/Typography';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { NodeModel, useDragOver, useTreeContext } from './';
import { Avatar, Box, Checkbox, CircularProgress, IconButton, Radio, Stack } from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import * as Colors from '@brightlayer-ui/colors';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import { Spacer } from '@brightlayer-ui/react-components';

type Props = {
    node: NodeModel;
    depth: number;
    isOpen: boolean;
    testIdPrefix?: string;
    handleRef: RefObject<any>;
    controlledSelected?: boolean;
    filterPayload: any;
    parentNode?: any;
    isDragging?: boolean;
    isMouseOver: boolean;
    setIsMouseOver: any;
};

const NodeIcon = ({
    labelColor,
    treeItem,
    filterPayload,
    handleExpandHierarchy,
}: {
    labelColor?: any;
    treeItem: any;
    filterPayload: any;
    handleExpandHierarchy: any;
}): JSX.Element => {
    const theme: any = useTheme();
    const {
        controlledExpandedNodes,
        setControlledExpandedNodes,
        hasChild,
        loadingNode,
        cleanHiearchyNode,
        expandedItems,
        setExpandedItems,
    } = useTreeContext();
    const isNodeDisabled = filterPayload?.find((filter: any) => filter.action === 'disable');
    const expandedItemsClone =
        controlledExpandedNodes && setControlledExpandedNodes ? controlledExpandedNodes : expandedItems;
    switch (true) {
        case !hasChild?.(treeItem): {
            return <></>;
        }
        case loadingNode?.includes?.(treeItem?.id): {
            return (
                <Avatar sx={{ color: theme?.palette?.primary?.main }} className="bg-transparent">
                    <CircularProgress size={20} />
                </Avatar>
            );
        }
        case expandedItemsClone?.includes?.(treeItem?.id): {
            return (
                <IconButton
                    data-cy="close-hierarchy"
                    sx={{
                        color: '#727e84',
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.[50],
                            color: theme?.palette?.primary?.main,
                        },
                    }}
                    {...(!isNodeDisabled
                        ? {
                              onClick: (): void => {
                                  cleanHiearchyNode?.(treeItem?.id);
                                  if (controlledExpandedNodes && setControlledExpandedNodes) {
                                      setControlledExpandedNodes((prevState: string[]): string[] =>
                                          prevState.filter((nodeId) => nodeId !== treeItem?.id)
                                      );
                                  } else {
                                      setExpandedItems?.((prevState: string[]): string[] =>
                                          prevState.filter((nodeId) => nodeId !== treeItem?.id)
                                      );
                                  }
                              },
                          }
                        : {})}
                >
                    <ExpandMore color={treeItem?.alarmCount && labelColor ? labelColor : ''} />
                </IconButton>
            );
        }
        default: {
            return (
                <IconButton
                    data-cy="open-hierarchy"
                    sx={{
                        color: '#727e84',
                        '&:hover': {
                            backgroundColor: theme?.palette?.primary?.[50],
                            color: theme?.palette?.primary?.main,
                        },
                    }}
                    {...(!isNodeDisabled
                        ? {
                              onClick: (): void => {
                                  handleExpandHierarchy(treeItem);
                              },
                          }
                        : {})}
                >
                    <ChevronRight color={treeItem?.alarmCount && labelColor ? labelColor : ''} />
                </IconButton>
            );
        }
    }
};

const CustomContent = React.forwardRef((props: any, ref) => {
    const theme: any = useTheme();
    const {
        labelKey,
        selectionType,
        selectedNodes,
        handleTreeNodeSelection,
        hasChevron,
        disableSelect,
        getAvatarChild,
        loadingNode,
        loadMore: loadMoreHierarchy,
        dnd,
        renderLabel,
        actions,
        paginationMappingData,
        setPaginationMappingData,
        pagination,
    } = useTreeContext();

    const {
        node: treeItem,
        parentNode,
        filterPayload,
        controlledSelected,
        isMouseOver,
        isDragging,
        depth,
        handleExpandHierarchy,
    }: any = props;

    /**
     * The function "handleSelectionClick" is used to handle the click event on a selection and call
     * the "handleTreeNodeSelection" function with the appropriate parameters.
     */
    const handleSelectionClick = (): void => {
        if (treeItem?.id?.includes?.('more-button-')) {
            loadMoreHierarchy?.(parentNode, {
                page: (paginationMappingData?.[parentNode?.id] ?? 0) + 1,
                size: pagination?.size ?? 20,
            });
            setPaginationMappingData?.((prevState: any) => ({
                ...prevState,
                [parentNode?.id]: (prevState?.[parentNode?.id] ?? 0) + 1,
            }));
        } else {
            handleTreeNodeSelection?.(treeItem, parentNode);
        }
    };

    const getSelectedNodeSx = (): any => {
        if (
            treeItem?.id?.includes('preview-') ||
            ((selectionType === 'radio' || selectionType === 'node') && selectedNodes === treeItem?.id)
        ) {
            return { color: `${theme?.palette?.primary?.main} !important` };
        }
    };

    const isNodeDisabled = filterPayload?.find((filter: any) => filter.action === 'disable');
    const isNodeCheckDisabled = filterPayload?.find((filter: any) => filter.action === 'disableCheck');

    const isNodeClickDisabled = !controlledSelected && !isNodeCheckDisabled && !isNodeDisabled;
    const isNodeSelectable = !disableSelect && !isNodeCheckDisabled && !isNodeDisabled;

    const getMoreButton = (): JSX.Element => {
        if (loadingNode?.includes?.(treeItem?.id)) {
            return (
                <Avatar
                    className="height-40 width-40 margin-right-16"
                    style={{ background: 'transparent', color: Colors.gray[500] }}
                >
                    <CircularProgress size={20} sx={{ color: theme?.palette?.primary?.main }} />
                </Avatar>
            );
        }
        return (
            <Avatar
                className="height-40 width-40 margin-right-16"
                style={{ background: 'transparent', color: Colors.gray[500] }}
            >
                <MoreHoriz />
            </Avatar>
        );
    };

    return (
        <div
            id="hierarchy-view"
            data-cy={isNodeClickDisabled ? 'hierarchy-view' : undefined}
            className={clsx('hierarchy-view', 'hierarchy-item-root', {
                'hierarchy-item-disabled': isNodeDisabled,
            })}
            style={{
                ...((selectedNodes === treeItem?.id ||
                    selectedNodes?.includes?.(treeItem?.id) ||
                    treeItem?.id.includes('preview-')) && { backgroundColor: theme?.palette?.primary?.[50] }),
                ...(!dnd && { paddingLeft: `${depth === 0 ? 0 : 30 * depth}px` }),
            }}
            ref={ref as React.Ref<HTMLDivElement>}
            {...(isNodeClickDisabled && { onClick: handleSelectionClick })}
        >
            <Stack flexDirection={'row'} alignItems={'center'} width={'100%'}>
                <Box
                    {...(!isNodeDisabled
                        ? {
                              onClick: (e: any): void => {
                                  e.stopPropagation();
                              },
                          }
                        : {})}
                    sx={getSelectedNodeSx()}
                    className={'hierarchy-item-iconContainer'}
                >
                    <NodeIcon
                        labelColor={props?.labelColor}
                        treeItem={treeItem}
                        filterPayload={filterPayload}
                        handleExpandHierarchy={handleExpandHierarchy}
                    />
                </Box>
                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        {getAvatarChild &&
                            !treeItem?.id?.includes('more-button-') &&
                            getAvatarChild(treeItem, selectionType, selectedNodes, theme, props?.labelColor)}
                        {treeItem?.id?.includes('more-button-') && getMoreButton()}
                        {renderLabel?.(treeItem, 'hierarchy-item-label', getSelectedNodeSx()) ?? (
                            <Box>
                                <Typography
                                    variant="h5"
                                    component="h5"
                                    color={treeItem?.alarmCount && props?.labelColor ? props?.labelColor : ''}
                                    sx={{
                                        ...getSelectedNodeSx(),
                                        ...(dnd ? { color: 'inherit' } : {}),
                                        ...(treeItem?.sx ?? {}),
                                    }}
                                >
                                    {treeItem?.[labelKey ?? 'displayName'] ?? treeItem?.name}
                                </Typography>
                            </Box>
                        )}
                    </Stack>
                    {!treeItem?.id?.includes('more-button-') && (
                        <>
                            <Spacer />
                            {actions?.({ isMouseOver, treeItem, isDragging, parentNode }) ?? (
                                <>
                                    {selectionType === 'radio' && isNodeSelectable && (
                                        <Radio
                                            checked={selectedNodes === treeItem?.id}
                                            id={'realm'}
                                            value={treeItem?.id}
                                            onChange={handleSelectionClick}
                                            onClick={(e: any): void => e.stopPropagation()}
                                        />
                                    )}
                                    {selectionType === 'checkbox' && isNodeSelectable && (
                                        <Checkbox
                                            checked={controlledSelected || selectedNodes?.includes(treeItem?.id)}
                                            name={treeItem?.id}
                                            onClick={(e: any): void => e.stopPropagation()}
                                            disabled={controlledSelected}
                                            {...(!controlledSelected && { onChange: handleSelectionClick })}
                                        />
                                    )}
                                    {hasChevron?.(treeItem) && (
                                        <IconButton color="inherit" disableRipple>
                                            <ChevronRight />
                                        </IconButton>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Stack>
            </Stack>
        </div>
    );
});

export const DefaultNodeRender: React.FC<Props> = (props) => {
    const {
        controlledExpandedNodes,
        setControlledExpandedNodes,
        setExpandedItems,
        loadMore: loadMoreHierarchy,
        dnd,
        paginationMappingData,
        pagination,
    } = useTreeContext();

    const { setIsMouseOver } = props;
    const handleExpandHierarchy = (treeItem: any): void => {
        loadMoreHierarchy?.(treeItem, {
            page: paginationMappingData?.[treeItem?.id] ?? 0,
            size: pagination?.size ?? 20,
        });
        if (controlledExpandedNodes && setControlledExpandedNodes) {
            setControlledExpandedNodes((prevState: string[]): string[] => [...prevState, treeItem?.id]);
        } else {
            setExpandedItems?.((prevState: string[]): string[] => [...prevState, treeItem?.id]);
        }
    };

    const dragOverProps = useDragOver(props.node, props.isOpen, handleExpandHierarchy, props?.isDragging ?? false);

    const getDndContent = (): any => {
        if (dnd) {
            let result: number = 0;
            if (props?.depth === 0) {
                result = dnd ? 10 : 0;
            } else {
                result = dnd ? 48 * props?.depth : 30 * props?.depth;
            }
            return {
                paddingLeft: `${result}px`,
            };
        }
        return {};
    };

    return (
        <Box
            style={{
                alignItems: 'center',
                display: 'flex',
                borderBottom: '1px solid #f0ebeb',
                ...getDndContent(),
            }}
            {...dragOverProps}
            onMouseEnter={(): void => setIsMouseOver(true)}
            onMouseLeave={(): void => setIsMouseOver(false)}
        >
            {dnd && (
                <div
                    style={{
                        cursor: 'grab',
                        display: 'flex',
                    }}
                    ref={props.handleRef}
                >
                    <DragHandleIcon sx={{ pointerEvents: 'none' }} />
                </div>
            )}
            <CustomContent {...props} handleExpandHierarchy={handleExpandHierarchy} />
        </Box>
    );
};
