import React, { ReactNode } from 'react';
import { CustomModal, ModalActions } from './CustomModal';
import { useConfirm } from '@fiji/common/src/hooks';
import { CustomTransComponent, DeleteModal } from './';
import { Stack, Checkbox, DialogContent, Typography } from '@mui/material';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { useSelectedIds } from '../hooks';

type MultiItemsDeleteModalProps = {
    isOpen: boolean;
    header: ReactNode;
    isLoading: boolean;
    handleModalClose: () => void;
    handleConfirmDelete: () => void;
    deleteSuccess: boolean;
    handleIdsToBeDeleted: any;
    modalDescription: ReactNode;
    selectedIds: string[];
    filteredData: any;
    titleKey: string;
    titleKey1?: string;
    subtitleKey: string;
    deleteModalHeader: ReactNode;
    keyToTraverse: string;
    deleteModalDescription: ReactNode;
    confirmLabel?: ReactNode;
    deleteButtonText?: string;
    type?: string;
    nextConfirmClick?: any;
};

export const MultiItemsDeleteModal = (props: MultiItemsDeleteModalProps): JSX.Element => {
    const modalRef = React.useRef<any>(null);
    const [selectedIds, setSelectedIds, selectionHandler] = useSelectedIds();
    const { isVisible, onCancel, onClick, onConfirm } = useConfirm(props.handleConfirmDelete);

    React.useEffect(() => {
        if (props.deleteSuccess) onCancel();
    }, [props.deleteSuccess]);

    React.useEffect(() => {
        if (props?.type === 'users' && props?.filteredData?.length) {
            const newSelectedIds = props?.filteredData
                ?.filter((item: any) => item?.roleType !== 'EATON_ADMIN')
                ?.map((item: any) => item.id);
            setSelectedIds(newSelectedIds);
        }
    }, [props?.filteredData]);

    React.useEffect(() => {
        if (props?.selectedIds) {
            setSelectedIds(props.selectedIds);
        }
    }, [props?.selectedIds]);
    React.useEffect(() => {
        if (selectedIds) {
            props.handleIdsToBeDeleted(selectedIds);
        }
    }, [selectedIds]);

    const modalActions: ModalActions[] = [
        {
            key: 'cancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                props.handleModalClose();
                setSelectedIds(props?.selectedIds);
            },
        },
        {
            key: 'continue',
            label: props?.confirmLabel ?? <CustomTransComponent translationKey={'COMMON:CONTINUE'} />,
            variant: 'contained',
            color: 'error',
            className: 'error-btn',
            disabled: Boolean(!selectedIds?.length),
            handleClick: (): void => {
                if (props?.nextConfirmClick) {
                    props.handleModalClose();
                    props?.nextConfirmClick();
                    return;
                }
                props.handleModalClose();
                onClick();
            },
        },
    ];

    return (
        <>
            <CustomModal
                ref={modalRef}
                actions={modalActions}
                type="primary"
                header={props?.header}
                isOpen={props?.isOpen}
            >
                <DialogContent className="padding-0">
                    <Typography variant="body1">{props?.modalDescription}</Typography>
                    <Stack my={2} className="max-height-225 overflow-y-auto">
                        {props?.filteredData?.map((item: any) => (
                            <>
                                <InfoListItem
                                    key={item.id}
                                    className="cursor-pointer padding-y-8"
                                    dense
                                    title={
                                        <Stack>
                                            <Typography variant="body1" fontWeight={'600'}>
                                                {item?.[props.titleKey]}
                                            </Typography>
                                            {props.titleKey1 && (
                                                <Typography variant="body2" fontSize={'14px'}>
                                                    <CustomTransComponent translationKey={'COMMON:SERIAL'} />{' '}
                                                    {item?.[props.titleKey1]}
                                                </Typography>
                                            )}
                                        </Stack>
                                    }
                                    subtitle={item?.[props?.subtitleKey]}
                                    subtitleSeparator={'/'}
                                    iconAlign={'center'}
                                    ripple
                                    {...((!item?.roleType || item?.roleType !== 'EATON_ADMIN') && {
                                        icon: (
                                            <Checkbox
                                                checked={
                                                    Boolean(selectedIds.length) &&
                                                    selectedIds.includes(item[props.keyToTraverse])
                                                }
                                                onChange={selectionHandler(item[props.keyToTraverse])}
                                            />
                                        ),
                                    })}
                                />
                                {item?.roleType && item.roleType === 'EATON_ADMIN' && (
                                    <Typography variant="body2" fontSize={'13px'} marginLeft={'72px'} color="#ca3c3d">
                                        User is a Tier 1 administrator and cannot be deleted
                                    </Typography>
                                )}
                            </>
                        ))}
                    </Stack>
                </DialogContent>
            </CustomModal>
            <DeleteModal
                isVisible={isVisible}
                confirmClick={onConfirm}
                onCancel={onCancel}
                isLoading={props?.isLoading}
                header={props?.deleteModalHeader}
                description={props?.deleteModalDescription}
                deleteButtonText={props?.deleteButtonText}
            />
        </>
    );
};
