import React from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, Button, Stack, Toolbar } from '@mui/material';
import { CustomTransComponent } from 'components';
// import { useTypedSelector } from '@fiji/common';
// import { selectSubscritpionPayload } from '@fiji/common/src/features/entitlements/subscriptions/subscriptionSlice';
import { useCreatePlanMutation, useUpdatePlanMutation } from '@fiji/common/src/features/Entitlement/entitlementApi';
import {
    useCreateSubscriptionMutation,
    useUpdateSubscriptionMutation,
} from '@fiji/common/src/features/entitlements/subscriptions/subscriptionApi';
import { useNavigationBack } from 'hooks';

export const CreateRealmOrgHeader = (): JSX.Element => {
    const theme: any = useTheme();
    const [, { isLoading: isUpdating }] = useUpdatePlanMutation({ fixedCacheKey: 'updatePlan' });
    const [, { isLoading: createPlanLoading }] = useCreatePlanMutation({ fixedCacheKey: 'createPlan' });
    const [, { isLoading: isCreating }] = useCreateSubscriptionMutation({
        fixedCacheKey: 'createSubscription',
    });
    const [, { isLoading: updateSubscriptionLoader }] = useUpdateSubscriptionMutation({
        fixedCacheKey: 'updateSubscription',
    });

    const navigationBack = useNavigationBack();

    const handleCancel = (): void => {
        navigationBack();
    };
    const translationKey = location.pathname.includes('addOrg') ? 'COMMON:ADD_ORGANIZATION' : 'COMMON:ADD_REALM';
    return (
        <>
            <AppBar position={'static'}>
                <Toolbar className="bg-white text-black padding-5">
                    <Stack className="w-100">
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            marginRight={2}
                        >
                            <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} width={'100%'}>
                                <Button
                                    id="discard"
                                    disabled={false}
                                    sx={{
                                        border: `1px solid #e0e0e0`,
                                        '&:hover': {
                                            backgroundColor: theme?.palette?.primary?.[50],
                                            border: `1px solid`,
                                        },
                                    }}
                                    variant="outlined"
                                    onClick={handleCancel}
                                >
                                    <CustomTransComponent translationKey={'COMMON:CANCEL'} />
                                </Button>
                                <Button
                                    id="cancel"
                                    disabled={isUpdating || createPlanLoading || isCreating || updateSubscriptionLoader}
                                    sx={{
                                        backgroundColor: theme?.palette?.primary?.main,
                                        '&:hover': {
                                            backgroundColor: theme?.palette?.primary?.main,
                                        },
                                    }}
                                    variant="contained"
                                >
                                    <CustomTransComponent translationKey={translationKey} />
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>
        </>
    );
};
