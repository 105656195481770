import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { fixEtcGMT } from '@fiji/common/src/utils/helpers';
import moment from 'moment-timezone';

const localTimeZone = moment.tz.guess();

export const useTransformData = (): any => {
    const { data: profile } = useGetUserProfileQuery<{ data: any }>({});
    const convertDateTime = ({ timestamp, customFormat }: { timestamp: number; customFormat?: string }): any =>
        moment(timestamp)
            .tz(profile?.data?.timezone ? fixEtcGMT(profile?.data?.timezone) : localTimeZone)
            .format(customFormat ?? profile?.data?.dateFormat ?? 'DD/MM/YYYY');
    const pointConversion = (value: any): string => {
        if (isNaN(value)) return value;
        const str = value.toString();
        const decimalSeparator = profile?.data?.decimalSeparator?.[0] ?? '.';
        return str.replace('.', decimalSeparator);
    };
    return {
        convertDateTime,
        pointConversion,
        timezoneOffset: -moment.tz(profile?.data?.timezone ?? localTimeZone).utcOffset(),
    };
};
