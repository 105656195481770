import { DEFAULT_LANGUAGE } from '@fiji/common/src/constants/cacheTimeout';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        ns: ['app'],
        defaultNS: 'app',
        resources: {
            en: {
                // app: englishTranslations.translation,
                // bluiRegistration: englishTranslations.translation.bluiRegistration,
            },
        },
        lng: DEFAULT_LANGUAGE,
        fallbackLng: DEFAULT_LANGUAGE,

        interpolation: {
            escapeValue: false,
        },
    }) as any;

export default i18n;
