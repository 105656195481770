import React from 'react';
import { CustomModal, CustomTransComponent } from '../../../components';
import { useConfirm } from '@fiji/common/src/hooks';
import { ModalActions } from '../../../components/CustomModal';
import { FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { WIDGET_DESCRIPTION_LIMIT, WIDGET_NAME_LIMIT } from '@fiji/common/src/constants';
import { useHandleWidgetListActions } from '../common';
import { resetRenameWidgetModal, setRenameWidgetPayload } from '@fiji/common/src/features/widgetManagement/widgetSlice';
import { useAppDispatch } from '@fiji/common/src/app/store';

export const RenameModal = React.forwardRef((props: any, ref): JSX.Element => {
    const dispatch = useAppDispatch();
    const {
        payload,
        handleRenameModalAction: handleModalAction,
        isLoading,
        handleRenameSave: handleSave,
    } = useHandleWidgetListActions('renameWidgetModal', (data: any) => onClick(data));

    React.useImperativeHandle(
        ref,
        () => ({
            handleModalAction: (action: boolean, widgetData: any): void => handleModalAction(action, widgetData),
        }),
        []
    );
    const { data, isVisible, onCancel, onClick, onConfirm } = useConfirm((modalData: any): any =>
        handleSave(modalData, onCancel)
    );

    const handleDisable = (): boolean => {
        let bool = true;
        for (const key in payload) {
            if (payload[key] !== data[key]) {
                bool = false;
            }
        }
        if (isLoading || !payload?.name) {
            bool = true;
        }

        return bool;
    };

    const newWidgetModalActions: ModalActions[] = [
        {
            key: '#creationcancel',
            label: <CustomTransComponent translationKey={'COMMON:CANCEL'} />,
            close: true,
            variant: 'outlined',
            handleClick: (): void => {
                dispatch(resetRenameWidgetModal());
                onCancel();
            },
        },
        {
            key: '#creationsave',
            label: <CustomTransComponent translationKey={'COMMON:SAVE_LABEL'} />,
            variant: 'contained',
            disabled: handleDisable(),
            handleClick: (): void => {
                onConfirm();
            },
        },
    ];

    return (
        <CustomModal
            key="#new!@1313d"
            type="primary"
            isLoading={isLoading}
            actionsDivider
            header={<CustomTransComponent translationKey={'WIDGETS:RENAME_WIDGET_MODAL.RENAME_WIDGET'} />}
            actions={newWidgetModalActions}
            isOpen={isVisible}
            onClose={onCancel}
        >
            <Typography variant="subtitle1" fontWeight={'600'} my={2}>
                <CustomTransComponent translationKey={'WIDGETS:CLONE_MODAL_HEADER'} />
            </Typography>
            <TextField
                fullWidth
                id="widget-name"
                name="name"
                label={<CustomTransComponent translationKey={'COMMON:NAME'} />}
                inputProps={{ maxLength: WIDGET_NAME_LIMIT }}
                value={payload?.name ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    dispatch(setRenameWidgetPayload({ key: 'name', value: e.target.value }));
                }}
                variant="filled"
                className="margin-top-18"
                required
            />
            <Stack direction={'row'} justifyContent={'space-between'} px={2}>
                <FormHelperText>
                    <CustomTransComponent translationKey={'COMMON:REQUIRED_LABEL'} />
                </FormHelperText>
                <FormHelperText> {`${payload?.name?.length ?? 0}/${WIDGET_NAME_LIMIT}`}</FormHelperText>
            </Stack>
            <TextField
                id="outlined-password-input"
                fullWidth
                variant="filled"
                name="description"
                inputProps={{ maxLength: WIDGET_DESCRIPTION_LIMIT }}
                value={payload?.description ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    dispatch(setRenameWidgetPayload({ key: 'description', value: e.target.value }));
                }}
                label={<CustomTransComponent translationKey={'COMMON:DESCRIPTION'} />}
                className="margin-top-18"
            />
            <FormHelperText className="text-align-end padding-x-16">
                {`${payload?.description?.length ?? 0}/${WIDGET_DESCRIPTION_LIMIT}`}
            </FormHelperText>
        </CustomModal>
    );
});
