const WIDGET_FILTER_KEYS: any = {
    timeline_GROUP: 'groupId',
    timeline_DEVICE: 'deviceId',
    timeline_GATEWAY: 'gatewayId',
    device_list_DEVICE: 'deviceIds',
    device_list_GATEWAY: 'deviceIds',
    device_list_GROUP: 'groups',
    map_GROUP: 'groups',
    map_DEVICE: 'deviceIds',
    map_GATEWAY: 'deviceIds',
    group_list_GROUP: 'groupIds',
    schedule_DEVICE: 'deviceId',
    components_GROUP: 'groupId',
    components_DEVICE: 'deviceId',
    schedule_GATEWAY: 'deviceId',
    upcoming_GROUP: 'groupId',
    upcoming_DEVICE: 'deviceId',
    score_GROUP: 'groupId',
    score_DEVICE: 'deviceId',
};

const TREND_INITIAL_CONFIG: any = {
    chart: {
        type: 'line',
        zoomType: 'x',
        zooming: {
            mouseWheel: {
                enabled: true,
                sensitivity: 1.1,
                type: 'x',
            },
        },
    },
    title: {
        text: ' ',
    },
    credits: {
        enabled: false,
    },
    tooltip: {
        shared: true,
        style: {
            fontSize: '',
        },
        followTouchMove: false,
    },
    xAxis: {
        crosshair: {
            color: 'gray',
            dashStyle: 'Dash',
            width: 1,
        },
        type: 'datetime',
        tickLength: 0,
        zoomEnabled: true,
        panningEnabled: true,
    },
    legend: {
        enabled: false,
    },
    series: [],
    plotOptions: {
        series: {
            marker: {
                symbol: 'circle',
            },
        },
    },
    exporting: {
        enabled: false,
    },
};

const CHART_INITIAL_CONFIG = {
    chart: {
        type: 'bar',
    },
    title: {
        text: null,
    },
    xAxis: {
        title: {
            text: null,
        },
        lineWidth: 0,
        labels: {
            enabled: false,
        },
        tickColor: '#f0f8ff00',
        scrollbar: {
            enabled: true,
        },
    },
    yAxis: {
        title: {
            text: null,
        },
        labels: {
            format: '{value} KW',
            overflow: 'justify',
        },
        gridLineWidth: 0,
        tickInterval: 20,
        lineWidth: 2,
        tickColor: '#f0f8ff00',
    },
    tooltip: {
        enabled: false,
    },
    legend: {
        enabled: false,
    },
    credits: {
        enabled: false,
    },
};

const STATUS_COLORS: any = {
    Informational: 'rgba(0, 123, 193)',
    Alarm: 'rgba(200, 60, 61)',
    Warning: 'rgba(244,119,33) ',
    Offline: 'rgba(105,177,195)',
};

const CHART_TYPE: any = {
    bar: 'BAR_CHART',
    column: 'COLUMN_CHART',
    line: 'LINE_CHART',
    area: 'AREA_CHART',
};

const VIEW_ALL_FOOTER = {
    group_list: 'Groups',
    device_list: 'Devices',
    timeline: 'events',
};

export { WIDGET_FILTER_KEYS, TREND_INITIAL_CONFIG, STATUS_COLORS, CHART_INITIAL_CONFIG, CHART_TYPE, VIEW_ALL_FOOTER };
