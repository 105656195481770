import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { Avatar, List } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

export const CustomMenu = (props: { noShadow?: any; menuList: any; sx?: any }): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e: any): void => {
        e.stopPropagation();

        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                className={props?.noShadow ? 'background-none' : ''}
                onClick={handleClick}
                sx={props?.sx}
                data-test="custom-menu"
            >
                {props?.menuList?.avatar?.(open) ?? (
                    <Avatar className="bg-transparent">
                        <MoreVert className="color-content" />
                    </Avatar>
                )}
            </IconButton>
            <Menu
                className="user-menu fw-400 f-16"
                id={props?.menuList?.id}
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {props?.menuList?.menuGroups?.map((item: any) => (
                    <List disablePadding key={item?.title}>
                        <InfoListItem
                            className={item?.className}
                            sx={{ height: '3rem' }}
                            key={item?.title}
                            title={item?.title ?? ''}
                            icon={item?.icon ?? <></>}
                            onClick={(e): void => {
                                item?.onClick?.(e);
                                handleClose(e);
                            }}
                            {...(item?.isDivider && { divider: 'full' })}
                            data-test={item?.dataTest}
                        />
                    </List>
                ))}
            </Menu>
        </>
    );
};
