import React from 'react';

import {
    setAddWidgetModal,
    setDropdownOptions,
    setWidgetDeviceTypes,
    resetWidget,
    setPayload,
} from '@fiji/common/src/features/widgetManagement/widgetSlice';
import { useNavigate } from 'react-router-dom';
import { setDashboardWidgetIds } from '@fiji/common/src/features/dashboardManagement/dashboardSlice';
import { useCreateWidgetMutation } from '@fiji/common/src/features/widgetManagement/widgetApi';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { isNullOrUndefined, getWidgetTypeValidationConfig } from '@fiji/common/src/utils/helpers';
import {
    useGetAllDeviceCategoriesMutation,
    useGetDeviceModelsByCategoryMutation,
} from '@fiji/common/src/features/deviceManagement/ontologyApi';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';
import { usePayloadActions } from '@fiji/common/src/hooks/usePayloadActions';
import { selectCurrentPermission } from '@fiji/common/src/features/profile/profileSlice';
import { useRBAC } from 'hooks';
import { useTranslation } from 'react-i18next';

type ReturnProps = {
    handleIsSelected: (type: any, selectedItem: any) => boolean;
    handleNextClick: any;
    fetchDeviceTypes: (key: string, type?: string, searchParams?: any) => Promise<void>;
    deviceTypesLoader: boolean;
    widgetCreationLoader: boolean;
    handleSubmit: (callback: any) => Promise<void>;
    widgetError?: any;
};

export const useHandleAddWidgets = (): ReturnProps => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [cleanPayload] = usePayloadActions();

    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);

    const { payload, addWidgetModal: modalControls, category } = useTypedSelector((state) => state.widget);

    const [createWidget, { data: widgetCreationResponse, isLoading: widgetCreationLoader, error: widgetError }] =
        useCreateWidgetMutation();

    const [getDeviceTypesCategories, { isLoading }] = useGetAllDeviceCategoriesMutation();
    const [getDeviceModels, { isLoading: modelLoader }] = useGetDeviceModelsByCategoryMutation();

    const permissions = useTypedSelector(selectCurrentPermission);

    const { hasPermission } = useRBAC(permissions);
    const canCreateUserWidget = hasPermission('create-user-widgets');
    const canCreateSystemWidget = hasPermission('create-system-widgets');

    React.useEffect(() => {
        if (widgetCreationResponse) {
            navigate(`/${currentRealmName}/editWidget/${widgetCreationResponse?.data?.id}`, {
                state: {
                    typeId: widgetCreationResponse?.data?.widgetType?.id,
                    mode: modalControls?.mode,
                    assignType: modalControls?.assignType,
                    assignId: modalControls?.assignId,
                    dashboardId: modalControls?.dashboardId,
                },
            });
        }
    }, [widgetCreationResponse]);

    React.useEffect(() => {
        if (canCreateUserWidget && !canCreateSystemWidget) {
            dispatch(setPayload({ key: 'isSystemWidget', value: false }));
        }
    }, [canCreateSystemWidget, canCreateUserWidget]);

    const handleIsSelected = (type: any, selectedItem: any): boolean => {
        if (payload) {
            const payloadClone = JSON.parse(JSON.stringify(payload));
            if (JSON.stringify(payloadClone[type]) === JSON.stringify(selectedItem)) {
                return true;
            }
        }
        return false;
    };

    const handleNextClick = (sourceModalCallback: any, newWidgetModalCallback: any): void => {
        dispatch(setAddWidgetModal({ ...modalControls, isOpen: false }));
        if (
            payload?.selectedWidget?.id === 'newWidget' &&
            getWidgetTypeValidationConfig(payload?.widgetType?.id)['isSourceRequired'] &&
            isNullOrUndefined(payload?.widgetType?.internalCdnJsLink)
        ) {
            sourceModalCallback(true);
            return;
        }

        if (modalControls?.mode === 'dashboard' && payload?.selectedWidget?.id !== 'newWidget') {
            dispatch(setDashboardWidgetIds(payload.selectedWidget?.id));
            dispatch(resetWidget());
            return;
        }
        newWidgetModalCallback(true);
    };

    const handleSubmit = async (callback: any): Promise<void> => {
        const payloadClone = {
            type: 'PREDEFINED',
            widgetTypeId: payload?.widgetType?.id,
            name: payload?.name,
            isSystemWidget: payload?.isSystemWidget,
            categoryId: category?.id,
            description: payload.description,
            ...(payload?.widgetType?.config &&
                payload?.selectedWidget?.id === 'newWidget' && { config: payload?.widgetType?.config }),
            ...(category && !payload?.sourceId && { sourceId: category?.profileId }),
            ...(payload?.sourceId && { sourceId: payload?.sourceId }),
            ...(payload?.selectedWidget?.id !== 'newWidget' && { referenceWidgetId: payload?.selectedWidget?.id }),
        };

        const { error }: any = await createWidget(cleanPayload(payloadClone));
        if (!error) {
            callback();
            dispatch(resetWidget());
            dispatch(
                setMessageContent({
                    isOpen: true,
                    message: t('ADD_DEVICE_FORM:DEVICE_SAVED_SUCCESSFULLY', { replace: { deviceName: payload?.name } }),
                })
            );
        }
    };

    const fetchDeviceTypes = async (key: string, type?: string, searchParams?: any): Promise<void> => {
        if (type !== 'list') {
            const { data }: any = await getDeviceTypesCategories({
                category: 'CATEGORY',
            });

            if (data) {
                dispatch(setDropdownOptions(data?.data));
            }
        } else {
            const { data }: any = await getDeviceModels({
                filter: key,
                ...searchParams,
            });
            if (data) {
                dispatch(setWidgetDeviceTypes(data?.data));
                return;
            }
        }
    };

    return {
        handleSubmit,
        handleIsSelected,
        handleNextClick,
        fetchDeviceTypes,
        widgetCreationLoader,
        deviceTypesLoader: isLoading || modelLoader,
        widgetError,
    };
};
