import React from 'react';

import { Header } from '@fiji/common/src/types';
import CustomIcon from '../../../components/CustomIcon';
import { CustomTable, CustomTransComponent, DateAndYearSkelton, GroupPath, Hierarchy } from '../../../components';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { useNavigate, useParams } from 'react-router-dom';
import ChevronRight from '@mui/icons-material/ChevronRight';
import FileDownload from '@mui/icons-material/FileDownload';
import TrendingUp from '@mui/icons-material/TrendingUp';
import { useGetDeviceTimelineQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import {
    Avatar,
    Box,
    Chip,
    FormControl,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';
import { useGetAllGroupsMutation } from '@fiji/common/src/features/group/groupApi';
import { GroupHierarchySkeleton } from 'pages/GroupHierarchy/GroupHierarchySkeleton';
import { useFilteredValues } from 'hooks/useFilteredValues';
import { useDebounce, useIsMount } from '@fiji/common/src/hooks';
import { DateCalendar } from '@mui/x-date-pickers-pro';
import {
    downloadFileFromLink,
    getListItemBackgroundColor,
    getListItemTextColor,
    getTimelineBorder,
    toFirstLetterUpperCase,
} from 'utils/helpers';
import {
    useDownloadTimelineMutation,
    useGetTimelineAllStatusQuery,
} from '@fiji/common/src/features/Timeline/timelineApi';
import { ListItemTag } from '@brightlayer-ui/react-components';
import { getAlarmLabelValue, isNullOrUndefined } from '@fiji/common/src/utils/helpers';
import { useTransformData } from 'hooks/useTransformData';
import { useDispatch } from 'react-redux';
import { setMessageContent, setTimelineStatusColor } from '@fiji/common/src/features/common/commonSlice';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { STATUS_COLORS } from 'pages/WidgetManagement/common';
import { InputWithCustomPlaceholder } from 'components/InputWithCustomPlaceholder';
import { useTranslation } from 'react-i18next';

const getDateCell = (data: any, convertDateTime: any): any =>
    !isNullOrUndefined(data?.alarmUpdated) ? (
        <Stack>
            <Typography variant="body2" fontSize={'12px'}>
                <b>
                    {convertDateTime({
                        timestamp: parseInt(data?.alarmUpdated),
                        customFormat: 'HH:mm',
                    })}{' '}
                </b>
            </Typography>
            <Typography variant="body2">
                {convertDateTime({
                    timestamp: parseInt(data?.alarmUpdated),
                })}
            </Typography>
        </Stack>
    ) : (
        <>--</>
    );

const getTypeCell = (data: any): JSX.Element => <span>{data?.displayName ?? '--'}</span>;

const getDeviceCell = (data: any): JSX.Element => (
    <Stack>
        <Typography variant="body2" fontSize={'12px'}>
            {data?.deviceName ?? '--'}
        </Typography>
        <Typography variant="body2">{`# ${data?.serialNumber?.length ? data?.serialNumber : '--'}`}</Typography>
    </Stack>
);

const getGroupCell = (data: any): JSX.Element => <GroupPath pathName={[...(data?.groupPath ?? [])]} />;

const getCustomIconCell = (item: any): JSX.Element => (
    <Avatar sx={{ bgcolor: item?.icons?.status?.icon?.web?.style?.backgroundColor }}>
        <CustomIcon
            iconName={item?.icons?.status?.icon?.web?.name ?? ''}
            family={item?.icons?.status?.icon?.web?.family ?? ''}
            iconProps={{
                sx: {
                    color: item?.icons?.status?.icon?.web?.style?.color,
                },
            }}
        />
    </Avatar>
);

export const Timeline = (): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { deviceId, groupId } = useParams();
    const isMount = useIsMount();
    const tableRef: any = React.useRef();
    const dispatch = useDispatch();

    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);
    const currentOrg = useTypedSelector(selectedOrg);
    const selectedNode = useTypedSelector((state) => state['common']['selectedNode']);
    const [searchKey, setSearchKey] = React.useState<any>();
    const { data: timelineStatusList }: any = useGetTimelineAllStatusQuery({ showCategory: false });
    const [downloadTimeline, { isSuccess: downloadTimelineSuccess, data: downloadedFile }] =
        useDownloadTimelineMutation();
    const { data: profile } = useGetUserProfileQuery<{ data: any }>({});

    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);
    const { convertDateTime } = useTransformData();

    const [payload, setPayload] = React.useState<any>({
        page: 0,
        size: 10,
        filters: {
            ...(deviceId && { deviceId: [deviceId] }),
            ...(groupId && groupId !== currentOrg?.id && { groupId: [groupId] }),
        },
    });

    const [date, setDate] = React.useState<any>(null);

    const [selectedGroups, setSelectedGroups] = React.useState<any>([]);
    const [groupsData, setGroupsData] = React.useState<any>();
    const [hierarchyMappingData, setHierarchyMappingData] = React.useState({});
    const [loadingHierarchyNode, setLoadingHierarchyNode] = React.useState([]);
    const [getAllGroups, { data: groupsHierarchy }]: any = useGetAllGroupsMutation();
    const [activeDuration, setActiveDuration] = React.useState<number>(7);

    React.useEffect(() => {
        if (selectedNode?.id || groupId) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: {
                    ...prev.filters,
                    ...(deviceId && { deviceId: [selectedNode.id] }),
                    ...(groupId && groupId !== currentOrg?.id && { groupId: [groupId] }),
                },
            }));
        }
    }, [selectedNode, groupId]);

    React.useEffect(() => {
        if (!isMount) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                searchKey: debouncedValue,
            }));
        }
    }, [debouncedValue]);

    React.useEffect(() => {
        if (!isMount && selectedGroups) {
            const filterClone = JSON.parse(JSON.stringify(payload?.filters ?? {}));
            if (selectedGroups?.length) {
                filterClone['groupId'] = selectedGroups;
            } else {
                delete filterClone['groupId'];
            }

            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: { ...filterClone },
            }));
        }
    }, [selectedGroups]);

    React.useEffect(() => {
        if (date) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: { ...prev.filters, alarmUpdated: new Date(date)?.getTime() + 86399000 },
            }));
        }
    }, [date]);

    React.useEffect(() => {
        if (!groupsData) {
            setGroupsData(groupsHierarchy?.data?.records);
        }
    }, [groupsHierarchy]);

    React.useEffect(() => {
        if (currentOrg?.id) {
            getAllGroups({});
        }
    }, [currentOrg]);

    React.useEffect(() => {
        if (downloadTimelineSuccess) {
            if (downloadedFile?.data) {
                downloadFileFromLink(downloadedFile?.data, 'Timeline');
                dispatch(
                    setMessageContent({
                        isOpen: true,
                        message: t('AUDIT_LOGS:DOWNLOAD_MSG'),
                        duration: 3000,
                    })
                );
            } else {
                dispatch(
                    setMessageContent({
                        isOpen: true,
                        message: t('COMMON:NO_DATA'),
                        duration: 3000,
                    })
                );
            }
        }
    }, [downloadTimelineSuccess]);

    const handleFetchHierarchy = async (parentData: any): Promise<void> => {
        setLoadingHierarchyNode((prevState): any => [...prevState, parentData?.id]);
        const { data: childHierarchyData }: any = await getAllGroups({ parent: parentData?.id });
        if (childHierarchyData) {
            setHierarchyMappingData((prevState) => ({
                ...prevState,
                [parentData?.id]: childHierarchyData?.data?.records,
            }));
        }

        setLoadingHierarchyNode((prevState): any => prevState.filter((loadingNode) => loadingNode !== parentData?.id));
    };
    const groupSelectionHandler = (id: string): void => {
        const payloadClone = JSON.parse(JSON.stringify(selectedGroups));
        const uncheckedIndex = payloadClone?.indexOf(id);
        if (uncheckedIndex !== -1) {
            payloadClone?.splice(uncheckedIndex, 1);
        } else if (id) {
            payloadClone?.push(id);
        }
        setSelectedGroups(payloadClone);
    };

    const handleChipDelete = (key: any): void => {
        tableRef?.current?.resetFilters(key, true);
        if (key === 'alarmUpdated') {
            setDate(null);
        }
        if (key === 'groupId') {
            setSelectedGroups([]);
        }
        const paginationPayloadClone = JSON.parse(JSON.stringify(payload));
        delete paginationPayloadClone?.filters[key];
        setPayload(paginationPayloadClone);
    };

    const getGroupHeaderOptions = (): JSX.Element => (
        <Hierarchy
            isLoading={!groupsData}
            data={
                currentOrg?.defaultGroupAccess
                    ? [
                          {
                              ...currentOrg,
                              name: currentOrg?.name,
                              groupCount: currentOrg?.groupCount,
                              deviceCount: currentOrg?.deviceCount,
                              type: 'GROUP',
                              icon: { web: { family: 'material-ui', name: 'CorporateFare' } },
                          },
                      ]
                    : groupsData
            }
            hasChild={(treeItem: any): boolean => treeItem?.groupCount}
            labelKey={'name'}
            hierarchyMappingData={hierarchyMappingData}
            loadingNode={loadingHierarchyNode}
            handleTreeNodeSelection={(parentNode: any): any => {
                groupSelectionHandler(parentNode?.id);
            }}
            filters={[
                {
                    key: 'type',
                    operator: '!==',
                    value: 'DEVICE',
                    action: 'hidden',
                },
                {
                    key: 'type',
                    operator: '!==',
                    value: 'GATEWAY',
                    action: 'hidden',
                },
            ]}
            autoChildSelection={true}
            loadMore={handleFetchHierarchy}
            loadingSkeleton={<GroupHierarchySkeleton row={1} />}
            selectedNodes={selectedGroups}
            selectionType={'checkbox'} // radio, checkbox, node
        />
    );

    const getDateHeaderOptions = (): JSX.Element => (
        <DateCalendar disableFuture value={date ?? null} onChange={(data: any): void => setDate(data)} />
    );

    const getActionCell = (data: any): JSX.Element => (
        <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'end'}>
            {(data?.status === 'Active' || data?.status === 'Cleared') && (
                <ListItemTag
                    sx={{
                        border: getTimelineBorder(data),
                    }}
                    label={getAlarmLabelValue(data)}
                    fontColor={getListItemTextColor(data)}
                    backgroundColor={getListItemBackgroundColor(data)}
                />
            )}
            <IconButton
                id="detail-page"
                onClick={(): void => {
                    navigate(`/${currentRealmName}/TimeLineDetails/${data?.id} `);
                    dispatch(
                        setTimelineStatusColor(
                            STATUS_COLORS[data?.status === 'Active' ? data?.severity : 'Informational'] ??
                                STATUS_COLORS?.Informational
                        )
                    );
                }}
            >
                <ChevronRight />
            </IconButton>
        </Stack>
    );

    const getExtraHeaderOptions = (): JSX.Element => (
        <Box display={'flex'} alignItems={'center'}>
            <FormControl sx={{ minWidth: 80 }}>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Age"
                    variant="standard"
                    placeholder={(<CustomTransComponent translationKey={'MULTI_TRENDS:FIFTEEN_DAYS'} />) as any}
                    disableUnderline
                    value={activeDuration}
                    defaultValue={activeDuration ?? 7}
                    onChange={(e: any): void => setActiveDuration(e?.target?.value)}
                >
                    <MenuItem value={7}>
                        <CustomTransComponent translationKey={'DASHBOARD_MANAGEMENT:SEVEN_DAYS'} />
                    </MenuItem>
                    <MenuItem value={15}>
                        <CustomTransComponent translationKey={'MULTI_TRENDS:FIFTEEN_DAYS'} />
                    </MenuItem>
                    <MenuItem value={31}>
                        <CustomTransComponent translationKey={'MULTI_TRENDS:THIRTY_ONE_DAYS'} />
                    </MenuItem>
                    <MenuItem value={365}>
                        <CustomTransComponent translationKey={'COMMON:ONE_YEAR'} />
                    </MenuItem>
                </Select>
            </FormControl>

            <IconButton
                id="download"
                disabled={!timelineData?.data?.records?.length}
                onClick={async (): Promise<void> => {
                    const newPayload = JSON.parse(JSON.stringify(payload));
                    delete newPayload.page;
                    newPayload.size = timelineData?.data?.total;
                    // if (deviceId) {
                    //     newPayload.filters['deviceId'] = [deviceId];
                    // }
                    await downloadTimeline({
                        body: {
                            ...newPayload,
                            filters: {
                                ...(newPayload?.filters ?? {}),
                                duration: activeDuration,
                            },
                            timezone: profile?.data?.timezone,
                        },
                        id: deviceId ?? groupId ?? '',
                    });
                }}
            >
                <FileDownload sx={{ color: '#727E84' }} />
            </IconButton>
        </Box>
    );

    const getStatusStyles = (data: any): any => {
        if (data?.severity === 'Alarm' || data?.status?.includes('Active')) {
            return {
                content: '" "',
                position: 'absolute',
                top: '0px',
                bottom: '-1px',
                left: '0px',
                width: '6px',
                zIndex: '100',
                backgroundColor: data?.icons?.status?.indicator ?? '#FFFFF',
            };
        }
    };

    const tableRows: Header[] = [
        {
            header: (<CustomTransComponent size={{ width: '100%' }} translationKey={'COMMON:STATUS'} />) as any,
            accessor: 'severity',
            width: '15%',
            sx: getStatusStyles,
            isSortable: true,
            isFilterable: true,
            filterOptions: [{ id: 'all', label: <CustomTransComponent translationKey={'SCHEDULING:ALL'} /> }].concat(
                timelineStatusList?.data?.status?.map((timeline: any) => ({
                    id: timeline?.name,
                    label: timeline?.displayName,
                }))
            ),
            cell: (item: any) => getCustomIconCell(item),
            skeleton: (
                <Skeleton animation="wave" variant="circular">
                    <Avatar />
                </Skeleton>
            ),
        },
        {
            header: (<CustomTransComponent size={{ width: '100%' }} translationKey={'COMMON:DATE'} />) as any,
            accessor: 'alarmUpdated',
            isSortable: true,
            isFilterable: true,
            width: '25%',
            headerOptions: getDateHeaderOptions,
            cell: (data: any): JSX.Element => getDateCell(data, convertDateTime),
            skeleton: <DateAndYearSkelton />,
        },
        {
            header: (<CustomTransComponent size={{ width: '100%' }} translationKey={'COMMON:TYPE'} />) as any,
            accessor: 'eventName',
            width: '15%',
            isSortable: true,
            isFilterable: true,
            isDebounce: true,
            cell: (data: any) => getTypeCell(data),
        },
        {
            accessor: 'deviceName',
            isSortable: true,
            width: '15%',
            header: (<CustomTransComponent size={{ width: '100%' }} translationKey={'COMMON:DEVICE'} />) as any,
            isFilterable: true,
            isDebounce: true,
            cell: (data: any) => getDeviceCell(data),
        },
        {
            header: (<CustomTransComponent size={{ width: '100%' }} translationKey={'COMMON:GROUP'} />) as any,
            width: '15%',
            accessor: 'groupId',
            isSortable: true,
            isFilterable: true,
            cell: (data) => getGroupCell(data),
            headerOptions: getGroupHeaderOptions,
        },

        {
            cell: getActionCell,
            header: '',
            width: '15%',
            extraOptions: getExtraHeaderOptions,
            align: 'end',
        },
    ];
    const [tableFilters] = useFilteredValues({
        allFilters: tableRows,
        triggeredFilters: payload?.filters,
    });

    const handleTimelinePageChange = (page: any, size: any): void => {
        setPayload((prev: any) => ({ ...prev, page: page, size: size }));
    };

    const handleFilterChange = (filters: any, sortData?: any): void => {
        if (JSON.stringify(filters) !== JSON.stringify(payload.filters)) {
            const customKeys: any = {
                ...(deviceId && { deviceId: [deviceId] }),
                ...(payload?.filters?.groupId?.length && { groupId: payload?.filters?.groupId }),
                ...(groupId && groupId !== currentOrg?.id && { groupId: [groupId] }),
                ...(date && { alarmUpdated: payload?.filters?.alarmUpdated }),
            };

            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: { ...filters, ...(isMount ? customKeys : {}) },
            }));
        }
        if (sortData && Object.keys(sortData)?.length) {
            setPayload((prev: any) => ({
                ...prev,
                page: 0,
                sort: sortData,
            }));
        }
    };

    const getFilteredValue = (filterValue: any, wrapperDeviceType: any): any => {
        const newFilter = JSON.parse(JSON.stringify(filterValue ?? {}));
        if (wrapperDeviceType === 'Gateway' && filterValue?.deviceId) {
            newFilter['gatewayId'] = [deviceId];
            delete newFilter['deviceId'];
        }
        return newFilter;
    };

    const {
        data: timelineData,
        isLoading,
        isFetching,
    }: any = useGetDeviceTimelineQuery(
        {
            body: {
                ...payload,
                filters: {
                    ...(getFilteredValue(tableFilters, selectedNode?.wrapperDeviceType) ?? {}),
                },
            },
        },
        { skip: groupId !== currentOrg?.id && !selectedNode?.id, refetchOnMountOrArgChange: true }
    );

    const getChipName = (key: string): any => {
        switch (key) {
            case 'alarmUpdated':
                return 'Date';
            case 'groupId':
                return 'Group';
            case 'deviceName':
                return 'Device';

            case 'eventName':
                return 'Type';

            case 'severity':
                return 'Status';

            default:
                return toFirstLetterUpperCase(key);
        }
    };

    return (
        <Stack p={3}>
            <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                <InputWithCustomPlaceholder
                    id="searchKey"
                    value={searchKey ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        setSearchKey(e.target.value);
                    }}
                    placeholder={t('COMMON:SEARCH_PLACEHOLDER')}
                />

                {payload?.filters && (
                    <Stack direction="row" spacing={1}>
                        {Object.keys(payload?.filters).map(
                            (key: string) =>
                                key !== 'deviceId' && (
                                    <Chip
                                        key={`unique${key}`}
                                        label={getChipName(key)}
                                        onDelete={(): void => {
                                            handleChipDelete(key);
                                        }}
                                    />
                                )
                        )}
                    </Stack>
                )}
            </Stack>

            <Paper className="margin-y-16">
                <CustomTable
                    ref={tableRef}
                    data={timelineData?.data?.records}
                    isPagination
                    handlePageChange={handleTimelinePageChange}
                    isLoading={isLoading || isFetching}
                    keyToTraverse="id"
                    total={timelineData?.data?.total}
                    headers={tableRows}
                    noDataFoundIcon={<TrendingUp fontSize="inherit" />}
                    noDataFoundTitle={<CustomTransComponent translationKey={'TIMELINE:NO_TIMELINE'} />}
                    handleFilterChange={handleFilterChange}
                    containerHeight={280}
                />
            </Paper>
        </Stack>
    );
};
