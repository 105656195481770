import React from 'react';
import { Typography, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { Spacer } from '@brightlayer-ui/react-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as Colors from '@brightlayer-ui/colors';

type CustomHeaderProps = {
    title: string | React.ReactNode;
    subtitle: string;
    crossIcon?: boolean;
    noShadowedHeader?: boolean;
    handleNavigateBack: () => void;
};

export const CustomHeader = ({
    title,
    subtitle,

    crossIcon,
    noShadowedHeader,
    handleNavigateBack,
}: CustomHeaderProps): JSX.Element => {
    const timelineStatusColor = useSelector((appState: any) => appState.common.timelineStatusColor);
    const headerColorChange = useSelector((appState: any) => appState.common.headerColor);

    const getColors = (color: string): any => {
        switch (color) {
            case 'red':
                return Colors.red;
            case 'orange':
                return Colors.orange;
            default:
                return Colors.blue;
        }
    };

    return (
        <AppBar
            className="position-sticky"
            // sx={{
            //     color: 'inherit',
            //     backgroundColor: `${timelineStatusColor || getColors(headerColorChange)[500]}`,
            //     pr: `0px !important`,
            // }}
            sx={
                noShadowedHeader
                    ? { boxShadow: 'none', color: 'inherit', pr: `0px !important`, zIndex: 1299 }
                    : {
                          color: 'inherit',
                          backgroundColor: `${timelineStatusColor || getColors(headerColorChange)[500]}`,
                          pr: `0px !important`,
                          zIndex: 1299,
                      }
            }
        >
            <Toolbar className={`padding-x-16 min-height-56 bg-white`}>
                {crossIcon ? (
                    <IconButton onClick={handleNavigateBack}>
                        <CloseIcon
                            style={{
                                color: timelineStatusColor ? '#FFF' : '#424e54',
                            }}
                        />
                    </IconButton>
                ) : (
                    <IconButton onClick={handleNavigateBack}>
                        <ArrowBackIcon
                            style={{
                                color: timelineStatusColor ? '#FFF' : '#424e54',
                            }}
                        />
                    </IconButton>
                )}
                <Stack pl={3} direction={'column'}>
                    <Typography variant={'h6'} color={'inherit'} className={`${'text-secondary'}`}>
                        {title}
                    </Typography>
                    <Typography variant={'body1'} color={Colors.black[500]}>
                        {subtitle}
                    </Typography>
                </Stack>
                <Spacer />
            </Toolbar>
        </AppBar>
    );
};
