import { Skeleton } from '@mui/material';
import { useNewCustomTranslations } from 'hooks';
import i18next from 'i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { ApiEndpointType, ApiResponseType } from '@fiji/common/src/features/common/commonTypes';
import { Profile } from '@fiji/common/src/types';
import { DEFAULT_LANGUAGE } from '@fiji/common/src/constants/cacheTimeout';

export const CustomTransComponent = ({
    translationKey,
    size,
    replace,
    components,
    skip,
    customT,
}: {
    translationKey: any;
    size?: any;
    replace?: any;
    components?: any;
    skip?: boolean;
    customT?: any;
}): any => {
    const { t } = useTranslation();
    const { data: userProfileData } = useGetUserProfileQuery({}, { skip }) as ApiEndpointType<ApiResponseType<Profile>>;
    const language = userProfileData?.data?.language ?? DEFAULT_LANGUAGE;
    useNewCustomTranslations(translationKey?.split(':')[0], skip);

    const getCustomTransComponent = (): any => {
        if (!i18next.hasResourceBundle(language, translationKey?.split(':')[0])) {
            return (
                <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={size?.width ?? 200}
                    height={size?.height ?? 15}
                    sx={{ marginBottom: '10px', marginTop: '10px' }}
                />
            );
        } else if (customT) {
            return t(translationKey);
        }
        return <Trans i18nKey={translationKey} values={replace} components={components} />;
    };

    return <>{getCustomTransComponent()}</>;
};
