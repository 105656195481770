import React from 'react';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    Stack,
    Typography,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Close from '@mui/icons-material/Close';
import DragHandle from '@mui/icons-material/DragHandle';
import { useDefaultConfig, useWidgetConfigHandlers } from '../hooks';
import { ListSkeleton } from '../Skeletons';
import { CustomTransComponent, GroupPath } from 'components';
import { ArrowDropDown, Check } from '@mui/icons-material';
import { setCommandPaletteEl } from '@fiji/common/src/features/widgetManagement/commandBarWidgetSlice';
import { createNumArray } from 'utils/helpers';
import { COMMAND_PALETTE } from './commandPalette';
import { useTranslation } from 'react-i18next';

const loadNumberStyle = {
    fontSize: '14px',
    fontWeight: '600',
    backgroundColor: '#727E84',
    color: '#fff',
    width: '18px',
    height: '18px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

export const RearrangeChannels = (props: any): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const channelPayload = useTypedSelector(
        (state) =>
            state[
                props?.details?.widgetType?.id === 'settings' || props?.details?.widgetType?.id === 'properties'
                    ? 'details'
                    : props?.details?.widgetType?.id
            ]
    );

    const commandPaletteEl = useTypedSelector((state) => state.command_palette.commandPaletteEl);

    const { isLoading: widgetLoader } = useWidgetConfigHandlers();

    const { onDragEnd, handleRemoveChannel, handleChannelSelection, isCommandPaletteChecked } = useDefaultConfig(
        props?.details?.widgetType?.id
    );

    function handleSubHeading(): string {
        switch (props?.details?.widgetType?.id) {
            case 'loads':
                return (<CustomTransComponent translationKey={'COMMON:SELECTED LOADS'} />) as any;
            case 'details':
                return (<CustomTransComponent translationKey={'COMMON:SECONDARY'} />) as any;
            case 'command_bar':
                return (<CustomTransComponent translationKey={'COMMON:SELECTED CONTROLS'} />) as any;
            default:
                return (<CustomTransComponent translationKey={'COMMON:SELECTED CHANNELS'} />) as any;
        }
    }

    const handleCloseCommandPalette = (): void => {
        dispatch(setCommandPaletteEl(null));
    };

    const handleOpenCommandPalette = (
        event: React.MouseEvent<HTMLButtonElement>,
        deviceId: string,
        channelId: string
    ): void => {
        dispatch(setCommandPaletteEl({ el: event.currentTarget, deviceId, channelId }));
    };

    const handleSelectCommandPalette = ({ color, type, channelId, deviceId }: any): void => {
        handleChannelSelection(undefined, { data: { color, type }, channelId, deviceId });
    };

    const getCommandBarContent = (): any => {
        if (props?.details?.widgetType?.id === 'command_bar') {
            return <CustomTransComponent translationKey={'WIDGET_MANAGEMENT:SELECT_CONTROLS'} />;
        }
        return <CustomTransComponent translationKey={'COMMON:SELECTED_CHANNELS'} />;
    };

    const getLoadsContent = (): any => {
        if (props?.details?.widgetType?.id === 'loads') {
            return t('COMMON:LOADS');
        }
        return t('COMMON:CHANNELS');
    };

    const getSecondaryActionInPrimary = (item: any): JSX.Element => (
        <IconButton
            edge="end"
            aria-label="close"
            onClick={(): void => {
                handleRemoveChannel({
                    type: 'primary',
                    channel: item,
                });
            }}
        >
            <Close />
        </IconButton>
    );

    const getSecondaryActionInSecondary = (item: any): JSX.Element => (
        <Stack alignItems={'center'} direction={'row'}>
            {props?.details?.widgetType?.id === 'command_bar' && (
                <Button onClick={(e) => handleOpenCommandPalette(e, item.deviceId, item?.channelId)}>
                    <Box
                        sx={{
                            ...(item?.paletteConfig?.type === 'contained'
                                ? {
                                      backgroundColor: item?.paletteConfig?.color,
                                  }
                                : {
                                      border: `2px solid ${item?.paletteConfig?.color}`,
                                      position: 'relative',
                                  }),
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            margin: '4px',
                        }}
                    ></Box>
                    <ArrowDropDown />
                </Button>
            )}
            <IconButton
                edge="end"
                aria-label="close"
                onClick={(): void => {
                    handleRemoveChannel({
                        type: 'secondary',
                        channel: item,
                    });
                }}
            >
                <Close />
            </IconButton>
        </Stack>
    );
    const getPrimaryDraggableContainer = (): JSX.Element => (
        <List disablePadding>
            {channelPayload?.primary?.map(
                (channel: any, index: number, prev: any) =>
                    (prev[index - 1]?.channelId !== channel?.channelId ||
                        prev[index - 1]?.['deviceId'] !== channel?.['deviceId']) && (
                        <Draggable
                            key={`${channel?.channelId}${channel?.deviceId}`}
                            draggableId={`${channel?.channelId}${channel?.deviceId}`}
                            index={index}
                        >
                            {(providedItem): any => (
                                <ListItem
                                    ref={providedItem.innerRef}
                                    {...providedItem.draggableProps}
                                    className="border-bottom-1 list-item-hover"
                                    disablePadding
                                    secondaryAction={getSecondaryActionInPrimary(channel)}
                                >
                                    <ListItemButton className="padding-8" dense>
                                        <ListItemIcon {...providedItem.dragHandleProps} role={undefined}>
                                            <DragHandle />
                                        </ListItemIcon>
                                        {}
                                        <ListItemText
                                            className="text-secondary"
                                            primary={
                                                <Typography variant="body2" fontWeight={'600'}>
                                                    {channel?.displayName}
                                                </Typography>
                                            }
                                            secondary={
                                                channel['devicePath'] && (
                                                    <GroupPath
                                                        pathName={channel['devicePath']}
                                                        deviceName={channel['deviceName']}
                                                    />
                                                )
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )}
                        </Draggable>
                    )
            )}
        </List>
    );

    const getSecondaryDraggableContainer = (): JSX.Element => (
        <List disablePadding>
            {channelPayload?.secondary.map(
                (item: any, index: number, prev: any) =>
                    (prev[index - 1]?.[props?.keyToTraverse ?? 'channelId'] !==
                        item?.[props?.keyToTraverse ?? 'channelId'] ||
                        prev[index - 1]?.['deviceId'] !== item?.['deviceId']) && (
                        <Draggable
                            key={`${item?.[props?.keyToTraverse ?? 'channelId']}${item?.deviceId}`}
                            draggableId={`${item?.[props?.keyToTraverse ?? 'channelId']}${item?.deviceId}`}
                            index={index}
                        >
                            {(providedItem): any => (
                                <ListItem
                                    ref={providedItem.innerRef}
                                    className="border-bottom-1 list-item-hover"
                                    disablePadding
                                    secondaryAction={getSecondaryActionInSecondary(item)}
                                >
                                    <ListItemButton
                                        className="padding-8"
                                        {...providedItem.draggableProps}
                                        role={undefined}
                                        dense
                                    >
                                        <ListItemIcon role={undefined} {...providedItem.dragHandleProps}>
                                            <DragHandle />
                                        </ListItemIcon>
                                        {props?.keyToTraverse === 'loadId' && (
                                            <Box sx={loadNumberStyle}>{item.loadNumber}</Box>
                                        )}

                                        <ListItemText
                                            {...(props.keyToTraverse === 'loadId' && {
                                                sx: { marginLeft: '25px' },
                                            })}
                                            primary={
                                                <Typography variant="body2" fontWeight={'600'}>
                                                    {item[props.titleKey ?? 'displayName']}
                                                </Typography>
                                            }
                                            secondary={
                                                item['devicePath'] && (
                                                    <GroupPath
                                                        pathName={item['devicePath']}
                                                        deviceName={item['deviceName']}
                                                    />
                                                )
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )}
                        </Draggable>
                    )
            )}
        </List>
    );

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Grid item py={3} px={2} className="bg-content-wrapper height-800 w-49">
                <Stack className="border-bottom-1">
                    <Typography variant="body1" fontWeight={'600'}>
                        {props?.details?.widgetType?.id === 'loads' ? 'Selected Loads' : getCommandBarContent()}
                    </Typography>
                    <Typography mt={3} variant="body1" pb={3} fontWeight={'400'}>
                        {props?.details?.widgetType?.id === 'command_bar' ? (
                            <CustomTransComponent
                                translationKey={
                                    'WIDGET_MANAGEMENT:SELECT_CONTROLS_THAT_YOU_WOULD_LIKE_TO_VIEW_IN_THE_WIDGET'
                                }
                            />
                        ) : (
                            <CustomTransComponent
                                translationKey={'WIDGETS:COMMON.REARRANGE_CHANNELS_DESCRIPTION'}
                                replace={{
                                    channelType: getLoadsContent(),
                                    count: `(${
                                        (channelPayload?.primary?.length ?? 0) +
                                        (channelPayload?.secondary?.length ?? 0)
                                    })`,
                                }}
                            />
                        )}
                    </Typography>
                </Stack>
                <Stack mt={2} className="max-height-630 overflow-y-scroll">
                    {widgetLoader && <ListSkeleton total={5} primaryVariant="rectangular" />}
                    {!widgetLoader && (
                        <>
                            {props?.details?.widgetType?.id === 'details' && (
                                <Stack>
                                    <Typography variant="body2" ml={2}>
                                        <CustomTransComponent translationKey={'COMMON:PRIMARY'} />
                                    </Typography>
                                    <Droppable droppableId="primary">
                                        {(provided): any => (
                                            <Box
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                className="bg-white"
                                                my={2}
                                            >
                                                {getPrimaryDraggableContainer()}
                                                {provided.placeholder}
                                            </Box>
                                        )}
                                    </Droppable>
                                </Stack>
                            )}
                            {props?.details?.widgetType?.id === 'details' && <Divider />}
                            <Stack sx={{ marginTop: '10px' }}>
                                <Typography ml={2} variant="body2" className="text-secondary">
                                    {handleSubHeading()}
                                </Typography>

                                <Droppable droppableId="secondary">
                                    {(provided): any => (
                                        <Box
                                            my={2}
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className="bg-white"
                                        >
                                            {getSecondaryDraggableContainer()}
                                            {provided.placeholder}
                                        </Box>
                                    )}
                                </Droppable>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={commandPaletteEl?.el}
                                    open={Boolean(commandPaletteEl?.el)}
                                    onClose={handleCloseCommandPalette}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    {createNumArray(COMMAND_PALETTE.length / 10).map((item, stackIndex) => (
                                        <Stack direction={'row'} key={item}>
                                            {COMMAND_PALETTE?.slice(10 * stackIndex, 10 * (stackIndex + 1)).map(
                                                (currentPalette) => (
                                                    <Box
                                                        key={`${currentPalette.type}-${currentPalette.color}`}
                                                        sx={{
                                                            ...(currentPalette?.type === 'contained'
                                                                ? { backgroundColor: currentPalette?.color }
                                                                : {
                                                                      border: `2px solid ${currentPalette?.color}`,
                                                                      position: 'relative',
                                                                  }),
                                                            width: '24px',
                                                            height: '24px',
                                                            borderRadius: '50%',
                                                            margin: '4px',
                                                        }}
                                                        onClick={() =>
                                                            handleSelectCommandPalette({
                                                                ...currentPalette,
                                                                channelId: commandPaletteEl?.channelId,
                                                                deviceId: commandPaletteEl?.deviceId,
                                                            })
                                                        }
                                                    >
                                                        {isCommandPaletteChecked(
                                                            channelPayload,
                                                            currentPalette.type,
                                                            currentPalette.color
                                                        ) && (
                                                            <Check
                                                                sx={
                                                                    currentPalette?.type === 'contained'
                                                                        ? { color: '#fff' }
                                                                        : {
                                                                              color: currentPalette?.color,
                                                                              position: 'absolute',
                                                                              top: '-1px',
                                                                              left: '-2px',
                                                                          }
                                                                }
                                                            />
                                                        )}
                                                    </Box>
                                                )
                                            )}
                                        </Stack>
                                    ))}
                                </Menu>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Grid>
        </DragDropContext>
    );
};
