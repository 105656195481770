import {
    Assessment,
    ViewTimeline,
    List,
    Bolt,
    ListAlt,
    Settings as SettingsIcon,
    Article,
    EventNote,
    Update,
    TrendingUp,
} from '@mui/icons-material';
import { CustomTransComponent } from 'components';
import React from 'react';
import { Summary } from '../Summary';
import { Devices, Document, GroupSummary, Timeline } from 'pages/OrgHome';
import { DeviceDetails } from 'pages/DeviceDetails';
import { Loads } from 'pages/Loads';
import { Properties } from 'pages/Properties';
import { Settings } from 'pages/Settings';
import { Trends } from 'pages/Trends';
import { Schedule } from '../Schedule';
import { Device, DeviceActivating, NetworkSwitch } from '@brightlayer-ui/icons-mui';
import { UpcomingEvents } from 'pages/OrgHome/UpcomingEvents';
import { VirtualLoadsList } from 'pages/OrgHome/VirtualLoads/VirtualLoads';

const tabsStyle = { display: 'flex', flexDirection: 'row', marginBottom: '0' };

export const MappedTabs = ({ deviceId }: { deviceId?: any }): any => [
    {
        id: 'summary',
        sx: tabsStyle,
        icon: <Assessment />,
        renderTitle: <CustomTransComponent translationKey={'COMMON:SUMMARY'} />,
        renderContent: deviceId ? <Summary /> : <GroupSummary />,
    },
    {
        id: 'devices',
        sx: tabsStyle,
        renderContent: <Devices />,
        renderTitle: <CustomTransComponent translationKey={'COMMON:DEVICES'} />,
        icon: <Device />,
    },
    {
        id: 'timeline',
        sx: tabsStyle,
        icon: <ViewTimeline />,
        renderTitle: <CustomTransComponent translationKey={'COMMON:TIMELINE'} />,
        renderContent: <Timeline />,
    },
    {
        id: 'upcoming',
        sx: tabsStyle,
        icon: <Update />,
        renderTitle: <CustomTransComponent translationKey={'COMMON:UPCOMING'} />,
        renderContent: <UpcomingEvents />,
    },
    {
        id: 'details',
        sx: tabsStyle,
        renderTitle: <CustomTransComponent translationKey={'COMMON:DETAILS'} />,
        renderContent: <DeviceDetails />,
        icon: <List />,
    },
    {
        id: 'loads',
        sx: tabsStyle,
        renderTitle: <CustomTransComponent translationKey={'COMMON:LOADS'} />,
        icon: <Bolt />,
        renderContent: <Loads />,
    },
    {
        id: 'properties',
        sx: tabsStyle,
        renderTitle: <CustomTransComponent translationKey={'COMMON:PROPERTIES'} />,
        renderContent: <Properties />,
        icon: <ListAlt />,
    },
    {
        id: 'gateway-devices',
        sx: tabsStyle,
        renderTitle: <CustomTransComponent translationKey={'COMMON:GATEWAY_DEVICES'} />,
        renderContent: <Devices />,
        icon: <NetworkSwitch />,
    },
    {
        id: 'virtual_loads',
        sx: tabsStyle,
        icon: <DeviceActivating />,
        renderTitle: <CustomTransComponent translationKey={'COMMON:VIRTUAL_LOADS'} />,
        renderContent: <VirtualLoadsList />,
    },

    {
        id: 'settings',
        sx: tabsStyle,
        icon: <SettingsIcon />,
        renderTitle: <CustomTransComponent translationKey={'COMMON:SETTINGS'} />,
        renderContent: <Settings />,
    },
    {
        id: 'trends',
        sx: tabsStyle,
        renderTitle: <CustomTransComponent translationKey={'COMMON:TRENDS'} />,
        renderContent: <Trends />,
        icon: <TrendingUp />,
    },
    {
        id: 'documents',
        sx: tabsStyle,
        renderTitle: <CustomTransComponent translationKey={'COMMON:DOCUMENTS'} />,
        renderContent: <Document />,
        icon: <Article />,
    },
    {
        id: 'schedule',
        sx: tabsStyle,
        renderTitle: 'Schedule',
        renderContent: <Schedule />,
        icon: <EventNote />,
    },
];
